/**
 * Created by jimmy on 2019/7/26.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from '../serviceWorker';
import * as Utils from '../common/utils';
import * as CONST from '../common/const';

window.globalData = window.globalData || {
		userInfo: {}
	};

// check user status
// not login
// not active
(() => {
	if(CONST.whiteList.includes(window.location.pathname)) return;

	// 中信项目
	const userInfo = Utils.userInfo.get();
	if(!userInfo.id && (window.location.pathname.indexOf('/intro') == -1 && window.location.pathname.indexOf('/login') == -1)) {
		const { query = {} } = Utils.urlQuery.get();
		const queryArr = [];
		for(let i in query) {
			if(i && CONST.loginParamsList.includes(i)) {
				queryArr.push(`${i}=${encodeURIComponent(query[i])}`);
			}
		}
		if(queryArr.length > 0) {
			Utils.redirectPage(this, '/intro', 4020, 'prj', queryArr.join('&'));
		}else{
			Utils.redirectPage(this, '/intro', 4020);
		}
	}else if(userInfo.actived == 0 && window.location.pathname.indexOf('/rights') == -1) {
		Utils.redirectPage(this, '/rights', 4020);
	}
})();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
