/**
 * Created by jimmy on 2019/3/8.
 */

import React, { useState, useEffect } from 'react';

// style
import './list_loading.less';

const ListLoadingComp = props => {
	const { total, pageNo, pageSize, length } = props;
	
	return (
		<div>
			{
				total > pageNo * pageSize &&
				<div className="list-loading" id="loadingWrapper"><em className="iconfont icon-loading" />加载中……</div> ||
				(length > 0 && pageNo > 1 ? <div className="list-loading-end">到底了</div> : null)
			}
		</div>
	);
};

export default ListLoadingComp;