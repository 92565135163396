/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './user_rights_info.less';
import Page from '../compoment/prj/page';
import * as Request from '../common/request';
import * as Utils from '../common/utils';
import * as CONST from '../common/const';
import * as Theme from '../common/theme';

import IMG_RIGHT_03 from '../common/images/rights_03.png';
import IMG_RIGHT_03_ACTIVE from '../common/images/rights_03_active.png';

class PageUserRightsInfo extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      couponList: [],
      interestId: Utils.urlQuery.get('interestId') || ''
    }
  }
  
  componentDidMount() {
    this.getCoupons();
  }
  
  getCoupons = async () => {
    const result = await Request.post({
      url: '/coupon/getByUser'
    });
    
    if(result.ok == 0) {
      this.setState({
        couponList: result.obj.couponAllList || []
      });
    }
  };
  
  selectHandle = (coupon = {}) => {
    this.setState({
      interestId: coupon.interestId
    });
  };
  
  render() {
    const { couponList, interestId } = this.state;
    let curCoupon = couponList.filter(coupon => coupon.interestId == interestId);
    curCoupon = curCoupon[0] || {};
    
    return (
      <Page title="权益信息" {...this.props}>
        <section className="user-rights-info-wrap">
          <div className="tabs-wrap">
            <div className="tabs" style={{ width: 100 * couponList.length }}>
              {
                Utils.isValidArray(couponList) && couponList.map((coupon, idx) => {
                  const isActive = coupon.interestId == interestId;
                  // const icon = isActive ? CONST.interestTypeIcons[`INTEREST_EMPTY_0${idx + 1}_SELECTED`] : CONST.interestTypeIcons[`INTEREST_EMPTY_0${idx + 1}`];
                  return (
                    <div className={isActive ? 'item active theme-primary-btncolor' : 'item'} key={idx} onClick={() => {
                      this.selectHandle(coupon);
                    }}>
                      <em style={{ backgroundImage: `url(${Theme.getImages().interestIcon[`i${idx + 1}white`]})` }} />
	                    <label>{coupon.interestName}</label>
                    </div>
                  );
                })
              }
            </div>
          </div>
          <div className="content">
            <div className="title">
	            <span>{curCoupon.interestName}</span>
              <span className="number">（{curCoupon.cnt > 0 ? curCoupon.cnt + '张' : '不限次数'}）</span>
            </div>
            <div className="desc">
              <div className="title">权益说明</div>
              <div dangerouslySetInnerHTML={{ __html: curCoupon.instruction }} />
            </div>
          </div>
          <Link className="usage-action" to={Utils.getRedirectUrl(`/home?interestId=${curCoupon.interestTypeId}`)}>
            <span className="theme-primary-btncolor">立即使用</span>
          </Link>
        </section>
      </Page>
    );
  }
}

export default PageUserRightsInfo;