/**
 * Created by jimmy on 2019/3/9.
 */
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './hotel_item.less';
import * as Utils from '../../common/utils';

class CompHotelItem extends Component {
  render() {
    const { data, projectInfo } = this.props;

	  if(!data || data.price <= 0) {
		  return null;
	  }
    
    return (
      <Link className="hotel-item-container" to={Utils.getRedirectUrl(`/hotel/${data.id}/detail`)}>
        <div className="base-info">
          <div className="cover" style={{ backgroundImage: `url(${Utils.getImageUrl(data.hotelImg)})` }} />
          <div className="msg">
            <div className="hotel-name">{data.name}</div>
	          { data.nameEn && <div className="hotel-enname">{data.nameEn}</div> }
	          {
		          data.hotelTags && Utils.isValidArray(data.hotelTags) && <div className="hotel-tags">
			          {
				          data.hotelTags.map((tag, tagidx) => (<span key={tagidx}>{tag}</span>))
			          }
		          </div>
	          }
	          
            <div className="price-and-poi">
              <div className="poi">{data.starDesc}</div>
              <div className="price">
	              { !(Utils.doHidePrice(projectInfo)) && <span className="ori-price">市场价<span className="price-value"><em className="yen">¥</em>{data.price}</span></span> }
                {/*<span className="price-value"><em className="yen">¥</em>1999</span>*/}
                <span className="order-now theme-primary-btncolor">立即预订</span>
              </div>
            </div>
          </div>
        </div>
        <div className="other-info">
          { data.distance && <div className="distance">距您{data.distance.toFixed(1)}km</div> }
          <div className="tags">
            {
              Utils.isValidArray(data.projectInterestName) && data.projectInterestName.map((project, idx) => (
                <span className="yen theme-primary-color theme-primary-bordercolor" key={idx}>{project}</span>
              ))
            }
          </div>
        </div>
      </Link>
    );
  }
}

export default CompHotelItem;

