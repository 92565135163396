/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './icbc_prd_list.less';
import Page from '../compoment/prj/page';
import * as Utils from '../common/utils';

import IMG_BG_1 from '../common/images/prdlist/icbc_list/v2/bg/01.jpg';
import IMG_BG_2 from '../common/images/prdlist/icbc_list/v2/bg/02.jpg';
import IMG_BG_3 from '../common/images/prdlist/icbc_list/v2/bg/03.jpg';
import IMG_BG_4 from '../common/images/prdlist/icbc_list/v2/bg/04.jpg';
import IMG_BG_5 from '../common/images/prdlist/icbc_list/v2/bg/05.jpg';
import IMG_BG_6 from '../common/images/prdlist/icbc_list/v2/bg/06.jpg';
import IMG_BG_7 from '../common/images/prdlist/icbc_list/v2/bg/07.jpg';
import IMG_BG_8 from '../common/images/prdlist/icbc_list/v2/bg/08.jpg';
import IMG_BG_9 from '../common/images/prdlist/icbc_list/v2/bg/09.jpg';
import IMG_BG_10 from '../common/images/prdlist/icbc_list/v2/bg/10.jpg';

import IMG_HOTEL_1_1 from '../common/images/prdlist/icbc_list/v2/hotel1/1.png';
import IMG_HOTEL_1_2 from '../common/images/prdlist/icbc_list/v2/hotel1/2.png';
import IMG_HOTEL_1_3 from '../common/images/prdlist/icbc_list/v2/hotel1/3.png';

import IMG_HOTEL_2_1 from '../common/images/prdlist/icbc_list/v2/hotel2/1.png';
import IMG_HOTEL_2_2 from '../common/images/prdlist/icbc_list/v2/hotel2/2.png';
import IMG_HOTEL_2_3 from '../common/images/prdlist/icbc_list/v2/hotel2/3.png';

const BG_IMG_MAP = [
	IMG_BG_1, IMG_BG_2, IMG_BG_3, IMG_BG_4, IMG_BG_5, IMG_BG_6, IMG_BG_7, IMG_BG_8, IMG_BG_9, IMG_BG_10
];
const IMG_MAP = [
	{
		style: {
			top: 1849/750 * window.innerWidth,
			bgTop: '82%',
			listTop: '82%'
		},
		covers: [ IMG_HOTEL_1_1, IMG_HOTEL_1_2, IMG_HOTEL_1_3 ],
		list: [
			[
				{ link: 'https://www.hxxl6.com/dist/#/index/detail/HD481561481213915136', title: '三亚海棠湾万丽度假酒店' }
			],
			[
				{ link: 'https://www.hxxl6.com/dist/#/index/detail/HD481557286381432832', title: '三亚湾红树林度假世界' },
				{ link: 'https://www.hxxl6.com/dist/#/index/detail/HD481556680723935232', title: '三亚希尔顿花园酒店' }
			],
			[
				{ link: 'https://www.hxxl6.com/dist/#/index/detail/HD481558693201326080', title: '厦门海沧融信华邑酒店' },
				{ link: 'https://www.hxxl6.com/dist/#/index/detail/HD481563983250468864', title: '上海太阳岛度假俱乐部' }
			]
		]
	},
	{
		style: {
			top: 3924/750 * window.innerWidth,
			bgTop: '101.8%',
			listTop: '84.8%',
		},
		covers: [ IMG_HOTEL_2_1, IMG_HOTEL_2_2, IMG_HOTEL_2_3 ],
		list: [
			[
				{ link: 'https://www.hxxl6.com/dist/#/index/detail/HD481554367292649472', title: '馨乐庭星海服务公寓酒店' }
			],
			[
				{ link: 'https://www.hxxl6.com/dist/#/index/detail/HD481562821331791872', title: '韶关碧桂园凤凰酒店' },
				{ link: 'https://www.hxxl6.com/dist/#/index/detail/HD481560235086196736', title: '常熟蒋巷书院' }
			],
			[
				{ link: 'https://www.hxxl6.com/dist/#/index/detail/HD481555868127866880', title: '长白山万达智选假日酒店' },
				{ link: 'https://www.hxxl6.com/dist/#/index/detail/HD481564596822618112', title: '张家口云顶大酒店' }
			]
		]
	}
];

class PageJoyList extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {
		Utils.setTitle('产品列表');
  }


  render() {
    return (
      <section className="prdlist-wrap">
				{
					BG_IMG_MAP.map((item, idx) => <img src={item} key={idx} />)
				}
				{
					IMG_MAP && IMG_MAP.map((item, idx1) => {
						return (
							<div className="hotel-wrap" style={{ top: item.style.top }} key={idx1}>
								{
									item.covers.map((citem, cindex) => {
										const prdList = item.list[cindex];
										return <div className="hotel-block">
											<img src={citem} key={cindex} />
											<div className="prds">
												{
													prdList.map((prd, pidx) => {
														return <a href={prd.link} className="item" key={pidx} />
													})
												}
											</div>
										</div>
									})
								}
							</div>
						)
					})
				}
      </section>
    );
  }
}

export default PageJoyList;

window.onload=function () {
  if(window.location.pathname == '/prj/icbc-mall/prd-list') {
    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width,initial-scale=1')
  }
};
