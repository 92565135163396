/**
 * Created by jimmy on 2019/3/9.
 */
import React, { Component } from 'react';
import './hotel_list_filters.less';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import * as Request from '../../common/request';
import * as Theme from '../../common/theme';
import * as Amap from '../../common/amap';
import CityList from './city_list';

const filters = [
  { label: CONST.defaultCity.cityName, filed: 'city', icon: Theme.getImages().poi },
  { label: '推荐排序', filed: 'order', icon: '' },
  { label: '全部优惠', filed: 'coupon', icon: '', style: { paddingLeft: 20, paddingRight: 20 } }
];

class CompHotelListFilters extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      dropdownShow: false,
      dropdownList: [],
      multiple: false,
      target: '',
      selection: {
        order: '',
        coupon: ['']
      },
      
      orderTypeList: [
        { id: 0, name: '推荐排序' },
        { id: 1, name: '低价优先' }
      ],
      selectedOrderType: Utils.urlQuery.get('orderType') || 0,
      
      couponList: [
        { id: 0, name: '全部优惠' }
      ],
      selectedCoupon: '',
      
      cityShow: false,
      cityList: [],
	    cityInfo: {},
      poiInfo: {}
    };
  }
  
  componentDidMount() {
    this.initial();
  }
  
  initial = () => {
  	const { orderTypeList } = this.state;
    Promise.all([
      this.getCityList(),
      this.getCurrentPosition(),
      this.getCouponList()
    ]).then(async () => {
      const { selection, poiInfo, couponList } = this.state;
      // selected city initial
      const selectedCity = Utils.urlQuery.get('cityName') || (poiInfo.city || poiInfo.province);
      const cityId = await this.getCityIdByName(selectedCity);
      selection.city = selectedCity;
	    // selected coupon initial
	    let selectedCoupon = couponList.filter(coupon => Utils.urlQuery.get('interestType').indexOf(coupon.id) > -1 && coupon);
	    selectedCoupon = selectedCoupon.map(c => c.name);
	    selection.coupon = selectedCoupon && selectedCoupon.length > 0 ? selectedCoupon : ['全部优惠'];
	    // selected order type initial
	    selection.order = (() => {
		    let res = orderTypeList.filter(l => l.id == this.state.selectedOrderType && l);
		    res = res && res[0] || { name: '推荐排序' };
		    return res.name;
	    })();
	    
      this.setState({
        selection,
	      cityInfo: {
		      cityName: selectedCity
	      }
      });
      
      this.props.onInitial({
        cityInfo: {
          cityId,
          cityName: selectedCity,
          // lng: ((poiInfo.businessAreas[0] || {}).location || {}).lng,
          // lat: ((poiInfo.businessAreas[0] || {}).location || {}).lat
        },
        orderTypeInfo: {
          orderType: this.state.selectedOrderType
        },
        couponInfo: Utils.urlQuery.get('interestType') ? Utils.urlQuery.get('interestType').split(',') : []
      });
    });
  };
  
  /**
   * 城市
   */
  getCurrentPosition = async () => {
    let result = await Amap.getCurrentPosition2();
    if(result.resStatus == 'error') {
      result = await Amap.getCityInfo();
    }

    const curCityId = await this.getCityIdByName((result.city || result.province));
    Utils.localCache({ key: 'cityId', value: curCityId });
    this.setState({
      poiInfo: result
    });
    // return await Amap.getCurrentPosition().then(async result => {
    //   const poiInfo = result.addressComponent;
    //   const curCityId = await this.getCityIdByName((poiInfo.city || poiInfo.province));
	   //  Utils.localCache({ key: 'cityId', value: curCityId });
    //
    //   this.setState({
    //     poiInfo
    //   })
    // });
  };
  
  getCityIdByName = async (cityName) => {
    if(!cityName) return '';
    const result = await Request.post({
      url: '/common/getCityId',
      data: {
        cityName
      }
    });
    
    if(result.ok == 0) {
      return result.obj.cityId;
    }
  };
  
  getCityList = async () => {
    const result = await Request.post({
      url: '/project/cities/get'
    });
    
    if(result.ok == 0) {
      this.setState({
        cityList: result.obj.cities
      })
    }
  };
  
  showCity = () => {
    const homeBaseInfo = document.getElementById('homeBaseInfo');
    // Utils.scrollTo(homeBaseInfo.offsetHeight);
    this.setState({
      cityShow: true
    });
  };
  
  hideCity = () => {
    this.setState({
      cityShow: false
    });
  };
  
  /**
   * 排序 & 优惠
   */
  getCouponList = async () => {
    const { couponList } = this.state;
    const result = await Request.post({
      url: '/project/accomInterestTypes/get'
    });
    
    if(result.ok == 0) {
      this.setState({
        couponList: [
          ...couponList,
          ...result.obj.accomInterestTypes
        ]
      })
    }
  };
  
  showDropdown = async (filter) => {
    const homeBaseInfo = document.getElementById('homeBaseInfo');
    Utils.scrollTo(homeBaseInfo.offsetHeight);
    const { orderTypeList, selectedOrderType, couponList, selection, selectedCoupon } = this.state;
  
    let dropdownList = [];
    if(filter.filed == 'order') {
      dropdownList = orderTypeList;
    }else if(filter.filed == 'coupon') {
      dropdownList = couponList;
    }
    
    this.setState({
      dropdownList,
      dropdownShow: true,
      target: filter.filed,
      selection
    });
  };
  
  hideDropdown = () => {
    this.setState({
      dropdownList: [],
      dropdownShow: false,
      multiple: false,
      target: ''
    })
  };
  
  render() {
    const { style } = this.props;
    let {
      dropdownShow, dropdownList, multiple, target, selection,
      selectedOrderType,
      cityShow,
      cityList,
      couponList,
	    cityInfo
    } = this.state;

    return (
      <section className="hotel-list-filters-container" style={style || {}}>
        <div className="filter-items-wrapper">
          {
            filters.map((filter, idx) => (
              <div
                className={target === filter.filed ? 'filter-item active' : 'filter-item'}
                key={idx}
                style={filter.style || {}}
                onClick={() => {
                  if(filter.filed === 'city') {
                    this.showCity();
                  }else{
                    if(target === filter.filed) {
                      this.hideDropdown();
                    }else{
                      this.showDropdown(filter, idx);
                    }
                  }
                }}
              >
                { filter.icon && <em style={{ backgroundImage: `url(${filter.icon})` }} /> }
                <span className={target === filter.filed ? ' theme-primary-color' : ''}>{Utils.isValidArray(selection[filter.filed]) ? selection[filter.filed].join(' ') : selection[filter.filed]}</span>
                <i className={`trial-down${target === filter.filed ? ' theme-primary-trial' : ''}`} />
              </div>
            ))
          }
        </div>
        {
          dropdownShow && dropdownList && <div>
            <div className="dropdown-mask" onClick={this.hideDropdown} />
            <div className="dropdown">
              {
                dropdownList.map((item, idx) => {
                	let isSelected = selection[target] && selection[target].indexOf(item.name) > -1;
                  return (
                    <div
	                    className={`dropdown-item${isSelected ? ' selected theme-primary-color' : ''}`}
	                    style={ isSelected ? { backgroundImage: `url(${Theme.getImages().selected})` } : {}}
	                    key={idx}
	                    onClick={() => {
	                      if(target == 'order') {
	                        selection[target] = item.name;
	                        selectedOrderType = item.id;
	  
	                        this.setState({
	                          selection,
	                          selectedOrderType
	                        }, () => {
	                          this.hideDropdown();
	                          this.props.onChange({
	                            orderTypeInfo: {
	                              orderType: selectedOrderType
	                            }
	                          });
	                        });
	                      }else if(target == 'coupon') {
	                        if(item.id == 0) {
	                          selection[target] = [item.name];
	                        }else{
	                          selection[target] = selection[target].indexOf('全部优惠') > -1 ? [] : selection[target];
	                          let targetIdx = selection[target].indexOf(item.name);
	                          if(targetIdx > -1) {
	                            selection[target].splice(targetIdx, 1);
	                          }else{
	                            selection[target].push(item.name);
	                          }
	                          selection[target] = selection[target].length > 0 ? selection[target] :  ['全部优惠'];
	                        }
	  
	                        this.setState({
	                          selection
	                        });
	                      }
                    }}>{item.name}</div>
                  )
                })
              }
              {
                target == 'coupon' && <div className="dropdown-btn">
                  <span onClick={() => {
                    selection[target] = ['全部优惠'];
                    this.props.onChange({
                      couponInfo: []
                    });
                    this.setState({
                      selection
                    });
                  }}>重置</span>
                  <span className="theme-primary-btncolor" onClick={() => {
                    const couponInfo = [];
                    couponList.map(c => {
                      if(selection[target].indexOf(c.name) > -1 && c.id != 0){
                        couponInfo.push(c.id);
                      }
                    });
                    this.props.onChange({
                      couponInfo
                    });
                    this.hideDropdown();
                  }}>确定</span>
                </div>
              }
            </div>
          </div>
        }
        
        <div>
          { cityShow && <div className="city-list-mask" /> }
          <div className={cityShow ? 'city-list-container city-list-container-out' : 'city-list-container'}>
            <CityList
	            currentCity={cityInfo}
              data={cityList}
              cancelHandle={this.hideCity}
              selectHandle={cityInfo => {
                selection.city = !!cityInfo.oversea ? cityInfo.cityEn : cityInfo.cityName;
                this.setState({ selection });
                this.props.onChange({
                  cityInfo
                });
	              this.setState({ cityInfo });
                this.hideCity();
              }}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default CompHotelListFilters;

