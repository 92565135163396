/**
 * Created by jimmy on 2019/6/23.
 */

import * as Request from '../common/request';
import * as Utils from '../common/utils';
import * as CONST from '../common/const';

// 支付成功通知服务端
export const syncPayToServer = async (token, payResult) => {
	if(!token) return;

	return await Request.post({
		url: '/pay/client-paid-citic',
		data: {
			token,
			payResult
		}
	});
};

export const doPayment = async (retUrl, toPay, payCode, data = {}, url = '/pay/prepay-citic') => {
  const oid = data.orderId;
  if(data.orderId) delete data.orderId;
	// 获取支付 token
	const result = await Request.post({
		url,
		data: {
			retUrl,
			payCode,
			toPay: toPay * 100,
      ...data
		}
	});

	// 跳转支付 h5
	if(result.ok == 0 && result.obj.token) {
		const _url = `${CONST.ZX_PAYMENT_PATH}#/orderDetail?token=${result.obj.token}&resultUrl=${encodeURIComponent(retUrl)}`;
		setTimeout(() => {
			window.location.href = _url;
		}, 666);
	}else{
		Utils.dialog.toast(result.msg || '获取 token 失败');
		setTimeout(() => {
			window.location.href = `${retUrl}?oid=${oid}`;
		}, 666);
	}
};

export const doWeChatPayment = async (bookOrderId, retUrl, toPay) => {
	// 获取微信支付签名
	const userWeChatInfo = Utils.weChat.get();
	if(!userWeChatInfo || !userWeChatInfo.openId) {
		Utils.dialog.toast('微信登录信息已失效，即将为您重新登录');
		setTimeout(() => {
			Utils.weChat.login({}, () => {
				_doPayment();
			});
		}, 1500);
	}else{
		_doPayment();
	}

	async function _doPayment() {
		const userWeChatInfo = Utils.weChat.get();
		console.log('userWeChatInfo', userWeChatInfo);
		const result = await Request.post({
			url: '/pay/prepay-wechat',
			data: {
				bookOrderId,
				openId: userWeChatInfo.openId,
				toPay: toPay * 100
			}
		});
		if(result.ok == 0) {
			function onBridgeReady(){
				window.WeixinJSBridge.invoke(
					'getBrandWCPayRequest',
					result.obj,
					async function(res){
						console.log('res', res);
						let payResult = null;
						if(res.err_msg == "get_brand_wcpay_request:ok" ){
							Utils.dialog.toast('支付成功');
							payResult = 1;
						}else{
							Utils.dialog.toast('支付被中断，稍后可以在您的订单中重新支付');
							payResult = 0;
						}
						if(payResult == 0 || payResult == 1) {
							await syncWxPayToServer(bookOrderId, payResult);
						}
						setTimeout(() => {
							window.location.href = retUrl;
						}, 888);
					});
			}
			if (typeof WeixinJSBridge == "undefined"){
				if( document.addEventListener ){
					document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
				}else if (document.attachEvent){
					document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
					document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
				}
			}else{
				onBridgeReady();
			}
		}
	}
};

/**
 * 宁波银行支付
 * @returns {Promise.<void>}
 */
export const doNBankPayment = async (bookOrderId, retUrl, toPay) => {
	const result = await Request.post({
		url: '/pay/prepay-nbank',
		data: {
			bookOrderId,
			toPay: toPay * 100,
			retUrl
		}
	});
	if(result.ok == 0) {
		return result.obj.formHtml;
	}
};

/**
 *
 * @param orderId | 订单 ID
 * @param payResult | 1 - 支付成功，0 - 支付失败
 * @returns {Promise}
 */
export const syncWxPayToServer = async (orderId, payResult) => {
	if(!orderId) return;
	
	return await Request.post({
		url: '/pay/client-paid-wechat',
		data: {
			orderId,
			payResult
		}
	});
};