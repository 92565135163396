/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import moment from 'moment';
import './coupons.less';
import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as Theme from '../../common/theme';
import * as CONST from '../../common/const';

class CompCoupons extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      oriList: [],
      list: [],
      notSuperposeList: []
    }
  }
  
  componentDidMount() {
    this.getList();
  }
  
  getList = async () => {
    const { productId } = this.props.match.params;
    const result = await Request.post({
      url: '/coupon/getByProduct',
      data: {
        productId
      }
    });
    
    if(result.ok == 0) {
      const list = result.obj.couponList || [];
      this.setState({
        oriList: list,
        list
      });
      
      this.props.initialHandle(list);
    }
  };
  
  selectedHandle = (idx) => {
	  const { breakfast } = this.props;
	  const { list, oriList } = this.state;
	  list[idx].selected = !list[idx].selected;
	  const target = list[idx];
	  let breakfastCnt = 0;
	  
	  const selectedTypeIds = [];
	  list.map(lst => {
	  	if(lst.selected) {
	  		if(!selectedTypeIds.includes(lst.interestTypeId)) {
				  selectedTypeIds.push(lst.interestTypeId);
			  }
			
			  if(lst.interestTypeId == CONST.interestTypeIds.BREAKFAST) {
				  breakfastCnt += 1;
			  }
		  }
	  });
	
	  const _list = [];
	  const _notSpoList = [];
	  oriList.map((oriLst, index) => {
	  	if(target.allowSuperpose) {
			  if(selectedTypeIds.includes(oriLst.interestTypeId) && !oriLst.selected) {
				  if(oriLst.interestTypeId == CONST.interestTypeIds.BREAKFAST){
					  // 早餐券：根据房型要求选择 1 - 2 张且最多 2 张
					  if(breakfastCnt >= 2 || (breakfastCnt >= 1 && breakfast == 1) || (breakfastCnt >= 0 && breakfast == 2)){
						  _notSpoList.push({...oriLst, disabled: true});
					  }else{
						  _list.push(oriLst);
					  }
				  }else{
					  oriLst.allowSuperpose ? _list.push(oriLst) : _notSpoList.push({...oriLst, disabled: true})
				  }
			  }else{
				  if(oriLst.allowSuperpose){
					  _list.push(oriLst);
				  }else{
					  selectedTypeIds.length == 0 ? _list.push(oriLst) : _notSpoList.push({...oriLst, disabled: true});
				  }
			  }
		  }else{
	  		if(target.selected) {
				  idx == index ? _list.push(oriLst) : _notSpoList.push({...oriLst, disabled: true});
			  }else{
				  _list.push(oriLst);
			  }
		  }
	  });
	  
	  this.setState({
		  list: _list,
		  notSuperposeList: _notSpoList
	  });
  };

  submitHandle = () => {
    const { list } = this.state;
    const { breakfast } = this.props;
    
    const selectCoupons = list.filter(item => item.selected == true && item);
    const breakCoupons = selectCoupons.filter(coupon => coupon.interestTypeId == CONST.interestTypeIds.BREAKFAST);

    if(breakCoupons.length > 2) {
      Utils.dialog.toast(CONST.ERROR_TIPS.ONLY_2_BREAKFAST);
      return;
    }else if(breakfast == 1 && breakCoupons.length > 1) {
      Utils.dialog.toast(CONST.ERROR_TIPS.ONLY_1_BREAKFAST);
      return;
    }else if(breakfast == 2 && breakCoupons.length > 0) {
      Utils.dialog.toast(CONST.ERROR_TIPS.NO_NEED_BREAKFAST);
      return;
    }
    
    this.props.selectHandle({
      coupons: selectCoupons
    });
  };
  
  renderItem = (item, idx) => {
    if(!item) return null;
    
    return (
      <div className={item.disabled ? 'item disabled' : 'item'} key={idx} onClick={() => {
        if(item.disabled) return;
        this.selectedHandle(idx);
      }}>
        <div className="info">
          <div className="name">
            <span>{item.interestName}</span>
	          { item.cnt == 0 && <span className="number">(不限次数)</span> }
          </div>
          <div className="date">有效期: {moment(item.validStartTime).format('YYYY/MM/DD')}-{moment(item.validEndTime).format('YYYY/MM/DD')}</div>
        </div>
        <div className="selector" style={!!item.selected ? { backgroundImage: `url(${Theme.getImages().selectedRound})` } : {}} />
      </div>
    );
  };
  
  render() {
    const { list, notSuperposeList } = this.state;
    const selectCoupons = list.filter(item => item.selected == true && item);

    return (
      <section className="coupons-wrap">
	      {/*<div className="list-back" onClick={this.props.backHandle} />*/}
        <div className="list-wrap">
          { Utils.isValidArray(list) && list.map(this.renderItem) }
          { Utils.isValidArray(notSuperposeList) &&  <div className="title"><span>以下券不可叠加使用</span></div> }
          { Utils.isValidArray(notSuperposeList) && notSuperposeList.map(this.renderItem) }
        </div>
        <div className="ctrl-wrap">
          <span>{selectCoupons.length > 0 ? `已选${selectCoupons.length}张优惠券` : '未选优惠券'}</span>
          <div className="btns-wrap">
	          <div className="cancel-btn" onClick={this.props.backHandle}>取消</div>
	          <div className="submit-btn theme-primary-btncolor" onClick={this.submitHandle}>确定</div>
          </div>
        </div>
      </section>
    );
  }
}

export default CompCoupons;