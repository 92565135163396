/**
 * Created by jimmy on 2019/6/26.
 */
import * as Utils from './utils';

import CITICSALE_FAIL_ROUND from '../common/citic-sale/fail-round.png';
import CITICSALE_SUC_ROUND from '../common/citic-sale/success-round.png';
import CITICSALE_LOC from '../common/citic-sale/location.png';
import CITICSALE_PHONE from '../common/citic-sale/phone.png';
import CITICSALE_POI from '../common/citic-sale/poi.png';
import CITICSALE_SANJIAO from '../common/citic-sale/sanjiao.png';
import CITICSALE_SELECTED from '../common/citic-sale/selected.png';
import CITICSALE_SELECTED_ROUND from '../common/citic-sale/selected-round.png';
import CITICSALE_UPGRADE from '../common/citic-sale/upgrade.svg';
import CITICSALE_INTEREST_1 from '../common/citic-sale/interestIcon/1.png';
import CITICSALE_INTEREST_2 from '../common/citic-sale/interestIcon/2.png';
import CITICSALE_INTEREST_3 from '../common/citic-sale/interestIcon/3.png';
import CITICSALE_INTEREST_4 from '../common/citic-sale/interestIcon/4.png';
import CITICSALE_INTEREST_5 from '../common/citic-sale/interestIcon/5.png';
import CITICSALE_INTEREST_6 from '../common/citic-sale/interestIcon/6.png';
import CITICSALE_INTEREST_1_KONG from '../common/citic-sale/interestIcon/1-kong.png';
import CITICSALE_INTEREST_2_KONG from '../common/citic-sale/interestIcon/2-kong.png';
import CITICSALE_INTEREST_3_KONG from '../common/citic-sale/interestIcon/3-kong.png';
import CITICSALE_INTEREST_4_KONG from '../common/citic-sale/interestIcon/4-kong.png';
import CITICSALE_INTEREST_5_KONG from '../common/citic-sale/interestIcon/5-kong.png';
import CITICSALE_INTEREST_6_KONG from '../common/citic-sale/interestIcon/6-kong.png';
import CITICSALE_INTEREST_1_WHITE from '../common/citic-sale/interestIcon/1-white.png';
import CITICSALE_INTEREST_2_WHITE from '../common/citic-sale/interestIcon/2-white.png';
import CITICSALE_INTEREST_3_WHITE from '../common/citic-sale/interestIcon/3-white.png';
import CITICSALE_INTEREST_4_WHITE from '../common/citic-sale/interestIcon/4-white.png';
import CITICSALE_INTEREST_5_WHITE from '../common/citic-sale/interestIcon/5-white.png';
import CITICSALE_INTEREST_6_WHITE from '../common/citic-sale/interestIcon/6-white.png';

const prj = Utils.projectName.get();
export const getImages = () => {
	switch(prj) {
		case 'citic-sale':
		default:
			return {
				failRound: CITICSALE_FAIL_ROUND,
				successRound: CITICSALE_SUC_ROUND,
				loc: CITICSALE_LOC,
				phone: CITICSALE_PHONE,
				poi: CITICSALE_POI,
				sanJiao: CITICSALE_SANJIAO,
				selected: CITICSALE_SELECTED,
				selectedRound: CITICSALE_SELECTED_ROUND,
				upgrade: CITICSALE_UPGRADE,
				interestIcon: {
					'i1': CITICSALE_INTEREST_1,
					'i2': CITICSALE_INTEREST_2,
					'i3': CITICSALE_INTEREST_3,
					'i4': CITICSALE_INTEREST_4,
					'i5': CITICSALE_INTEREST_5,
					'i6': CITICSALE_INTEREST_6,
					'i1kong': CITICSALE_INTEREST_1_KONG,
					'i2kong': CITICSALE_INTEREST_2_KONG,
					'i3kong': CITICSALE_INTEREST_3_KONG,
					'i4kong': CITICSALE_INTEREST_4_KONG,
					'i5kong': CITICSALE_INTEREST_5_KONG,
					'i6kong': CITICSALE_INTEREST_6_KONG,
					'i1white': CITICSALE_INTEREST_1_WHITE,
					'i2white': CITICSALE_INTEREST_2_WHITE,
					'i3white': CITICSALE_INTEREST_3_WHITE,
					'i4white': CITICSALE_INTEREST_4_WHITE,
					'i5white': CITICSALE_INTEREST_5_WHITE,
					'i6white': CITICSALE_INTEREST_6_WHITE,
				}
			};
			break;
	}
};