/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import './prd_list.less';
import Page from '../compoment/prj/page';
import * as Utils from '../common/utils';

import IMG_CXYNEW_1 from '../common/images/prdlist/new_v8/1_01.jpg';
import IMG_CXYNEW_2 from '../common/images/prdlist/new_v8/1_02.jpg';
import IMG_CXYNEW_3 from '../common/images/prdlist/new_v8/1_03.jpg';
import IMG_CXYNEW_4 from '../common/images/prdlist/new_v8/1_04.jpg';
import IMG_CXYNEW_5 from '../common/images/prdlist/new_v8/1_05.jpg';
import IMG_CXYNEW_6 from '../common/images/prdlist/new_v8/1_06.jpg';
import IMG_CXYNEW_7 from '../common/images/prdlist/new_v8/1_07.jpg';
import IMG_CXYNEW_8 from '../common/images/prdlist/new_v8/1_08.jpg';
import IMG_CXYNEW_9 from '../common/images/prdlist/new_v8/1_09.jpg';
import IMG_CXYNEW_10 from '../common/images/prdlist/new_v8/1_10.jpg';
import IMG_CXYNEW_11 from '../common/images/prdlist/new_v8/1_11.jpg';
import IMG_CXYNEW_12 from '../common/images/prdlist/new_v8/1_12.jpg';
import IMG_CXYNEW_13 from '../common/images/prdlist/new_v8/1_13.jpg';
import IMG_CXYNEW_14 from '../common/images/prdlist/new_v8/1_14.jpg';
import IMG_CXYNEW_15 from '../common/images/prdlist/new_v8/1_15.jpg';

import IMG_CXYNEW_18 from '../common/images/prdlist/new_v3/1_18.png';
import IMG_CXYNEW_19 from '../common/images/prdlist/new_v3/1_19.png';

const IMG_MAP = {
  IMG_CXYNEW_1, IMG_CXYNEW_2, IMG_CXYNEW_3, IMG_CXYNEW_4, IMG_CXYNEW_5, IMG_CXYNEW_6, IMG_CXYNEW_7, IMG_CXYNEW_8, IMG_CXYNEW_9,
  IMG_CXYNEW_10, IMG_CXYNEW_11, IMG_CXYNEW_12, IMG_CXYNEW_13, IMG_CXYNEW_14, IMG_CXYNEW_15, IMG_CXYNEW_18, IMG_CXYNEW_19
};

class PageHome extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {
	  Utils.setTitle('产品列表');
  }


  render() {
    return (
      <section className="prdlist-wrap">
        {
          Object.keys(IMG_MAP).map((img, idx) => (
		        <img src={IMG_MAP[img]} key={idx} />
	        ))
        }
      </section>
    );
  }
}

export default PageHome;

window.onload=function () {
	if(window.location.pathname == '/prj/citic-call/prd-list') {
		document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width,initial-scale=1')
	}
};
