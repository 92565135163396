/**
 * Created by jimmy on 2019/7/31.
 */

import * as Request from '../common/request';
import * as Utils from '../common/utils';
import * as CONST from '../common/const';

export const action = async (retUrl) => {
	const accessCode = Utils.urlQuery.get('accessCode');
	let retCnt = Number(Utils.urlQuery.get('retCnt') || 0);
	
	/**
	 * 重定向超过 5 次报错
	 */
	if(retCnt >= 5) {
		Utils.dialog.toast('多次重定向，登录异常');
		return;
	}else{
		if(!accessCode) {
			/**
			 * 没有 accessCode，到中信获取
			 * @type {number}
			 */
			retCnt += 1;
			// 跳转到中信获取 accessCode
			if(retUrl.indexOf('accessCode=') > -1) {   //  跳转到中信的 URL 中不能包含 accessCode
				retUrl = retUrl.replace('&accessCode=', '').replace('?accessCode=', '');
			}

			const _href = `${CONST.ZX_HOST}/citiccard/newucwap/oauth/getAccessCode.do?` + [
					`appid=${CONST.APPID}`,
					'scope=queryUserInfo',
					`returnUrl=${encodeURIComponent(`https://m.todate.com.cn/citic/cb/userInfo?retCnt=${retCnt}&retUrl=${encodeURIComponent(retUrl)}`)}`,
				].join('&');
			window.location.href = _href;
		}else{
			/**
			 * 服务端登录
			 * @type {Promise}
			 */
			const result = await Request.post({
				url: '/user/citic-login-code',
				data: {
					accessCode
				}
			});
			if(result && result.ok == 0) {
				const data = result.obj;
				return {
					statusCode: !data.mobile ? 1 : 2,   // 1 - 服务端第一次登录 | 注册；2 - 服务端已经存在该用户信息，静默登录
					...data
				}
			}else{
				Utils.dialog.toast(result.msg);
			}
		}
	}
};

// 宁波银行登录
export const nBankLogin = async () => {
	const dataContent = Utils.urlQuery.get('dataContent');
	const signContent = Utils.urlQuery.get('signContent');

	/**
	 * 服务端登录
	 * @type {Promise}
	 */
	const result = await Request.post({
		url: '/user/nbank-login',
		data: {
			data: dataContent,
			sign: signContent
		}
	});
	if(result && result.ok == 0) {
		return result.obj;
	}
	return false;
};