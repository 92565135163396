/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ReactSwipe from 'react-swipe';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './hotel_detail.less';
import * as Utils from '../common/utils';
import * as Request from '../common/request';
import * as Theme from '../common/theme';
import * as Amap from '../common/amap';
// import IMG_SANJIAO from '../common/citic-sale/sanjiao.png';
import Page from '../compoment/prj/page';

class PageHotelDetail extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      hideAllContent: true,
      displayAllContent: false,
      data: {},
      productList: [],
	    swipeIndex: 0,
	    projectInfo: {},
    }
  }
  
  componentDidMount() {
    this.initialData();
    this.getProjectInfo();

	  window.scrollTo(0, 0);
  }
  
  calcAllContentHeight = () => {
    const hiddenContentObj = document.getElementById('hiddenContent');
    this.setState({
      hideAllContent: hiddenContentObj && parseInt(hiddenContentObj.clientHeight) > 66
    });
  };
  
  initialMapPreview = (lnglat) => {
    Amap.addMap(lnglat, 'detailMapPreview');
  };

  initialData = () => {
    Promise.all([
      this.getData(),
      this.getProductList()
    ]).then(async () => {
      const { data, productList } = this.state;
      this.calcAllContentHeight();
      this.initialMapPreview([data.lng, data.lat]);

      const productMap = {};
      productList.map(item => {
        productMap[item.productId] = { ...item, oversea: data.oversea };
      });
      Utils.localCache({ key: 'productList', value: JSON.stringify(productMap) });
    })
  };

	getProjectInfo = async () => {
		const result = await Request.post({
			url: '/project/info/get'
		});

		if(result.ok == 0) {
			this.setState({
				projectInfo: result.obj
			})
		}
	};
  
  getData = async () => {
    const { hotelId } = this.props.match.params;
    const result = await Request.post({
      url: '/hotel/info/get',
      data: {
        hotelId
      }
    });
    
    if(result.ok == 0) {
      this.setState({
        data: result.obj
      });
	
	    Utils.localCache({ key: 'hotelInfo', value: JSON.stringify(result.obj || {}) });
    }
  };
  
  getProductList = async () => {
    const { hotelId } = this.props.match.params;
    const result = await Request.post({
      url: '/product/accom/list',
      data: {
        hotelId
      }
    });
    
    if(result.ok == 0) {
      this.setState({
        productList: result.obj.products
      });
    }
  };
  
  updateSwipeIndex = index => {
	  this.setState({ swipeIndex: index });
  };
  
  render() {
    const { hideAllContent, displayAllContent, data, productList, swipeIndex, projectInfo } = this.state;
	  
    return (
      <Page title={data.name} {...this.props}>
        <section className="hotel-detail-wrap">
          <div className="block-wrap">
            {
	            data.hotelPics && data.hotelPics.length > 0 && <div className="banner-list-wrap">
		            <ReactSwipe
			            className="banner-list"
			            swipeOptions={{
			            	continuous: false,
				            stopPropagation: false,
				            transitionEnd: this.updateSwipeIndex
			            }}
		            >
			            {
				            data.hotelPics.map((pic, idx) => <div className="banner-item" key={idx} style={{ backgroundImage: `url(${Utils.getImageUrl(pic)})` }} />)
			            }
		            </ReactSwipe>
		            <div className="index-wrap">
			            {data.hotelPics.map((p, idx) => <em key={idx} className={idx == swipeIndex ? 'active' : ''} />)}
		            </div>
	            </div>
            }
            <div className="baseinfo">
              <div>
                <div className="title">{data.name}</div>
                <div className="en-title">{data.nameEn} {data.starDesc && `| ${data.starDesc}`}</div>
              </div>
              <div className="theme-secondary-color poi">{data.cityName}</div>
            </div>
            <div className="desc-wrap">
              <div className={displayAllContent ? 'content-all' : 'content'}>
                {data.introDesc}
                { hideAllContent && !displayAllContent && <span className="toggle-btn open-btn theme-secondary-color" onClick={() => {
                  this.setState({ displayAllContent: true });
                }}>...展开<em style={{ backgroundImage: `url(${Theme.getImages().sanJiao})` }} /></span> }
              </div>
	            {
		            hideAllContent && displayAllContent && <div className="toggle-btn close-btn theme-secondary-color" onClick={() => {
			            this.setState({ displayAllContent: false });
		            }}>收起<em style={{ backgroundImage: `url(${Theme.getImages().sanJiao})` }} /></div>
	            }
              <div className="content-hidden" id="hiddenContent">{data.introDesc}</div>
            </div>
          </div>
  
          <div className="block-wrap">
            {
              !data.oversea &&
                <div className="address">
                  <div className="content">
                    <div className="info">
                      <label>地址：</label><span>{data.address}</span>
                    </div>
                    <div className="guide-btn" onClick={() => {
                      // alert('去导航');
                      Amap.doGuide([data.lng, data.lat]);
                    }}>导航</div>
                  </div>
                  <div className="map" id="detailMapPreview" />
                </div> ||
                <div className="address">
                  <div className="content">
                    <div className="info">
                      <label>地址：</label><span>{data.addressEn}</span>
                    </div>
                    <CopyToClipboard text={data.addressEn} onCopy={() => Utils.dialog.toast('已复制')}>
                      <div className="guide-btn">复制地址</div>
                    </CopyToClipboard>
                  </div>
                </div>
            }
          </div>
  
          <div className="bedtype-item-wrap">
            {
              productList && productList.length > 0 && productList.map((item, idx) => (
                <Link className="bedtype-item" key={idx} to={Utils.getRedirectUrl(`/hotel/${item.productId}/book`)}>
                  <div className="cover" style={{ backgroundImage: `url(${Utils.getImageUrl(item.productImg)})` }} />
                  <div className="message">
                    <div className="title">{item.name}</div>
                    <div className="params">
	                    { item.areaInfo && <span>{item.areaInfo}</span> }
	                    { item.bedTypeName && <span>{item.bedTypeName}</span> }
	                    <span>{({ '0': '无早', '1': '单早', '2': '双早' })[item.breakfast]}</span>
                    </div>
                    <div className="coupons">
	                    { !!item.proposedPrice && !(Utils.doHidePrice(projectInfo)) && <div className="proposed-price yen">¥{item.proposedPrice}</div> }
                      <div className="order theme-primary-btncolor">预订</div>
                      {
                        item.productInterests && item.productInterests.length > 0 && item.productInterests.map((pi, pidx) => <span className="theme-tag" key={pidx}>{pi}</span>)
                      }
                    </div>
                  </div>
                </Link>
              ))
            }
          </div>
          
	        {
		        data.reminders && data.reminders.length > 0 && <div className="block-wrap tips">
			        <div className="title">温馨提示</div>
			        <div className="tip-list">
				        {
					        data.reminders.map((r, idx) => {
					        	return (
							        <div key={idx} style={{ marginTop: idx == 0 ? 0 : '20px' }}>
								        {
								        	r.productName && <div className="tip-item">
										        <label>商品名称</label><span dangerouslySetInnerHTML={{ __html: r.productName }} />
									        </div>
								        }
								        {
									        r.checkinCheckoutTime && <div className="tip-item">
										        <label>入离时间</label><span dangerouslySetInnerHTML={{ __html: r.checkinCheckoutTime }} />
									        </div>
								        }
								        {
									        r.parkingInfo && <div className="tip-item">
										        <label>泊车信息</label><span dangerouslySetInnerHTML={{ __html: r.parkingInfo }} />
									        </div>
								        }
								        {
									        r.importantNotice && <div className="tip-item">
										        <label>重要通知</label><span dangerouslySetInnerHTML={{ __html: r.importantNotice }} />
									        </div>
								        }
								        {
									        r.cancelRule && <div className="tip-item">
										        <label>取消规则</label><span dangerouslySetInnerHTML={{ __html: r.cancelRule }} />
									        </div>
								        }
								        {
									        r.childPolicy && <div className="tip-item">
										        <label>儿童政策</label><span dangerouslySetInnerHTML={{ __html: r.childPolicy }} />
									        </div>
								        }
								        
								        
								        
							        </div>
						        );
					        })
				        }
				        
			        </div>
		        </div>
	        }
        </section>
      </Page>
    );
  }
}

export default PageHotelDetail;
