/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './user_rights.less';
import Page from '../compoment/prj/page';
import * as Request from '../common/request';
import * as Utils from '../common/utils';
import * as Theme from '../common/theme';

import IMG_RIGHT_02 from '../common/images/rights_02.png';
import IMG_SANJIAO from '../common/images/sanjiao.png';

const IMG_INTEREST = {
  IMG_INTEREST_01: Theme.getImages().interestIcon['i1'],
	IMG_INTEREST_02: Theme.getImages().interestIcon['i2'],
	IMG_INTEREST_03: Theme.getImages().interestIcon['i3'],
	IMG_INTEREST_04: Theme.getImages().interestIcon['i4'],
	IMG_INTEREST_05: Theme.getImages().interestIcon['i5'],
	IMG_INTEREST_06: Theme.getImages().interestIcon['i6'],
	IMG_INTEREST_01KONG: Theme.getImages().interestIcon['i1kong'],
	IMG_INTEREST_02KONG: Theme.getImages().interestIcon['i2kong'],
	IMG_INTEREST_03KONG: Theme.getImages().interestIcon['i3kong'],
	IMG_INTEREST_04KONG: Theme.getImages().interestIcon['i4kong'],
	IMG_INTEREST_05KONG: Theme.getImages().interestIcon['i5kong'],
	IMG_INTEREST_06KONG: Theme.getImages().interestIcon['i6kong'],
};

class PageUserRights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      specialFlag: false,
      priceFlag: false,
      confirmFlag: false,
      couponList: [],
      couponNotUsedList: [],
      couponUsedList: [],
    }
  }

  componentDidMount() {
    this.getCoupons();
  }

  getCoupons = async () => {
    const result = await Request.post({
      url: '/coupon/getByUser'
    });

    if(result.ok == 0) {
      this.setState({
        couponList: result.obj.couponAllList || [],
        couponNotUsedList: result.obj.couponNotUsedList || [],
        couponUsedList: result.obj.couponUsedList || []
      });
    }
  };
	
	renderItem = (item = {}, idx, isHistory) => {
		if(!item) {
			return null;
		}
		return (
			<div className="item" key={idx}>
				<div className="msg">
					<div className="info">
						<div className="name">
							<span>{item.interestName}</span>
							{
								!isHistory && <span className="number">
	                {item.cnt === 0 ? '(不限次数)' : `(剩余${item.cnt}张)`}
	              </span>
							}
						</div>
						<div className="date">有效期: {moment(item.validStartTime).format('YYYY/MM/DD')}-{moment(item.validEndTime).format('YYYY/MM/DD')}</div>
					</div>
					<em style={{ backgroundImage: `url(${IMG_INTEREST[`IMG_INTEREST_0${item.interestTypeId}KONG`]})` }} />
				</div>
				<div className="tip">适用商品：请参考商品列表优惠信息</div>
			</div>
		);
	};

  render() {
    let { couponList, couponNotUsedList, couponUsedList } = this.state;
    return (
      <Page title="我的权益" {...this.props}>
        <section className="user-rights-wrap">
          <div className="rights">
            <div className="title">专属权益</div>
            <div className="list">
              {
                Utils.isValidArray(couponList) && couponList.map((item, idx) => {
                  return (
                    <Link
                      className="item"
                      key={idx}
                      style={{ backgroundImage: `url(${IMG_INTEREST[`IMG_INTEREST_0${item.interestTypeId}`]})` }}
                      to={Utils.getRedirectUrl(`/user/rights/info?interestId=${item.interestId}`)}
                    >
	                    { item.cnt > 0 ? <span>x {item.cnt}</span> : <span>∞</span> }
	                    <label>{item.interestName}</label>
                    </Link>
                  );
                })
              }
            </div>
          </div>
          {
            !(Utils.isValidArray(couponList) || Utils.isValidArray(couponUsedList)) &&
              <div className="empty-txt"><span>暂无优惠券</span></div> ||
              <div className="coupons">
                <div className="title">专属优惠券<div className="active2" onClick={() => {
	                Utils.redirectPage(this, '/rights?raw=1');
                }}>再次激活</div></div>
                <div className="list">
	                {
		                Utils.isValidArray(couponNotUsedList) && couponNotUsedList.map((item, idx) => {
			                return this.renderItem(item, idx, false);
		                })
	                }
                </div>
	              <div className="list history-list">
		              <div className="toggle" style={{ display: 'none' }}>展开历史折扣券<em style={{ backgroundImage: `url(${IMG_SANJIAO})` }} /></div>
		              { Utils.isValidArray(couponUsedList) && <div className="title"><span>历史优惠券</span></div> }
		              {
			              Utils.isValidArray(couponUsedList) && couponUsedList.map((item, idx) => {
				              return this.renderItem(item, idx, true);
			              })
		              }
	              </div>
              </div>
          }
        </section>
      </Page>
    );
  }
}

export default PageUserRights;
