/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './user_order_detail.less';
import moment from 'moment';
import * as Request from '../common/request';
import * as Utils from '../common/utils';
import * as Theme from '../common/theme';
import * as Amap from '../common/amap';
import * as CONST from '../common/const';
import * as Payment from '../common/payment';

import Page from '../compoment/prj/page';

// import IMG_PHONE from '../common/prj-sale/phone.png';
// import IMG_LOCATION from '../common/prj-sale/location.png';

class PageOrderDetail extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      orderInfo: {},
	    loginType: 0,
	    projectInfo: {}
    }
  }
  
  componentDidMount() {
    this.getData();
    this.getLoginType();
    this.getProjectInfo();
  }

	getProjectInfo = async () => {
		const result = await Request.post({
			url: '/project/info/get'
		});

		this.setState({ projectInfoInit: true });
		if(result.ok == 0) {
			this.setState({
				projectInfo: result.obj
			})
		}
	};
  
  getData = async () => {
    const { orderId: bookOrderId } = this.props.match.params;
    const result = await Request.post({
      url: '/book-order/detail',
      data: {
        bookOrderId
      }
    });
  
    if(result.ok == 0) {
    	const orderInfo = result.obj;
      this.setState({
        orderInfo
      });
	
	    this.countDown(orderInfo.expireTime);
    }
  };
	
	getPriceDetail = () => {
		const { orderInfo } = this.state;
		let arr = [
			{ label: '价格', span: `¥${orderInfo.payPrice}` }
		];
		
		if(Utils.isValidArray(orderInfo.bookOrderCoupons)) {
			const lenMap = {};
			let minusCoupon = {};
			orderInfo.bookOrderCoupons.map(item => {
				if(!lenMap[item.interestTypeId]){
					lenMap[item.interestTypeId] = 0;
				}
				lenMap[item.interestTypeId] += 1;
				
				if(item.interestTypeId == CONST.interestTypeIds.CUTOFF_VAL) minusCoupon = item;
			});
			
			orderInfo.bookOrderCoupons.map(item => {
				switch(item.interestTypeId){
					case CONST.interestTypeIds.CUTOFF_VAL:
					case CONST.interestTypeIds.CUTOFF_PERCENT:
						arr.push({
							label: '实际优惠金额',
							span: `¥${Number((orderInfo.totalPrice - orderInfo.payPrice).toFixed(1))}`
						});
						break;
					default:
						arr.push({
							label: item.interestName,
							span: `x ${lenMap[item.interestTypeId]}张`
						});
						break;
				}
			})
		}
		
		return arr;
	};
	
	countDown(seconds) {
		let min = 0, sec = 0;
		const restSec = seconds - parseInt(new Date().getTime() / 1000, 10);
		if(restSec > 0) {
			min = Math.floor(restSec / 60 % 60);
			sec = restSec - min * 60;
		}
		min = Utils.addZero(min);
		sec = Utils.addZero(sec);
		this.setState({ restTime: `${min}:${sec}` });
		this.timer = setTimeout(() => this.countDown(seconds), 1000);
	};
	
	cancelOrder = async (bookOrderId) => {
		const result = await Request.post({
			url: '/book-order/cancel',
			data: {
				bookOrderId
			}
		});
		
		if(result.ok == 0) {
			Utils.dialog.toast('取消成功');
			window.location.reload();
		}
	};
	
	getLoginType = async () => {
		const result = await Request.post({
			url: '/project/login-type'
		});
		
		let loginType = 2;
		if(result.ok == 0) {
			loginType = result.obj.websiteLoginType || 2;
		}
		this.setState({ loginType });
		return loginType;
	};
	
  render() {
    const { orderInfo, restTime } = this.state;
    
    // 优惠权益信息
    let bookCouponsMap = {};
    if(Utils.isValidArray(orderInfo.bookOrderCoupons)) {
      orderInfo.bookOrderCoupons.map(item => {
        bookCouponsMap[item.interestTypeId] = bookCouponsMap[item.interestTypeId] || { interestName: item.interestName, items: [] };
        bookCouponsMap[item.interestTypeId].items.push(item);
      });
    }
    const bookCoupons = Object.keys(bookCouponsMap).map(i => ({
      interestName: bookCouponsMap[i].interestName,
      cnt: bookCouponsMap[i].items.length
    }));
    
    // 统一为毫秒，方便计算
    const useStartTime = orderInfo.useStartTime * 1000;
    const useEndTime = orderInfo.useEndTime * 1000;
	  
    return (
      <Page title="订单详情" {...this.props}>
        <section className="order-detail-wrap">
	        {
		        orderInfo.status == 0 ?
			        <div className="status-toppay-wrap">
				        <div className="status-toppay">
					        <div className="status-time">
						        <div className="status-text theme-secondary-color">{orderInfo.statusText}</div>
						        <div className="time-left">剩余支付时间 {restTime}</div>
					        </div>
					        <div className="order-price"><i className="yen">¥</i>{orderInfo.payPrice}</div>
				        </div>
				        <div className="status-topay-btns">
					        <span onClick={() => {
					        	this.cancelOrder(orderInfo.bookOrderId);
					        }}>取消订单</span>
					        <span className="theme-primary-btncolor topay" onClick={async () => {
						        let loginType = this.state.loginType;
						        if(loginType == 0) {
							        loginType = await this.getLoginType();
						        }
						
						        if(loginType == 1) {
							        window.location.href = `${window.location.origin}/prj/wechat/payment?oid=${orderInfo.bookOrderId}&pname=${Utils.projectName.get()}`
						        }else if(loginType == 2) {
							        this.props.history.push(Utils.getRedirectUrl(`/payment?oid=${orderInfo.bookOrderId}`));
						        }else if(loginType == 3) {
							        const nbankResult = await Payment.doNBankPayment(orderInfo.bookOrderId, `${window.location.origin}/prj/${Utils.projectName.get()}/user/order/${orderInfo.bookOrderId}`, orderInfo.payPrice);
							        if(nbankResult && nbankResult.startsWith('<html>')) {
								        document.write(nbankResult);
							        }
						        }
					        }}>去支付</span>
				        </div>
			        </div> :
			        <div className="status">
				        <div className="status-text">
					        <span>{orderInfo.statusText}</span>
					        <span className="no">订单号：{orderInfo.bookOrderId}</span>
				        </div>
				        <span className="price">{({
					        '0': '待支付', '-1': '订单金额', '-2': '待退款', '-3': '已退款', '10': '已支付', '11': '已支付', '12': '已支付', '13': '已支付'
				        })[orderInfo.status] || '已支付'}：<i className="yen">¥</i>{orderInfo.payPrice}</span>
			        </div>
	        }
          <div className="od-info">
	          {
	          	orderInfo.status == 0 && <div className="order-id">
	              <label>订单号</label>
	              <span>{orderInfo.bookOrderId}</span>
	            </div>
	          }
            <div className="cover" style={{ backgroundImage: `url(${Utils.getImageUrl(orderInfo.hotelImg)})` }} />
            <div className="title">{orderInfo.hotelName}{!!orderInfo.oversea && `(${orderInfo.hotelEnName})`}</div>
            <div className="address">地址：{!!orderInfo.oversea ? orderInfo.hotelEnAddress : orderInfo.hotelAddress}</div>
  
            {
              !orderInfo.oversea &&
              <div className="hotel-entrance">
                <span className="item" onClick={() => {
                  Amap.doGuide([orderInfo.lng, orderInfo.lat]);
                }}>
                  <em style={{ backgroundImage: `url(${Theme.getImages().loc})` }} />导航至酒店
                </span>
                <a className="item" href={`tel:${orderInfo.hotelPhone}`}><em style={{ backgroundImage: `url(${Theme.getImages().phone})` }} />致电酒店</a>
              </div> ||
              <div className="address">
                <div className="content">
                  {/* <div className="info">
                    <label>地址：</label><span>{orderInfo.hotelEnAddress || orderInfo.hotelAddress}</span>
                  </div> */}
                  <CopyToClipboard text={orderInfo.hotelEnAddress || orderInfo.hotelAddress} onCopy={() => Utils.dialog.toast('已复制')}>
                    <div className="guide-btn">复制地址</div>
                  </CopyToClipboard>
                </div>
              </div>
            }
            
            {
              orderInfo.thirdNo && <div className="order-confirm-id">
                <label>酒店确认号</label>
                <span>{orderInfo.thirdNo}</span>
              </div>
            }
            <div className="order-book-info">
              <div className="obi-item">
                <label>入住日期</label>
                <div className="msg">
                  <span>{moment(useStartTime).format('YYYY/M-D')}</span><i>{Utils.datesDiff(useStartTime, useEndTime)}晚</i><span>{moment(useEndTime).format('YYYY/M-D')}</span>
                </div>
              </div>
              <div className="obi-item">
                <label>房型</label>
                <div className="msg">
                  <span>{orderInfo.productName}</span>
                  { !!orderInfo.isLevelUp && !!orderInfo.realProductName && <em className="upgrade-text" style={{ backgroundImage: `url(${Theme.getImages().upgrade})` }} /> }
                  { !!orderInfo.isLevelUp && !!orderInfo.realProductName && <span>{orderInfo.realProductName}</span> }
                </div>
              </div>
	            {
		            !!orderInfo.isLevelUp && <div className="obi-item">
			            <label>床型</label>
			            <div className="msg">
				            <span>{orderInfo.bookDemand}</span>
			            </div>
		            </div>
	            }
              <div className="obi-item">
                <label>入住人</label>
                <span>{orderInfo.guestName}</span>
              </div>
              {
                !!orderInfo.oversea && !!orderInfo.guestNamePy && <div className="obi-item">
                  <label>拼音</label>
                  <span>{orderInfo.guestNamePy}</span>
                </div>
              }
              <div className="obi-item">
                <label>手机号</label>
                <span>{orderInfo.guestMobile}</span>
              </div>
              <div className="obi-item">
                <label>早餐</label>
                <div className="msg">
                  <span>{orderInfo.bookBreakfastType}</span>
                  { !!orderInfo.hasBreakfastLevelUp && !!orderInfo.bookBreakfastLevelUpType && <em className="upgrade-text" style={{ backgroundImage: `url(${Theme.getImages().upgrade})` }} /> }
                  { !!orderInfo.hasBreakfastLevelUp && !!orderInfo.bookBreakfastLevelUpType && <span>{orderInfo.bookBreakfastLevelUpType}</span> }
                </div>
              </div>
            </div>
            {
              orderInfo.bookDemand && <div className="special-living">
                <label>住宿偏好</label>
                <span>{orderInfo.bookDemand}</span>
              </div>
            }
            {
              orderInfo.otherDemand && <div className="special-living">
                <label>其他偏好</label>
                <span>{orderInfo.otherDemand}</span>
              </div>
            }
            <div className="order-info">
              {
                this.getPriceDetail().map((coupon, idx) => {
                  return (
                    <div className="obi-item" key={idx}>
                      <label>{coupon.label}</label><span>{coupon.span}</span>
                    </div>
                  );
                })
              }
            </div>
	          {
	          	!Utils.doHidePrice(this.state.projectInfo) && <div className="total-price">
			          <label>总价</label>
			          <span><i className="yen">¥</i>{orderInfo.totalPrice}</span>
		          </div>
	          }
          </div>
        </section>
      </Page>
    );
  }
}

export default PageOrderDetail;