/**
 * Created by jimmy on 2019/3/8.
 */
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import * as Utils from '../../common/utils';

import './header.less';

const PageHeader = props => {
	// if(!!Utils.ua.isWeChat()) {
	// 	return null;
	// }

	const [ showFlag, setShowFlag ] = useState(false);
	const flagInitial = () => {
		Utils.judgeDkkjVersionwith7(() => {
			// over 7 and hide header
			console.log('over 7');
			setShowFlag(false);
		}, () => {
			// below 7 and show header
			console.log('below 7');
			setShowFlag(true);
		});
	};
	useEffect(() => {
		flagInitial();
	}, [123]);

	if(!showFlag) return null;

	const root = 'prj';
	const pathNameMap = Utils.pathNameMapInitial(props, root);

	// 外链来源标记
	const fr = Utils.urlQuery.get('fr');
	return (
    <div>
	    <div className="page-header">
		    {
			    <div className="back iconfont icon-sanjiaoxing" onClick={() => {
			    	let backKey = props.location.pathname;
			    	if(fr) {
					    backKey = `back-from-${fr}`;
				    }
				    if(!pathNameMap[backKey]) {
					    window.history.go(-1);
					    return;
				    }

			    	if(pathNameMap[backKey] == 'close') {
					    window.close();
				    }else if(pathNameMap[backKey] == 'local'){
			    		const _localValue = Utils.getRedirectPathFromSession(backKey, root);
			    		if(_localValue) {
						    window.location.replace(_localValue);
					    }
				    }else{
					    window.location.replace(pathNameMap[backKey]);
				    }
			    }} />
		    }
		    <div className="txt">{props.title}</div>
		    {
		    	!!Utils.isHome(props, root) && <div className="back" /> || <Link className="back iconfont icon-home" to={Utils.getRedirectUrl('/home', root)} />
		    }
	    </div>
	    <div className="page-header-sapce" />
    </div>
  );
};

export default PageHeader;
