/**
 * Created by jimmy on 2019/3/9.
 */
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './city_list.less';
import * as Utils from '../../common/utils';
import * as Theme from '../../common/theme';
// import IMG_POI from '../common/citic-sale/poi.png';

class CompCityList extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      cityType: 0,    // 0 国内城市；1 国外城市
      list: [],
      hotList: [],
      overseaList: [],
      hotOverseaList: [],
      
      currentList: [],
      currentHotList: [],
	    currentCity: {}
    };
  }
  
  componentDidMount() {
    this.initial(this.props);
  }
  
  componentWillReceiveProps(nextProps,nextState){
    this.initial(nextProps);
  }
  
  initial = (props) => {
    const data = props.data;
    
    let list = [], overseaList = [], hotList = [], hotOverseaList = [];
    Utils.isValidArray(data) && data.map(item => {
      if(!!item.oversea) {
        overseaList.push(item);
        if(!!item.isHot) {
          hotOverseaList.push(item);
        }
      }else{
        list.push(item);
        if(!!item.isHot) {
          hotList.push(item);
        }
      }
    });
    
    const cateByFirstLetter = (list = [], key) => {
      const map = {};
      list.map(item => {
        if(item && item[key]) {
          const fl = item[key].slice(0, 1);
          map[fl] = map[fl] || [];
          map[fl].push(item);
        }
      });
  
      const res = [];
      Object.keys(map).map(item => {
        if(item && Utils.isValidArray(map[item])){
          res.push({
            letter: item.toUpperCase(),
            items: map[item]
          })
        }
      });
      res.sort(function (a, b) {
	      if (a.letter < b.letter){
	      	return -1;
	      } else if (a.letter > b.letter){
	      	return 1;
	      }else{
	      	return 0;
	      }
      });
      return res;
    };
    
    list = cateByFirstLetter(list, 'cityEn');
    overseaList = cateByFirstLetter(overseaList, 'cityEn');
    this.setState({
      list, hotList, overseaList, hotOverseaList,
      currentList: list,
      currentHotList: hotList,
	    currentCity: props.currentCity
    });
  };
  
  cityTypeHandle = (cityType) => {
    this.setState({ cityType });
    const { list, hotList, overseaList, hotOverseaList } = this.state;
    
    let currentList = [], currentHotList = [];
    if(cityType == 0) {
      currentList = list;
      currentHotList = hotList;
    }else if(cityType == 1) {
      currentList = overseaList;
      currentHotList = hotOverseaList;
    }
    this.setState({ currentList, currentHotList });
  };
  
  renderCityItem = (item, idx) => {
    return (
      <span key={idx} onClick={() => this.props.selectHandle(item)}>{!!item.oversea ? item.cityEn : item.cityName}</span>
    );
  };
  
  render() {
    const { list, hotList, overseaList, hotOverseaList, currentList, currentHotList, cityType, currentCity } = this.state;
    
    const hasOversea = Utils.isValidArray(overseaList);
    return (
      <div className="city-list">
        <div className="title">请选择城市<em className="close" onClick={this.props.cancelHandle} /></div>
        <div className="current-city">
          <div>
            <em style={{ backgroundImage: `url(${Theme.getImages().poi})` }} />
	          <label>{currentCity.cityName}</label>
            <span>当前定位城市</span>
          </div>
          {/*<div className="all-citys">全部城市</div>*/}
        </div>
        {
          !!hasOversea && <div className="national-tabs">
            <span className={cityType == 0 ? 'active' : ''} onClick={() => this.cityTypeHandle(0)}>国内<em className="theme-primary-btncolor" /></span>
            <span className={cityType == 1 ? 'active' : ''} onClick={() => this.cityTypeHandle(1)}>国外<em className="theme-primary-btncolor" /></span>
          </div>
        }
        <div className="city-wrap">
          {
            Utils.isValidArray(currentHotList) && <div className="hot-city">
              <label>热门城市</label>
              <div className="list">
                {
                  currentHotList.map(this.renderCityItem)
                }
              </div>
            </div>
          }
          <div className="all-city">
            <label>选择城市</label>
            {
              Utils.isValidArray(currentList) && currentList.map((l, idx) => {
                return (
                  <div className="list" key={idx}>
                    <em>{l.letter}</em>
                    <div>
                      {
                        l.items.map(this.renderCityItem)
                      }
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
}

export default CompCityList;

