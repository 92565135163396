import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './app.less';

import PageIntro from './intro';
import PageLogin from './login';
import PageRights from './rights';
import PageHome from './home';
import PageRule from './rule';
import PageHotelImgList from './hotel_img_list';
import PageHotelDetail from './hotel_detail';
import PageBook from './book';
import PageUserOrders from './user_orders';
import PageUserOrderDetail from './user_order_detail';
import PagePayment from './payment';
import PagePaymentResult from './payment_result';
import PageUserRights from './user_rights';
import PageUserRightsInfo from './user_rights_info';
import PagePrdList from './prd_list';
import PagePrdListNew from './prd_list_new';
import PagePrdMallList from './prd_mall_list';
import PagePrdListJuly from './prd-list-july';
import PagePrdJoyList from './prd_joy_list';
import PageCBUserInfo from './cb_userinfo';
import PageIcbcPrdList from './icbc_prd_list';
import PagePrdNbankList from './prd_list_nbank';

class App extends Component {
  render() {
    return (
      <Router>
        <div>
	        <Route exact path="/citic/cb/userInfo" component={PageCBUserInfo} />
          <Route exact path="/prj/citic-call/prd-list" component={PagePrdList} />
          <Route exact path="/prj/citic-call/prd-list-new" component={PagePrdListNew} />
          <Route exact path="/prj/citic-call/mall-list" component={PagePrdMallList} />
          <Route exact path="/prj/citic-call/joy-list" component={PagePrdJoyList} />
          <Route exact path="/prj/citic-call/prd-list-july" component={PagePrdListJuly} />
          <Route exact path="/prj/icbc-mall/prd-list" component={PageIcbcPrdList} />
          <Route exact path="/prj/nbank-call/prd-list" component={PagePrdNbankList} />
	        <Route exact path="/prj/wechat/payment" component={PagePayment} />
          <Route exact path="/prj/:pname/" component={PageIntro} />
          <Route exact path="/prj/:pname/intro" component={PageIntro} />
          <Route exact path="/prj/:pname/login" component={PageLogin} />
          <Route exact path="/prj/:pname/home" component={PageHome} />
          <Route exact path="/prj/:pname/rule" component={PageRule} />
          <Route exact path="/prj/:pname/rights" component={PageRights} />
          <Route exact path="/prj/:pname/hotel/:hotelId/imgs" component={PageHotelImgList} />
          <Route exact path="/prj/:pname/hotel/:hotelId/detail" component={PageHotelDetail} />
          <Route exact path="/prj/:pname/hotel/:productId/book" component={PageBook} />
          <Route exact path="/prj/:pname/payment" component={PagePayment} />
          <Route exact path="/prj/:pname/user/orders" component={PageUserOrders} />
          <Route exact path="/prj/:pname/user/order/:orderId" component={PageUserOrderDetail} />
          <Route exact path="/prj/:pname/user/rights" component={PageUserRights} />
          <Route exact path="/prj/:pname/user/rights/info" component={PageUserRightsInfo} />
          <Route exact path="/prj/:pname/payment/result" component={PagePaymentResult} />
        </div>
      </Router>
    );
  }
}

export default App;
