/**
 * Created by jimmy on 2019/3/9.
 */
import React, { Component } from 'react';
import './hotel_img_list.less';

import Page from '../compoment/prj/page';

class PageHotelImgList extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      imgList: [],
      cateList: [],
      curCate: '全部'
    }
  }
  
  componentDidMount() {
    this.initialList();
    this.initialCateList();
  }
  
  initialList = () => {
    this.setState({
      imgList: new Array(9).fill(666)
    })
  };
  
  initialCateList = () => {
    this.setState({
      cateList: [
        { title: '全部', count: 30 },
        { title: '外观', count: 10 },
        { title: '内景', count: 8 },
        { title: '房间', count: 14 },
        { title: '环境', count: 6 }
      ]
    })
  };
  
  cateChangeHandle = (target) => {
    this.setState({
      curCate: target.title
    })
  };
  
  render() {
    const { imgList, cateList, curCate } = this.state;
    return (
      <Page title="图片列表" {...this.props}>
        <section className="hotel-img-list-wrap">
          <div className="img-filters">
            <div className="img-filters-wrap">
              {
                cateList.map((cate, idx) => (
                  <div className={`filter-item${curCate == cate.title ? ' able' : ''}`} key={idx} onClick={this.cateChangeHandle.bind(this, cate)}>{cate.title} {cate.count}</div>
                ))
              }
            </div>
          </div>
          <div className="img-list-container">
            {
              imgList.map((img, idx) => (
                <div className="img-item" key={idx} />
              ))
            }
          </div>
        </section>
      </Page>
    );
  }
}

export default PageHotelImgList;

