/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import moment from 'moment';
import './book.less';
import * as Utils from '../common/utils';
import * as Request from '../common/request';
import * as CONST from '../common/const';
import * as Theme from '../common/theme';
// import IMG_SANJIAO from '../common/prj-sale/sanjiao.png';
// import IMG_SELECTED from '../common/prj-sale/selected.png';
import Page from '../compoment/prj/page';
import Calendar from '../compoment/prj/calendar';
import Coupons from '../compoment/prj/coupons';
import * as Payment from '../common/payment';

class PageBook extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      /**
       * 各种开关
       */
      specialFlag: true,
      priceFlag: false,
      confirmFlag: false,
      calendarFlag: false,
      couponsFlag: false,

      /**
       * 订单提交信息
       */
      bookObj: {
        bookDemand: '', // 预订需求
        guestMobile: '', // 入住人手机号
        guestName: '', // 入住人
        guestNamePy: '', // 入住人拼音
        otherDemand: '',  // 其他要求
        payMethod: 0,   // 支付方式:0微信
        payPrice: 0,  // 需支付金额
        productId: this.props.match.params.productId, // 产品ID
        totalPrice: 0,   // 订单总金额
        useEndTime: '',   // 预约结束时间-离店时间：yyyy-MM-dd
        useStartTime: '',   // 预约起始时间-入住时间：yyyy-MM-dd
        userInterests: []   // 优惠券ID列表
      },

      /**
       * 当前产品信息
       */
      productObj: {},
	
	    /**
	     * 当前酒店信息
	     */
	    hotelInfo: {},

	    /**
	     * 项目配置信息
	     */
	    projectInfo: {},
	    projectInfoInit: false,

      /**
       * 展示、交互需求的临时信息
       */
      tempObj: {
        couponsLength: 0, // 所有优惠券数量
        coupons: [],  // 选中优惠券完整信息
        selectedDates: [],  // 选中日期

        orderSubmitStatus: 0, // 订单提交状态
        confirmList: [],  // 订单提交确认信息，loading 时使用
        confirmDone: 0,  // 订单提交时确认信息效果和提交订单状态同步
	      maxBalanceCutoff: 0, // 当前日期最大优惠金额，单位：元
      },
	    
	    // 是否只允许自己使用
	    isOnlySelfUse: false,
	
	    loginType: 0
    }
  }
  
  componentDidMount() {
    this.getProduct();
    this.getBookInfo();
	  this.getHotelInfo();
	  this.getLoginType();
	  this.getProjectInfo();

	  window.scrollTo(0, 0);
  }
	
	getProduct = () => {
    const { productId } = this.props.match.params;
    let productInfo = Utils.localCache({ key: 'productList' });
    productInfo = JSON.parse(productInfo);
    const productObj = productInfo[productId] || {};
    this.setState({ productObj });
  };
  
  getHotelInfo = () => {
	  let hotelInfo = Utils.localCache({ key: 'hotelInfo' });
	  hotelInfo = hotelInfo ? JSON.parse(hotelInfo) : {};
	  this.setState({ hotelInfo });
  };

	getProjectInfo = async () => {
		const result = await Request.post({
			url: '/project/info/get'
		});

		this.setState({ projectInfoInit: true });
		if(result.ok == 0) {
			this.setState({
				projectInfo: result.obj
			})
		}
	};
	
	getMaxBalanceCutoff = async () => {
		const { bookObj, tempObj } = this.state;
		const { productId } = this.props.match.params;
		const result = await Request.post({
			url: '/book-order/pre-check-balance',
			data: {
				productId,
				useEndTime: bookObj.useEndTime,
				useStartTime: bookObj.useStartTime
			}
		});
		if(result && result.ok == 0) {
			tempObj.maxBalanceCutoff = result.obj.maxBalanceCutoff;
			this.setState({
				tempObj
			});
		}
	};
  
  getBookInfo = async () => {
	  const result = await Request.post({
		  url: '/project/isOnlySelfUse/get'
	  });
	
	  if(result.ok == 0) {
	  	const isOnlySelfUse = result.obj.isOnlySelfUse;
		  this.setState({
			  isOnlySelfUse
		  });
		  
		  if(!!isOnlySelfUse) {
		  	const userInfo = Utils.userInfo.get();
		  	this.setState({
				  bookObj: {
					  ...this.state.bookObj,
					  guestMobile: userInfo.mobile,
					  guestName: userInfo.userRealName
				  }
			  })
		  }
	  }
  };
  
  /**
   * 价格计算
   * 
   * totalPrice是指按原价计算，总共的价格。
   * payPrice是指优惠之后要支付的价格。
   *
   * 首先所有只能订一个晚上的，只有住一送一券是可以订两晚的。
   *
   * 优先级如下顺序
   * 1、免房券，payPrice 为 0
   * 2、住一送一券，payPrice 为最贵的那天房价
   * 3、立减券
   * 4、折扣券
   */
  calcPrice = () => {
    const { tempObj, bookObj } = this.state;
  
    // 初始价格
    let totalPrice = 0, payPrice = 0;
    let higherPrice = 0;
    if(Utils.isValidArray(tempObj.selectedDates)) {
      for(let i = 0, len = tempObj.selectedDates.length - 1; i < len; i++){
        const dateItem = tempObj.selectedDates[i];
        totalPrice += dateItem.price;
        payPrice += dateItem.price;
  
        higherPrice = Math.max(higherPrice, dateItem.price);
      }
    }
    
    // 权益计算
    const coupons = tempObj.coupons;
    if(Utils.isValidArray(coupons)) {
      let cutoffPercent = {};
      coupons.map(coupon => {
        if(coupon.interestTypeId == CONST.interestTypeIds.CUTOFF_PERCENT) {
          cutoffPercent = coupon;
        }
        
        switch (coupon.interestTypeId){
          case CONST.interestTypeIds.FREE: // 免房券
            payPrice = 0;
            break;
          case CONST.interestTypeIds.FREEONE: // 住一送一，选择贵的那一晚支付
            payPrice = higherPrice;
            break;
          case CONST.interestTypeIds.CUTOFF_VAL: // 立减券，满足一定金额额度可以减去一定金额
            if(payPrice >= coupon.minTotalPrice) {
              payPrice = payPrice - coupon.cutoffValue;
            }
            break;
        }
      });
      
      // 折扣券放在最后算
      if(
        cutoffPercent && 
        cutoffPercent.interestTypeId == CONST.interestTypeIds.CUTOFF_PERCENT && 
        payPrice > cutoffPercent.minTotalPrice
      ) {
	      let cutOffVal = payPrice * (1 - cutoffPercent.cutoffValue);
	      payPrice = payPrice - Math.min(cutOffVal, Math.min(cutoffPercent.maxCutoffPrice, tempObj.maxBalanceCutoff));
      }
    }
    
    this.setState({
      bookObj: {
        ...bookObj,
        totalPrice,
        payPrice
      }
    });
  };

  paramsVarify = (params = {}) => {
    const { productObj, projectInfo } = this.state;
    if(!params.useStartTime || !params.useEndTime) {
      Utils.dialog.toast(CONST.ERROR_TIPS.NO_EMPTY_DATE);
      return false;
    }else if(!params.guestMobile){
      Utils.dialog.toast(CONST.ERROR_TIPS.NO_EMPTY_MOBILE);
      return false;
    }else if((productObj.oversea && !params.guestNamePy) || (!productObj.oversea && !params.guestName)){
      Utils.dialog.toast(CONST.ERROR_TIPS.NO_EMPTY_NAME);
      return false;
    }else if ((projectInfo && projectInfo.mustUseInterest == 1) && params.userInterests && params.userInterests.length == 0) {
	    Utils.dialog.toast(CONST.ERROR_TIPS.NO_EMPTY_COUPON);
	    return false;
    }

    return true;
  };

  showConfirmInfo = () => {
    const { productObj, bookObj, tempObj, hotelInfo, projectInfo } = this.state;
    
    const confirmList = [
      { content: hotelInfo.name, display: false },
      { content: productObj.name, display: false },
      { content: `${Utils.dateDisplayer(bookObj.useStartTime)}-${Utils.dateDisplayer(bookObj.useEndTime)}，共${Utils.datesDiff(bookObj.useStartTime, bookObj.useEndTime)}晚`, display: false },
      { content: `入住人：${bookObj.guestName}`, display: false }
    ];
    if(Utils.doHidePrice(projectInfo)) {
	    confirmList.push(
		    { content: `支付总价 ¥${bookObj.payPrice}`, display: false }
	    )
    }
    this.setState({
      confirmFlag: true,
      tempObj: { ...tempObj, confirmList }
    }, () => {
      setTimeout(() => {
        for(let i = 0; i < confirmList.length; i++) {
          confirmList[i].display = true;
        }
        this.setState({
          tempObj: { ...tempObj, confirmList }
        })
      }, 100);
    });

    return {
      hide: () => {
        this.setState({ confirmFlag: false });
      }
    }
  }

  getPriceDetail = () => {
    const { tempObj, bookObj, productObj } = this.state;
    let arr = [
      { 
        label: `房费 x ${tempObj.selectedDates.length - 1}晚 ${productObj.bedTypeName}`, 
        span: `¥${(dates => {
          let price = 0;
          for(let i = 0, len = dates.length - 1; i < len; i++) {
            price += dates[i].price;
          }
          
          return price;
        })(tempObj.selectedDates)}` 
      }
    ];
    
    if(Utils.isValidArray(tempObj.coupons)) {
      const lenMap = {};
      let minusCoupon = {};
      tempObj.coupons.map(item => {
        if(!lenMap[item.interestTypeId]){
          lenMap[item.interestTypeId] = 0;
        }
        lenMap[item.interestTypeId] += 1;
        
        if(item.interestTypeId == CONST.interestTypeIds.CUTOFF_VAL) minusCoupon = item;
      });
      
      tempObj.coupons.map(item => {
        arr.push({
          label: item.interestName,
          span: (() => {
            let span = '';
            switch(item.interestTypeId){
              case CONST.interestTypeIds.CUTOFF_VAL:
                span = `-¥${item.cutoffValue}`;
                break;
              case CONST.interestTypeIds.CUTOFF_PERCENT:
                span = (() => {
                  let span = '';
	                let _payPrice = bookObj.totalPrice;
                  if(!!minusCoupon.interestTypeId && bookObj.totalPrice > minusCoupon.minTotalPrice) {
	                  _payPrice = bookObj.totalPrice - minusCoupon.minTotalPrice;
                  }
	                span = _payPrice * (1 - item.cutoffValue);
	                span = Number(span);
	                span = Math.min(span, Math.min(item.maxCutoffPrice, tempObj.maxBalanceCutoff));
	                return span > 0 ? `-¥${parseFloat(span.toFixed(2))}` : 0;
                })();
                break;
              default:
                span = `x ${lenMap[item.interestTypeId]}张`;
                break;
            }
            return span;
          })()
        });
      })
    }
    arr.push({ label: '应付金额', span: `¥${bookObj.payPrice}`});
    
    return arr;
  };

  submitOrder = async () => {
    let { bookObj, loginType, projectInfo } = this.state;

    // 参数校验
    if(!this.paramsVarify(bookObj)) return;

    // 展示 confirm 内容
    const confirmInfoObj = this.showConfirmInfo();

    // 提交订单
    const result = await Request.post({
      url: '/book-order/create',
      data: bookObj
    });

    if(result.ok == 0) {
      setTimeout(() => {
        Utils.dialog.toast(CONST.ERROR_TIPS.ORDER_SUBMIT_SUC);
        setTimeout(async () => {
          confirmInfoObj.hide();
	
	        if(loginType == 0) {
		        loginType = await this.getLoginType();
	        }
	
	        if(loginType == 1) {
		        if(Utils.doHidePrice(projectInfo)) {
			        window.location.href = `${window.location.origin}/prj/${Utils.projectName.get()}/user/order/${result.obj.bookOrderId}`;
		        }else{
			        window.location.href = `${window.location.origin}/prj/wechat/payment?oid=${result.obj.bookOrderId}&pname=${Utils.projectName.get()}`
		        }
	        }else if(loginType == 2) {
		        if(Utils.doHidePrice(projectInfo)) {
			        this.props.history.push(Utils.getRedirectUrl(`/user/order/${result.obj.bookOrderId}`));
		        }else {
			        this.props.history.push(Utils.getRedirectUrl(`/payment?oid=${result.obj.bookOrderId}`));
		        }
	        }else if(loginType == 3) {
	        	const order = result.obj;
	        	const orderDetailLink = `${window.location.origin}/prj/${Utils.projectName.get()}/user/order/${order.bookOrderId}`;
		        const nbankResult = await Payment.doNBankPayment(order.bookOrderId, orderDetailLink, order.payPrice);
		        if(order.payPrice == 0) {
			        window.location.href = orderDetailLink;
		        }else{
			        if(nbankResult && nbankResult.startsWith('<html>')) {
				        document.write(nbankResult);
			        }
		        }
	        }
        }, 2000);
      }, 2800);
    }else{
      confirmInfoObj.hide();
    }
  };
	
	getLoginType = async () => {
		const result = await Request.post({
			url: '/project/login-type'
		});
		
		let loginType = 2;
		if(result.ok == 0) {
			loginType = result.obj.websiteLoginType || 2;
		}
		this.setState({ loginType });
		return loginType;
	};
  
  render() {
    const {
      specialFlag, priceFlag, confirmFlag, calendarFlag, couponsFlag,
      tempObj, bookObj, productObj,
	    isOnlySelfUse,
	    projectInfo,
	    projectInfoInit
    } = this.state;
    
    return (
      <Page title="订单填写" {...this.props}>
        <section className="book-wrap">
          <div className="hotel-info">
            <div className="title">{productObj.name}</div>
            <div className="date" onClick={() => {
              this.setState({ calendarFlag: true });
            }}>
              <div className="date-item">
                <label>入住</label><div className={`value${!bookObj.useStartTime && ' value-empty' || ''}`}>{Utils.dateDisplayer(bookObj.useStartTime)}</div>
              </div>
              <div className="night-long">{Utils.datesDiff(bookObj.useStartTime, bookObj.useEndTime)}晚</div>
              <div className="date-item">
                <label>离店</label><div className={`value${!bookObj.useEndTime && ' value-empty' || ''}`}>{Utils.dateDisplayer(bookObj.useEndTime)}</div>
              </div>
            </div>
            <div className="tip">{CONST.ERROR_TIPS.ORDER_CONFIRM_NO_UPDATE}</div>
	          <em className="hotel-info-bg theme-primary-bgcolor" />
          </div>

          <div className="form-block">
            <div className="block-title">入住信息</div>
            <div className="form-item">
              <label>入住人</label>
              <input
	              placeholder="填写实际入住人姓名"
	              value={bookObj.guestName}
	              disabled={!!isOnlySelfUse}
	              onChange={e => {
	                bookObj.guestName = e.target.value;
	                this.setState({ bookObj });
	              }} />
            </div>
            {
              !!productObj.oversea && <div className="form-item">
                <label>拼音</label>
                <input placeholder="与护照/通行证拼音一致" value={bookObj.guestNamePy} onChange={e => {
                  bookObj.guestNamePy = e.target.value;
                  this.setState({ bookObj });
                }} />
              </div>
            }
            <div className="form-item">
              <label>手机号</label>
              <input
	              placeholder="填写手机号"
	              disabled={!!isOnlySelfUse}
                maxLength={11}
                value={bookObj.guestMobile}
	              onChange={e => {
	                bookObj.guestMobile = e.target.value;
	                this.setState({ bookObj });
	              }}
              />
            </div>
          </div>

          {
            tempObj.couponsLength > 0 && <div className="coupon-block">
              <div className="block-title">专享权益</div>
              {
                Utils.isValidArray(tempObj.coupons) &&
                  <div className={(!bookObj.useStartTime || !bookObj.useEndTime) ? 'coupon-area disabled' : 'coupon-area'} onClick={() => {
	                  if(!bookObj.useStartTime || !bookObj.useEndTime) return;
	                  
                    this.setState({ couponsFlag: true });
                  }}>
                    <span>专享优惠券</span>
                    <div className="info">
                      <div className="coupon-detail">
                        {
                          tempObj.coupons.map((coupon, idx) => <span className="theme-primary-color" key={idx}>{coupon.interestName}</span>)
                        }
                      </div>
                      <em style={{ backgroundImage: `url(${Theme.getImages().sanJiao})` }} />
                    </div>
	                  <em className="opacity-bg theme-primary-bgcolor" />
                  </div> ||
                  <div className={(!bookObj.useStartTime || !bookObj.useEndTime) ? 'coupon-area disabled' : 'coupon-area'} onClick={() => {
	                  if(!bookObj.useStartTime || !bookObj.useEndTime) return;
	                  
                    this.setState({ couponsFlag: true });
                  }}>
                    <span>专享优惠券</span>
                    <div className="info">
                      <span>当前可用{tempObj.couponsLength}张</span>
                      <em style={{ backgroundImage: `url(${Theme.getImages().sanJiao})` }} />
                    </div>
	                  <em className="opacity-bg theme-primary-bgcolor" />
                  </div>
              }
            </div>
          }
          
          <div className="special-block">
            <div className="block-title" onClick={() => {
              this.setState({
                specialFlag: !this.state.specialFlag
              });
            }}>特殊要求<em className={!!specialFlag && 'active' || ''} style={{ backgroundImage: `url(${Theme.getImages().sanJiao})` }} /></div>
            {
              !!specialFlag && <div>
                <div className="special-content">{CONST.ERROR_TIPS.NO_SATISFY_ALL_REQUIRES}</div>
                <div className="special-living">
                  <div className="title">住宿偏好</div>
                  <div className="special-item">
                    {
                      CONST.bookDemands.map((item, idx) => {
                        return (
                          <span key={idx} className={bookObj.bookDemand.indexOf(item) > -1 ? 'active' : ''} onClick={() => {
                            if(!bookObj.bookDemand) {
                              bookObj.bookDemand = item;
                            }else{
                              if(bookObj.bookDemand.indexOf(item) > -1) {
                                bookObj.bookDemand = bookObj.bookDemand.replace(item, '');
                              }else{
                              	if(bookObj.bookDemand.indexOf('大床') > -1 && item == '双床') {
		                              bookObj.bookDemand = bookObj.bookDemand.replace('大床', '');
	                              }
	                              if(bookObj.bookDemand.indexOf('双床') > -1 && item == '大床') {
		                              bookObj.bookDemand = bookObj.bookDemand.replace('双床', '');
	                              }
                                bookObj.bookDemand += ` ${item}`;
                              }
                            }
                            bookObj.bookDemand = bookObj.bookDemand.replace(/(^\s*)|(\s*$)/g, "");
                            this.setState({ bookObj });
                          }}>{item}</span>
                        );
                      })
                    }
                  </div>
                </div>
                <div className="special-else">
                  <div className="title">其他要求</div>
                  <div className="else-area">
                    <textarea placeholder="其他特殊要求" value={bookObj.otherDemand} onChange={e => {
                      bookObj.otherDemand = e.target.value;
                      this.setState({ bookObj });
                    }} />
                  </div>
                </div>
              </div>
            }
          </div>
        
          <div className="bottom-block">
	          {
		          !!projectInfoInit && !(Utils.doHidePrice(projectInfo)) && <div className="price">
			          <span className="book-value"><i className="yen">¥</i>{bookObj.payPrice}</span>
			          { bookObj.totalPrice - bookObj.payPrice > 0 && <span className="coupon-value">已优惠 <i className="yen">¥</i>{(bookObj.totalPrice - bookObj.payPrice).toFixed(2)}</span> }
		          </div> || <div className="price" />
	          }
            <div className="info-block">
              {
	              !!projectInfoInit && !(Utils.doHidePrice(projectInfo)) && Utils.isValidArray(tempObj.selectedDates) && <div className="price-detail theme-secondary-color" onClick={() => {
                  this.setState({ priceFlag: !this.state.priceFlag });
                }}>明细<em className={!!priceFlag ? 'active' : ''} style={{ backgroundImage: `url(${Theme.getImages().sanJiao})` }} /></div>
              }
              <div className="submit-btn theme-primary-btncolor" onClick={this.submitOrder}>提交订单</div>
            </div>
          </div>
          
          {/* 价格明细模块 */}
          {
            !!priceFlag && Utils.isValidArray(tempObj.selectedDates) && <div>
              <div className="price-detail-content-mask" onClick={() => {
                this.setState({ priceFlag: false });
              }} />
              <div className="price-detail-content">
                <div className="title">{productObj.name}</div>
                <div className="details">
                  {
                    
                    this.getPriceDetail().map((item, idx) => {
                    	if(!item.span) return null;
                    
	                    return (
		                    <div className="detail-item" key={idx}>
			                    <label>{item.label}</label><span className="yen">{item.span}</span>
		                    </div>
	                    )
                    })
                  }
                </div>
              </div>
            </div>
          }
          
          {/* 订单确认模块 */}
          {
            !!confirmFlag && <div>
              <div className="confirm-book-mask" onClick={() => {
                this.setState({
                  confirmFlag: false
                })
              }} />
              <div className="confirm-book">
                <div className="content">
                  {
                    tempObj.confirmList.map((item, idx) => {
                      return (
                        <div className={`c-item ${item.display ? `ci${idx + 1}` : ''}`} key={idx}>
                          <label className="yen">{item.content}</label><i style={{ backgroundImage: `url(${Theme.getImages().selected})` }} />
                        </div>
                      )
                    })
                  }
                </div>
                <div className="btn">{CONST.ERROR_TIPS.ORDER_SUBMIT_ING}</div>
              </div>
            </div>
          }
          
          {/* 日期模块 */}
          <div>
            { !!calendarFlag && <div className="calendar-wrap-mask" /> }
            <div className={calendarFlag ? 'calendar-container calendar-container-out' : 'calendar-container'}>
              <Calendar
                {...this.props}
                shouldClear={tempObj.selectedDates.length == 0}
                has6Coupon={(() => {
                  const typeIds = tempObj.coupons.map(coupon => coupon.interestTypeId);
                  return typeIds.indexOf(CONST.interestTypeIds.FREEONE) > -1;
                })()}
                selectHandle={dates => {
                  const startObj = dates[0] || {};
                  const endObj = dates[dates.length - 1] || {};
                  this.setState({
                    bookObj: {
                      ...bookObj,
                      useStartTime: startObj.date,
                      useEndTime: endObj.date,
                    },
                    tempObj: {
                      ...tempObj,
                      selectedDates: dates
                    },
                    calendarFlag: false
                  }, async () => {
                  	await this.getMaxBalanceCutoff();
	                  this.calcPrice();
                  });
                }}
                cancelHandle={() => {
                  this.setState({
                    calendarFlag: false
                  });
                }}
              />
            </div>
  
            <div className={couponsFlag ? 'coupons-container coupons-container-out' : 'coupons-container'}>
              <Coupons
                breakfast={productObj.breakfast}
                {...this.props}
                initialHandle={(coupons = []) => {
                  tempObj.couponsLength = coupons.length;
                  this.setState({ tempObj });
                }}
                selectHandle={obj => {
                  const ids = obj.coupons.map(coupon => coupon.userInterestId);
                  const typeIds = obj.coupons.map(coupon => coupon.interestTypeId);
                  
                  bookObj.userInterests = ids;
                  const dayDiff = moment(bookObj.useStartTime).diff(bookObj.useEndTime, 'day');
                  if(
                    (typeIds.indexOf(CONST.interestTypeIds.FREEONE) > -1 && bookObj.useStartTime && bookObj.useEndTime && Math.abs(dayDiff) == 1) ||
                    (typeIds.indexOf(CONST.interestTypeIds.FREEONE) == -1 && bookObj.useStartTime && bookObj.useEndTime && Math.abs(dayDiff) == 2)
                  ) {
                    Utils.dialog.toast(CONST.ERROR_TIPS.INTEREST_UPTO_FREEONE);
                    bookObj.useStartTime = '';
                    bookObj.useEndTime = '';
                    bookObj.payPrice = 0;
                    bookObj.totalPrice = 0;
                    tempObj.selectedDates = [];
                  }
                  tempObj.coupons = obj.coupons || [];
                  
                  this.setState({
                    bookObj,
                    tempObj,
                    couponsFlag:false
                  }, this.calcPrice);
                }}
                backHandle={() => {
                  this.setState({ couponsFlag: false });
                }}
              />
            </div>
          </div>
        </section>
      </Page>
    );
  }
}

export default PageBook;