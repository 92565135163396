/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import Page from '../compoment/prj/page';

import * as Utils from '../common/utils';

class PageCBUserInfo extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			
		}
	}
	
	componentDidMount() {
		this.autoRedirect();
	}
	
	autoRedirect = () => {
		const href = window.location.href;
		const hrefArr = href.split('retUrl=');    // 比较 hack 的解析方案，因为初衷就是要解析链接，但是对方跳回来的链接没有 encodeURIComponent，常规解析的时候会导致 retUrl 的值参数缺失；这个方案默认约定 retUrl 要在链接最后，现阶段需求下来看问题不大
		let retUrl = Utils.urlQuery.get('retUrl');
		if(hrefArr && hrefArr[1]) {
			retUrl = hrefArr[1];
		}
		retUrl = decodeURIComponent(retUrl);
		console.log('cbuserinfo', retUrl);
		
		const accessCode = Utils.urlQuery.get('accessCode', retUrl) || Utils.urlQuery.get('accessCode');
		const retCnt = Utils.urlQuery.get('retCnt');
		retUrl = Utils.urlQuery.set({ accessCode, retCnt }, retUrl).href;

		window.location.href = retUrl;
	};
	
	render() {
		return (
			<div style={{ backgroundColor: '#fff' }} />
		);
	}
}

export default PageCBUserInfo;
