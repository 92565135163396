/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import './rights.less';
import * as Utils from '../common/utils';
import * as Request from '../common/request';
import Page from '../compoment/prj/page';

class PageRights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCode: '',
	    projectInfo: {}
    };
  }
  
  componentDidMount() {
	  if(!Utils.urlQuery.get('raw')){
		  this.checkActive();
	  }
    this.getConfig();
  }

  getConfig = async () => {
	  const result = await Request.post({
		  url: '/project/info/get'
	  });
	  if(result && result.ok == 0) {
		  this.setState({
			  projectInfo: result.obj
		  })
	  }
  };
  
  checkActive = () => {
    const userInfo = Utils.userInfo.get();
    if(userInfo.actived != 0) {
      Utils.redirectPage(this, '/home');
    }
  };
  
  activeHandle = async () => {
    const { activeCode } = this.state;
    if(!activeCode) {
      Utils.dialog.toast('激活码不能为空');
      return;
    }
    
    const result = await Request.post({
      url: '/user/bind-code',
      data: {
        activeCode
      }
    });
    
    if(result.ok == 0) {
      Utils.dialog.toast('激活成功');
      Utils.userInfo.set({
        ...Utils.userInfo.get(),
        actived: 1
      });
      Utils.redirectPage(this, '/home');
    }
  };
  
  render() {
    const { activeCode, projectInfo } = this.state;
    return (
      <Page title="权益" {...this.props}>
        <section className="rights-wrap">
          {/*<div className="icon" />*/}
	        { projectInfo && projectInfo.logo && <img className="icon" src={projectInfo.logo} /> || <div className="icon-new" style={{ height: 30 }} /> }
          <div className="main-wrap">
            <div className="main">
              <div className="title" />
              <div className="input-area">
                <InputArea
                  label="激活码"
                  placeholder="请输入激活码"
                  value={activeCode}
                  onChange={event => {
                    this.setState({
                      activeCode: event.target.value
                    })
                  }}
                />
              </div>
              <div className="submit-btn" onClick={this.activeHandle}>开启尊享之旅</div>
            </div>
          </div>
        </section>
      </Page>
    );
  }
}

class InputArea extends Component {
  render() {
    const { label, placeholder, isCode, onChange, value } = this.props;
    return (
      <div className="input-wrap">
        <label>{label}</label>
        <input placeholder={placeholder} onChange={onChange} value={value} />
        { isCode && <div className="code-btn">获取验证码</div> }
      </div>
    );
  }
}

export default PageRights;
