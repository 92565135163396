/**
 * Created by jimmy on 2019/3/8.
 */
/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import * as Utils from '../../common/utils';
import * as Request from '../../common/request';
import PageHeader from './header';
import './page.less';

class CompHeader extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			logoutFlag: false
		}
	}
	
	componentDidMount() {
		this.initial(this.props);
	}
	
	componentWillReceiveProps(nextProps) {
		this.initial(nextProps);
	}
	
	logoutHandle = async () => {
		const result = await Request.post({
			url: '/user/logout'
		});
		if(result.ok == 0) {
			Utils.userInfo.clear();
			Utils.redirectPage(this, '/login?t=1', 4020);
		}
	};
	
	initial = props => {
		if(props.title){
			Utils.setTitle(props.title);
		}
		// if(props.title != '订单填写'){
		// 	window.scrollTo(0, 0);
		// }
		
		let longTimer = null;
		let $refpr = this.refs.pageRoot;
		$refpr.addEventListener('touchstart',  (event) => {
			longTimer = setTimeout(() => {
				event.preventDefault();
				this.setState({ logoutFlag: true }, () => {
					setTimeout(() => {
						this.setState({ logoutFlag: false });
					}, 2000);
				});
			}, 1000);
		}, false);
		$refpr.addEventListener('touchmove', (event) => {
			if(this.state.logoutFlag){
				event.preventDefault();
			}
			clearTimeout(longTimer);
			longTimer = null;
		}, false);
		$refpr.addEventListener('touchend',  (event) => {
			if(this.state.logoutFlag){
				event.preventDefault();
			}
			clearTimeout(longTimer);
			return false;
		}, false);
	};
	
  render() {
  	const { logoutFlag } = this.state;
    const { title, style } = this.props;
    const userInfo = Utils.userInfo.get() || {};
    
    return (
      <section className="page-container" style={{ ...style, minHeight: window.innerHeight }} ref="pageRoot">
        {/*
          false && <header className="header">
            <span>返回</span>
            <span className="title">{title}</span>
          </header>
        */}
        <PageHeader {...this.props} />
	      <div className={`login-out${!!logoutFlag ? ' display' : ''}`} ref="logout">
		      <em>{userInfo.mobile}</em><span onClick={this.logoutHandle}>退出登录</span>
	      </div>
        <section>{this.props.children}</section>
      </section>
    );
  }
}

export default CompHeader;
