/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import './prd_list_nbank.less';
import Page from '../compoment/prj/page';
import * as Utils from '../common/utils';

import IMG_CXYNEW_1 from '../common/images/nbank/0822.jpg';

const IMG_MAP = {
  IMG_CXYNEW_1
};

class PagePrdNbankList extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {
    Utils.setTitle('产品列表');
  }


  render() {
    return (
      <section className="prdlist-wrap">
        {
          Object.keys(IMG_MAP).map((img, idx) => (
            <img src={IMG_MAP[img]} key={idx} />
          ))
        }
      </section>
    );
  }
}

export default PagePrdNbankList;

window.onload=function () {
  if(window.location.pathname == '/prj/nbank-call/prd-list') {
    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width,initial-scale=1')
  }
};
