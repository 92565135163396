/**
 * Created by jimmy on 2019/4/4.
 */
import 'whatwg-fetch';
import * as Utils from './utils';
import * as CONST from './const';

const getHeaders = () => {
  const userInfo = Utils.userInfo.get();
  const pname = Utils.projectName.get();
  Utils.projectName.set(pname);
  // userInfo.token = 'ba894648-572f-4b12-9a43-1049c4f029ac';
  return {
    "Content-Type":"application/json; charset=utf-8",
    'XT-Auth': 'yscgt4KUR1IzMfkOCWS3zSsCgxZNk',
    'XT-REQUESTED-SO-TOKEN': userInfo.token || '',
    'XT-REQUESTED-SO-PRJ': pname,
	  'XT-REQUESTED-SO-CITY': Utils.localCache({ key: 'cityId' }) || ''
  }
};

export const post = async (settings = {}) => {
  // for debug
  // if(settings.url == '/project/coupon-set/cfg') {
  //   return {
  //     "ok":0,
  //     "obj":{
  //       "supportCouponMember":1,
  //       "topImage":"1588953947-d2q18dh0jv.png",
  //       "memberImage":"1588856312-ch6qxm1lknq.png",
  //       "bgColor":"EAEDF9",
  //       "barBgColor":"FFFFFF",
  //       "barSelectedBgColor":"5870EF",
  //       "barTextColor":"7C7C7C",
  //       "barSelectedTextColor":"ffffff",
  //       "tabs":[
  //         {"tabId":1,"tabName":"单店套餐"},
  //         {"tabId":2,"tabName":"餐饮美食"}
  //       ]
  //     }
  //   }
  // }
  let url = CONST.apiPrefix + settings.url;
  let timer = null;
  if(!settings.hideLoading){
	  timer = setTimeout(Utils.loading.show, 500);
  }
  let response = await window.fetch(url, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(settings.data || {})
  });
  response = await response.json();
	if(!settings.hideLoading){
		clearTimeout(timer);
		Utils.loading.hide();
	}

  if(response.ok == 4010 || response.ok == 4020) {
  	if(window.location.pathname.startsWith('/prj')){
		  Utils.dialog.toast(response.msg);
		  Utils.userInfo.clear();
		  setTimeout(() => {
			  const accessCode = Utils.urlQuery.get('accessCode');
			  let _path = '/login?t=1';
			  if(accessCode){
				  _path += `&accessCode=${accessCode}`;
			  }
        // 兼容登录信息不太干净
        const dataContent = Utils.urlQuery.get('dataContent');
        const signContent = Utils.urlQuery.get('signContent');
        if(dataContent && signContent) {
          _path += `&dataContent=${dataContent}&signContent=${signContent}`
        }
			  Utils.redirectPage(this, _path, 4020);
		  }, 1000);
	  }else{
		  // Utils.dialog.toast(response.msg);
		  setTimeout(() => {
			  Utils.userInfo.clear();
			  window.location.href = Utils.urlQuery.set({ accessCode: '' }).href;
		  }, 1000);
	  }
  }else if(response.ok > 4000) {
    Utils.dialog.toast(response.msg);
  }else if(response.ok == -1){
  	if(settings.silent) return;
	  Utils.dialog.toast(response.msg);
  }

  return response;
};
