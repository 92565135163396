/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './user_orders.less';
import * as Request from '../common/request';
import * as CONST from '../common/const';
import * as Utils from '../common/utils';
import * as Payment from '../common/payment';

import Page from '../compoment/prj/page';
import ListLoading from '../compoment/list_loading';

const tabs = [
  { label: '全部', type: 'all', status: 0 },
	{ label: '待付款', type: 'topay', status: 1 },
  { label: '处理中', type: 'dealing', status: 2 },
  { label: '待使用', type: 'touse', status: 3 },
  { label: '退款/关闭', type: 'refund/close', status: -1 },
];

class PageOrders extends Component {
  constructor(props) {
    super(props);
    
    this.timer = null;
    
    this.state = {
      status: 0,  // 状态，0全部，1待付款，2处理中，3待使用，-1已退款/关闭
      list: [],
	    pageSize: Utils.urlQuery.get('pageSize') || 10,
	    pageNo: Utils.urlQuery.get('pageNo') || 1,
	    totalCnt: 0,
	    loginType: 0
    }
  }
  
  componentDidMount() {
    this.getList();
    this.getLoginType();
  }
  
  getList = async (loadMore) => {
    const { status, pageSize, pageNo } = this.state;
    const result = await Request.post({
      url: '/book-order/list',
      data: {
        status,
	      pageSize,
	      pageNo
      }
    });
    
    if(result.ok == 0) {
	    const state = {
		    totalCnt: result.obj.totalCnt
	    };
	    if (loadMore) {
		    state.list = [...this.state.list, ...result.obj.list];
	    } else {
		    state.list = result.obj.list;
	    }
	    this.setState(state, () => {
		    this.loadingMore();
	    });
    }
  };
  
  cancelOrder = async (bookOrderId) => {
    const result = await Request.post({
      url: '/book-order/cancel',
      data: {
        bookOrderId
      }
    });
  
    if(result.ok == 0) {
      Utils.dialog.toast('取消成功');
      this.getList();
    }
  };
	
	getLoginType = async () => {
		const result = await Request.post({
			url: '/project/login-type'
		});
		
		let loginType = 2;
		if(result.ok == 0) {
			loginType = result.obj.websiteLoginType || 2;
		}
		this.setState({ loginType });
		return loginType;
	};
  
  renderOrderBtns = (order) => {
    const orderBtns = [];
    // 0待付款，-1已关闭，-2退款中，-3已退款，10已支付，11已支付处理中，12已支付待使用，13已支付已使用
    switch(order.status){
      case CONST.orderStatusType.TOPAY:
        orderBtns.push(
          <div className="cancel" key="cancel" onClick={(e) => {
            e.preventDefault();
            Utils.dialog.confirm('确认取消订单吗？', () => {
              this.cancelOrder(order.bookOrderId);
            });
          }}>取消订单</div>
        );
        orderBtns.push(
          <div className="theme-primary-btncolor primary" key="gopay" onClick={async (e) => {
            e.preventDefault();
            let loginType = this.state.loginType;
            if(loginType == 0) {
	            loginType = await this.getLoginType();
            }
            
            if(loginType == 1) {
	            window.location.href = `${window.location.origin}/prj/wechat/payment?oid=${order.bookOrderId}&pname=${Utils.projectName.get()}`
            }else if(loginType == 2) {
	            Utils.redirectPage(this, `/payment?oid=${order.bookOrderId}`);
            }else if(loginType == 3) {
	            const nbankResult = await Payment.doNBankPayment(order.bookOrderId, `${window.location.origin}/prj/${Utils.projectName.get()}/user/order/${order.bookOrderId}`, order.payPrice);
	            if(nbankResult && nbankResult.startsWith('<html>')) {
		            document.write(nbankResult);
	            }
            }
          }}>去支付</div>
        );
        break;
      default:
        orderBtns.push(<Link className="theme-primary-btncolor primary" key={'order' + order.status} to={Utils.getRedirectUrl('/home')}>再次预订</Link>);
        break;
    }
    return orderBtns;
  };
	
	
	loadingMore = () => {
		const { pageNo, pageSize, totalCnt } = this.state;
		window.addEventListener('scroll', function () {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			
			if(totalCnt <= pageNo * pageSize) return;
			
			this.timer = setTimeout(() => {
				const wrapper =document.getElementById('loadingWrapper');
				if(!!wrapper) {
					const top = wrapper.getBoundingClientRect().top;
					const windowHeight = window.screen.height;
					if (top && top < windowHeight) {
						this.setState({
							pageNo: pageNo + 1
						}, () => {
							this.getList(true);
						});
					}
				}
			}, 50)
		}.bind(this), false);
	};
		
  render() {
    const { list, status, totalCnt, pageSize, pageNo } = this.state;
    return (
      <Page title="我的订单" {...this.props}>
        <section className="orders-wrap">
          <div className="order-tabs">
            {
              tabs.map((tab, idx) => (
                <div className="tab-item" key={idx} onClick={() => {
                  this.setState({
                    status: tab.status,
	                  pageNo: 1
                  }, this.getList);
                }}>
                  <span key={idx} className={status == tab.status ? 'theme-primary-color current' : ''}>{tab.label}<em className="theme-primary-bgcolor" /></span>
                </div>
              ))
            }
          </div>
          <div className="order-list">
            {
              Utils.isValidArray(list) && list.map((order, idx) => (
                <Link className="order-item" key={idx} to={Utils.getRedirectUrl(`/user/order/${order.bookOrderId}`)}>
                  <div className="orderid">
                    <span className="id">订单编号：{order.bookOrderId}</span>
                    <span className="status theme-secondary-color">{order.statusText}</span>
                  </div>
                  <div className="order-info">
                    <div className="title">{order.hotelName}</div>
                    <div className="info">
                      <span>{order.productName}</span>
                      <span>{order.cnt}晚</span>
                      <span>{order.bookBreakfastType}</span>
                    </div>
                    <div className="date">
                      <span>入住：{moment(order.useStartTime * 1000).format('YYYY-M-D')}</span>
                      <span>离店：{moment(order.useEndTime * 1000).format('YYYY-M-D')}</span>
                    </div>
                    <div className="price">¥ {order.payPrice}</div>
                  </div>
                  <div className="order-ctrls">
                    {this.renderOrderBtns(order)}
                  </div>
                </Link>
              )) || <div className="no-order"><span>暂无订单</span></div>
            }
            
            <ListLoading
	            length={list.length}
	            total={totalCnt}
	            pageSize={pageSize}
	            pageNo={pageNo}
            />
          </div>
        </section>
      </Page>
    );
  }
}

export default PageOrders;