/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import './prd_list.less';
import Page from '../compoment/prj/page';
import * as Utils from '../common/utils';

import IMG_CXYNEW_1 from '../common/images/prdlist/joy/1_01.jpg';
import IMG_CXYNEW_2 from '../common/images/prdlist/joy/1_02.png';
import IMG_CXYNEW_3 from '../common/images/prdlist/joy/1_03.png';
import IMG_CXYNEW_4 from '../common/images/prdlist/joy/1_04.png';
import IMG_CXYNEW_5 from '../common/images/prdlist/joy/1_05.png';
import IMG_CXYNEW_6 from '../common/images/prdlist/joy/1_06.png';
import IMG_CXYNEW_7 from '../common/images/prdlist/joy/1_07.png';
import IMG_CXYNEW_8 from '../common/images/prdlist/joy/1_08.jpg';
import IMG_CXYNEW_9 from '../common/images/prdlist/joy/1_09.jpg';
import IMG_CXYNEW_10 from '../common/images/prdlist/joy/1_10.jpg';
import IMG_CXYNEW_11 from '../common/images/prdlist/joy/1_11.jpg';
import IMG_CXYNEW_12 from '../common/images/prdlist/joy/1_12.jpg';

const IMG_MAP = {
  IMG_CXYNEW_1, IMG_CXYNEW_2, IMG_CXYNEW_3, IMG_CXYNEW_4, IMG_CXYNEW_5, IMG_CXYNEW_6, IMG_CXYNEW_7, IMG_CXYNEW_8, IMG_CXYNEW_9,
  IMG_CXYNEW_10, IMG_CXYNEW_11, IMG_CXYNEW_12
};

class PageJoyList extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {
	  Utils.setTitle('产品列表');
  }


  render() {
    return (
      <section className="prdlist-wrap">
        {
          Object.keys(IMG_MAP).map((img, idx) => (
            <img src={IMG_MAP[img]} key={idx} />
          ))
        }
      </section>
    );
  }
}

export default PageJoyList;

window.onload=function () {
  if(window.location.pathname == '/prj/citic-call/joy-list') {
    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width,initial-scale=1')
  }
};
