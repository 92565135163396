/**
 * Created by jimmy on 2019/3/2.
 */

import INTEREST_01 from '../common/images/interestIcon/1.png';
import INTEREST_02 from '../common/images/interestIcon/2.png';
import INTEREST_03 from '../common/images/interestIcon/3.png';
import INTEREST_04 from '../common/images/interestIcon/4.png';
import INTEREST_05 from '../common/images/interestIcon/5.png';
import INTEREST_06 from '../common/images/interestIcon/6.png';

import INTEREST_EMPTY_01 from '../common/images/interestIcon/empty1.png';
import INTEREST_EMPTY_02 from '../common/images/interestIcon/empty2.png';
import INTEREST_EMPTY_03 from '../common/images/interestIcon/empty3.png';
import INTEREST_EMPTY_04 from '../common/images/interestIcon/empty4.png';
import INTEREST_EMPTY_05 from '../common/images/interestIcon/empty5.png';
import INTEREST_EMPTY_06 from '../common/images/interestIcon/empty6.png';

import INTEREST_EMPTY_01_SELECTED from '../common/images/interestIcon/empty1_selected.png';
import INTEREST_EMPTY_02_SELECTED from '../common/images/interestIcon/empty2_selected.png';
import INTEREST_EMPTY_03_SELECTED from '../common/images/interestIcon/empty3_selected.png';
import INTEREST_EMPTY_04_SELECTED from '../common/images/interestIcon/empty4_selected.png';
import INTEREST_EMPTY_05_SELECTED from '../common/images/interestIcon/empty5_selected.png';
import INTEREST_EMPTY_06_SELECTED from '../common/images/interestIcon/empty6_selected.png';

export const interestTypeIcons = {
	INTEREST_01,
	INTEREST_02,
	INTEREST_03,
	INTEREST_04,
	INTEREST_05,
	INTEREST_06,
	INTEREST_EMPTY_01,
	INTEREST_EMPTY_02,
	INTEREST_EMPTY_03,
	INTEREST_EMPTY_04,
	INTEREST_EMPTY_05,
	INTEREST_EMPTY_06,
	INTEREST_EMPTY_01_SELECTED,
	INTEREST_EMPTY_02_SELECTED,
	INTEREST_EMPTY_03_SELECTED,
	INTEREST_EMPTY_04_SELECTED,
	INTEREST_EMPTY_05_SELECTED,
	INTEREST_EMPTY_06_SELECTED,
};

export const imgPrefix = 'https://todate-beauty.oss-cn-shanghai.aliyuncs.com/';

export const apiPrefix = 'https://api.todate.com.cn/api/v1';

export const bookDemands = ['高楼层', '大床', '双床'];

export const payments = {
	wechat: {type: 'wechat', name: '微信支付', iconCls: 'wx'},
	citic: {type: 'citic', name: '中信支付', iconCls: 'citic'},
	citic3: {type: 'citic3', name: '中信支付-3期免息', iconCls: 'citic'},
	citic6: {type: 'citic6', name: '中信支付-6期免息', iconCls: 'citic'},
	citic12: {type: 'citic12', name: '中信支付-12期免息', iconCls: 'citic'},
	nbank: {type: 'nbank', name: '宁波银行', iconCls: 'nbank'}
};

export const orderTabs = [
	{label: '全部', type: 'all', status: 0},
	{label: '待付款', type: 'topay', status: 1},
	{label: '处理中', type: 'dealing', status: 2},
	{label: '待使用', type: 'touse', status: 3},
	{label: '退款/关闭', type: 'refund/close', status: -1},
];

export const interestTypeIds = {
	CUTOFF_VAL: 1,  // 立减券
	CUTOFF_PERCENT: 2,  // 折扣券
	BREAKFAST: 3, // 早餐券
	UPGRADE: 4, // 升等券
	FREE: 5,  // 免房券
	FREEONE: 6  // 住一送一
};

export const orderStatusType = {
	TOPAY: 0,   // 待付款
	CLOSED: -1,   // 已关闭
	REFUNDING: -2,    // 退款中
	REFUNDED: -3,   // 已退款
	PAYED: 10,    // 已支付
	PAYED_PROCESSING: 11,  // 已支付处理中
	PAYED_TOUSE: 12,    // 已支付待使用
	PAYED_USED: 13    // 已支付已使用
};

export const starType = {
	5: '豪华型',
	4: '高档型',
	3: '舒适型',
	2: '经济型',
	1: '经济型'
};

export const ERROR_TIPS = {
	NO_EMPTY_NAME: '入住人不能为空哦',
	NO_EMPTY_MOBILE: '手机号不能为空哦',
	VALID_MOBILE: '请输入合法的手机号码',
	NO_EMPTY_DATE: '日期不能为空哦',
	INTEREST_UPTO_FREEONE: '你变更了一送一券，请重新选择入住时间',
	SUBMIT_SUC: '提交成功',
	ORDER_SUBMIT_SUC: '订单提交成功',
	ORDER_SUBMIT_ING: '订单提交中……',
	ORDER_CONFIRM_NO_UPDATE: '订单确认后不可变更取消',
	NO_SATISFY_ALL_REQUIRES: '请选择您的入住偏好，酒店会尽量安排您的要求，但无法保证，还请谅解。',
	ONLY_2_BREAKFAST: '最多只能同时使用两份早餐券',
	ONLY_1_BREAKFAST: '酒店已经包含了单早，只需要再选择一个早餐券即可',
	NO_NEED_BREAKFAST: '酒店已经包含了双早，不需要使用早餐券',
	NO_EMPTY_COUPON: '请选择权益',
};

export const INTRO_TEXT = {
	'default': '精选全国多家高端酒店，覆盖多个城市和地区，为您提供尊享住宿优惠权益。 酒店品牌包括香格里拉、喜来登、悦榕庄等知名国际品牌',
	'citic-annual-s': '精选全国100余家高端酒店，覆盖30多个城市和地区，为您提供尊享住宿优惠权益。 酒店品牌包括香格里拉、喜来登、悦榕庄等知名国际品牌。',
	'citic-cs': '精选全国70余家高端酒店，覆盖40个城市和地区，为您提供“免费酒店住宿”、“单人免费早餐”、“百元住宿抵用券”三大尊享住宿优惠权益。 酒店品牌包括香格里拉、喜来登、希尔顿、威斯汀等知名国际品牌。'
};

export const APPID = 'CiticMerchant00000014';

// export const ZX_HOST = 'https://uc.creditcard.ecitic.com';   // 线上环境
export const ZX_HOST = 'https://uc.creditcard.ecitic.com';   // 测试环境

// export const ZX_PAYMENT_PATH = 'https://s.creditcard.ecitic.com/citiccard/payweb/index.html';  // 支付地址
export const ZX_PAYMENT_PATH = 'https://s.creditcard.ecitic.com/citiccard/payweb/index.html';  // 支付地址（测试环境）

export const SELF_HOST = 'https://m.todate.com.cn';  // 线上环境：https://m.todate.com.cn

export const whiteList = [
	'/prj/citic-call/prd-list',
	'/prj/citic-call/prd-list-new',
	'/prj/citic-call/mall-list',
	'/prj/citic-call/joy-list',
	'/prj/citic-call/prd-list-july',
	'/prj/icbc-mall/prd-list',
	'/prj/nbank-call/prd-list',
	'/citic/cb/userInfo'
];

export const defaultPictures = {
	td_dflt_s: 'https://todate-beauty.oss-cn-shanghai.aliyuncs.com/dflt/td_dflt_s.png',
	td_dflt: 'https://todate-beauty.oss-cn-shanghai.aliyuncs.com/dflt/td_dflt.png',
	td_pic_s: 'https://todate-beauty.oss-cn-shanghai.aliyuncs.com/dflt/td_pic_sm.png',
	td_pic: 'https://todate-beauty.oss-cn-shanghai.aliyuncs.com/dflt/td_pic.png',
	td_room_s: 'https://todate-beauty.oss-cn-shanghai.aliyuncs.com/dflt/td_rm_sm.png',
	td_room: 'https://todate-beauty.oss-cn-shanghai.aliyuncs.com/dflt/td_rm.png'
};

export const regRules = {
	mobile: /^1\d{10}$/
};

export const cityList = [
	{name: "北京市", zip: "110000"},
	{name: "市辖区", zip: "110100"},
	{name: "天津市", zip: "120000"},
	{name: "市辖区", zip: "120100"},
	{name: "河北省", zip: "130000"},
	{name: "石家庄市", zip: "130100"},
	{name: "唐山市", zip: "130200"},
	{name: "秦皇岛市", zip: "130300"},
	{name: "邯郸市", zip: "130400"},
	{name: "邢台市", zip: "130500"},
	{name: "保定市", zip: "130600"},
	{name: "张家口市", zip: "130700"},
	{name: "承德市", zip: "130800"},
	{name: "沧州市", zip: "130900"},
	{name: "廊坊市", zip: "131000"},
	{name: "衡水市", zip: "131100"},
	{name: "山西省", zip: "140000"},
	{name: "太原市", zip: "140100"},
	{name: "大同市", zip: "140200"},
	{name: "阳泉市", zip: "140300"},
	{name: "长治市", zip: "140400"},
	{name: "晋城市", zip: "140500"},
	{name: "朔州市", zip: "140600"},
	{name: "晋中市", zip: "140700"},
	{name: "运城市", zip: "140800"},
	{name: "忻州市", zip: "140900"},
	{name: "临汾市", zip: "141000"},
	{name: "吕梁市", zip: "141100"},
	{name: "内蒙古自治区", zip: "150000"},
	{name: "呼和浩特市", zip: "150100"},
	{name: "包头市", zip: "150200"},
	{name: "乌海市", zip: "150300"},
	{name: "赤峰市", zip: "150400"},
	{name: "通辽市", zip: "150500"},
	{name: "鄂尔多斯市", zip: "150600"},
	{name: "呼伦贝尔市", zip: "150700"},
	{name: "巴彦淖尔市", zip: "150800"},
	{name: "乌兰察布市", zip: "150900"},
	{name: "兴安盟", zip: "152200"},
	{name: "锡林郭勒盟", zip: "152500"},
	{name: "阿拉善盟", zip: "152900"},
	{name: "辽宁省", zip: "210000"},
	{name: "沈阳市", zip: "210100"},
	{name: "大连市", zip: "210200"},
	{name: "鞍山市", zip: "210300"},
	{name: "抚顺市", zip: "210400"},
	{name: "本溪市", zip: "210500"},
	{name: "丹东市", zip: "210600"},
	{name: "锦州市", zip: "210700"},
	{name: "营口市", zip: "210800"},
	{name: "阜新市", zip: "210900"},
	{name: "辽阳市", zip: "211000"},
	{name: "盘锦市", zip: "211100"},
	{name: "铁岭市", zip: "211200"},
	{name: "朝阳市", zip: "211300"},
	{name: "葫芦岛市", zip: "211400"},
	{name: "吉林省", zip: "220000"},
	{name: "长春市", zip: "220100"},
	{name: "吉林市", zip: "220200"},
	{name: "四平市", zip: "220300"},
	{name: "辽源市", zip: "220400"},
	{name: "通化市", zip: "220500"},
	{name: "白山市", zip: "220600"},
	{name: "松原市", zip: "220700"},
	{name: "白城市", zip: "220800"},
	{name: "延边朝鲜族自治州", zip: "222400"},
	{name: "黑龙江省", zip: "230000"},
	{name: "哈尔滨市", zip: "230100"},
	{name: "齐齐哈尔市", zip: "230200"},
	{name: "鸡西市", zip: "230300"},
	{name: "鹤岗市", zip: "230400"},
	{name: "双鸭山市", zip: "230500"},
	{name: "大庆市", zip: "230600"},
	{name: "伊春市", zip: "230700"},
	{name: "佳木斯市", zip: "230800"},
	{name: "七台河市", zip: "230900"},
	{name: "牡丹江市", zip: "231000"},
	{name: "黑河市", zip: "231100"},
	{name: "绥化市", zip: "231200"},
	{name: "大兴安岭地区", zip: "232700"},
	{name: "上海市", zip: "310000"},
	{name: "市辖区", zip: "310100"},
	{name: "江苏省", zip: "320000"},
	{name: "南京市", zip: "320100"},
	{name: "无锡市", zip: "320200"},
	{name: "徐州市", zip: "320300"},
	{name: "常州市", zip: "320400"},
	{name: "苏州市", zip: "320500"},
	{name: "南通市", zip: "320600"},
	{name: "连云港市", zip: "320700"},
	{name: "淮安市", zip: "320800"},
	{name: "盐城市", zip: "320900"},
	{name: "扬州市", zip: "321000"},
	{name: "镇江市", zip: "321100"},
	{name: "泰州市", zip: "321200"},
	{name: "宿迁市", zip: "321300"},
	{name: "浙江省", zip: "330000"},
	{name: "杭州市", zip: "330100"},
	{name: "宁波市", zip: "330200"},
	{name: "温州市", zip: "330300"},
	{name: "嘉兴市", zip: "330400"},
	{name: "湖州市", zip: "330500"},
	{name: "绍兴市", zip: "330600"},
	{name: "金华市", zip: "330700"},
	{name: "衢州市", zip: "330800"},
	{name: "舟山市", zip: "330900"},
	{name: "台州市", zip: "331000"},
	{name: "丽水市", zip: "331100"},
	{name: "安徽省", zip: "340000"},
	{name: "合肥市", zip: "340100"},
	{name: "芜湖市", zip: "340200"},
	{name: "蚌埠市", zip: "340300"},
	{name: "淮南市", zip: "340400"},
	{name: "马鞍山市", zip: "340500"},
	{name: "淮北市", zip: "340600"},
	{name: "铜陵市", zip: "340700"},
	{name: "安庆市", zip: "340800"},
	{name: "黄山市", zip: "341000"},
	{name: "滁州市", zip: "341100"},
	{name: "阜阳市", zip: "341200"},
	{name: "宿州市", zip: "341300"},
	{name: "六安市", zip: "341500"},
	{name: "亳州市", zip: "341600"},
	{name: "池州市", zip: "341700"},
	{name: "宣城市", zip: "341800"},
	{name: "福建省", zip: "350000"},
	{name: "福州市", zip: "350100"},
	{name: "厦门市", zip: "350200"},
	{name: "莆田市", zip: "350300"},
	{name: "三明市", zip: "350400"},
	{name: "泉州市", zip: "350500"},
	{name: "漳州市", zip: "350600"},
	{name: "南平市", zip: "350700"},
	{name: "龙岩市", zip: "350800"},
	{name: "宁德市", zip: "350900"},
	{name: "江西省", zip: "360000"},
	{name: "南昌市", zip: "360100"},
	{name: "景德镇市", zip: "360200"},
	{name: "萍乡市", zip: "360300"},
	{name: "九江市", zip: "360400"},
	{name: "新余市", zip: "360500"},
	{name: "鹰潭市", zip: "360600"},
	{name: "赣州市", zip: "360700"},
	{name: "吉安市", zip: "360800"},
	{name: "宜春市", zip: "360900"},
	{name: "抚州市", zip: "361000"},
	{name: "上饶市", zip: "361100"},
	{name: "山东省", zip: "370000"},
	{name: "济南市", zip: "370100"},
	{name: "青岛市", zip: "370200"},
	{name: "淄博市", zip: "370300"},
	{name: "枣庄市", zip: "370400"},
	{name: "东营市", zip: "370500"},
	{name: "烟台市", zip: "370600"},
	{name: "潍坊市", zip: "370700"},
	{name: "济宁市", zip: "370800"},
	{name: "泰安市", zip: "370900"},
	{name: "威海市", zip: "371000"},
	{name: "日照市", zip: "371100"},
	{name: "莱芜市", zip: "371200"},
	{name: "临沂市", zip: "371300"},
	{name: "德州市", zip: "371400"},
	{name: "聊城市", zip: "371500"},
	{name: "滨州市", zip: "371600"},
	{name: "菏泽市", zip: "371700"},
	{name: "河南省", zip: "410000"},
	{name: "郑州市", zip: "410100"},
	{name: "开封市", zip: "410200"},
	{name: "洛阳市", zip: "410300"},
	{name: "平顶山市", zip: "410400"},
	{name: "安阳市", zip: "410500"},
	{name: "鹤壁市", zip: "410600"},
	{name: "新乡市", zip: "410700"},
	{name: "焦作市", zip: "410800"},
	{name: "濮阳市", zip: "410900"},
	{name: "许昌市", zip: "411000"},
	{name: "漯河市", zip: "411100"},
	{name: "三门峡市", zip: "411200"},
	{name: "南阳市", zip: "411300"},
	{name: "商丘市", zip: "411400"},
	{name: "信阳市", zip: "411500"},
	{name: "周口市", zip: "411600"},
	{name: "驻马店市", zip: "411700"},
	{name: "省直辖县级行政区划", zip: "419000"},
	{name: "湖北省", zip: "420000"},
	{name: "武汉市", zip: "420100"},
	{name: "黄石市", zip: "420200"},
	{name: "十堰市", zip: "420300"},
	{name: "宜昌市", zip: "420500"},
	{name: "襄阳市", zip: "420600"},
	{name: "鄂州市", zip: "420700"},
	{name: "荆门市", zip: "420800"},
	{name: "孝感市", zip: "420900"},
	{name: "荆州市", zip: "421000"},
	{name: "黄冈市", zip: "421100"},
	{name: "咸宁市", zip: "421200"},
	{name: "随州市", zip: "421300"},
	{name: "恩施土家族苗族自治州", zip: "422800"},
	{name: "省直辖县级行政区划", zip: "429000"},
	{name: "湖南省", zip: "430000"},
	{name: "长沙市", zip: "430100"},
	{name: "株洲市", zip: "430200"},
	{name: "湘潭市", zip: "430300"},
	{name: "衡阳市", zip: "430400"},
	{name: "邵阳市", zip: "430500"},
	{name: "岳阳市", zip: "430600"},
	{name: "常德市", zip: "430700"},
	{name: "张家界市", zip: "430800"},
	{name: "益阳市", zip: "430900"},
	{name: "郴州市", zip: "431000"},
	{name: "永州市", zip: "431100"},
	{name: "怀化市", zip: "431200"},
	{name: "娄底市", zip: "431300"},
	{name: "湘西土家族苗族自治州", zip: "433100"},
	{name: "广东省", zip: "440000"},
	{name: "广州市", zip: "440100"},
	{name: "韶关市", zip: "440200"},
	{name: "深圳市", zip: "440300"},
	{name: "珠海市", zip: "440400"},
	{name: "汕头市", zip: "440500"},
	{name: "佛山市", zip: "440600"},
	{name: "江门市", zip: "440700"},
	{name: "湛江市", zip: "440800"},
	{name: "茂名市", zip: "440900"},
	{name: "肇庆市", zip: "441200"},
	{name: "惠州市", zip: "441300"},
	{name: "梅州市", zip: "441400"},
	{name: "汕尾市", zip: "441500"},
	{name: "河源市", zip: "441600"},
	{name: "阳江市", zip: "441700"},
	{name: "清远市", zip: "441800"},
	{name: "东莞市", zip: "441900"},
	{name: "中山市", zip: "442000"},
	{name: "潮州市", zip: "445100"},
	{name: "揭阳市", zip: "445200"},
	{name: "云浮市", zip: "445300"},
	{name: "广西壮族自治区", zip: "450000"},
	{name: "南宁市", zip: "450100"},
	{name: "柳州市", zip: "450200"},
	{name: "桂林市", zip: "450300"},
	{name: "梧州市", zip: "450400"},
	{name: "北海市", zip: "450500"},
	{name: "防城港市", zip: "450600"},
	{name: "钦州市", zip: "450700"},
	{name: "贵港市", zip: "450800"},
	{name: "玉林市", zip: "450900"},
	{name: "百色市", zip: "451000"},
	{name: "贺州市", zip: "451100"},
	{name: "河池市", zip: "451200"},
	{name: "来宾市", zip: "451300"},
	{name: "崇左市", zip: "451400"},
	{name: "海南省", zip: "460000"},
	{name: "海口市", zip: "460100"},
	{name: "三亚市", zip: "460200"},
	{name: "三沙市", zip: "460300"},
	{name: "省直辖县级行政区划", zip: "469000"},
	{name: "重庆市", zip: "500000"},
	{name: "市辖区", zip: "500100"},
	{name: "四川省", zip: "510000"},
	{name: "成都市", zip: "510100"},
	{name: "自贡市", zip: "510300"},
	{name: "攀枝花市", zip: "510400"},
	{name: "泸州市", zip: "510500"},
	{name: "德阳市", zip: "510600"},
	{name: "绵阳市", zip: "510700"},
	{name: "广元市", zip: "510800"},
	{name: "遂宁市", zip: "510900"},
	{name: "内江市", zip: "511000"},
	{name: "乐山市", zip: "511100"},
	{name: "南充市", zip: "511300"},
	{name: "眉山市", zip: "511400"},
	{name: "宜宾市", zip: "511500"},
	{name: "广安市", zip: "511600"},
	{name: "达州市", zip: "511700"},
	{name: "雅安市", zip: "511800"},
	{name: "巴中市", zip: "511900"},
	{name: "资阳市", zip: "512000"},
	{name: "阿坝藏族羌族自治州", zip: "513200"},
	{name: "甘孜藏族自治州", zip: "513300"},
	{name: "凉山彝族自治州", zip: "513400"},
	{name: "贵州省", zip: "520000"},
	{name: "贵阳市", zip: "520100"},
	{name: "六盘水市", zip: "520200"},
	{name: "遵义市", zip: "520300"},
	{name: "安顺市", zip: "520400"},
	{name: "毕节市", zip: "520500"},
	{name: "铜仁市", zip: "520600"},
	{name: "黔西南布依族苗族自治州", zip: "522300"},
	{name: "黔东南苗族侗族自治州", zip: "522600"},
	{name: "黔南布依族苗族自治州", zip: "522700"},
	{name: "云南省", zip: "530000"},
	{name: "昆明市", zip: "530100"},
	{name: "曲靖市", zip: "530300"},
	{name: "玉溪市", zip: "530400"},
	{name: "保山市", zip: "530500"},
	{name: "昭通市", zip: "530600"},
	{name: "丽江市", zip: "530700"},
	{name: "普洱市", zip: "530800"},
	{name: "临沧市", zip: "530900"},
	{name: "楚雄彝族自治州", zip: "532300"},
	{name: "红河哈尼族彝族自治州", zip: "532500"},
	{name: "文山壮族苗族自治州", zip: "532600"},
	{name: "西双版纳傣族自治州", zip: "532800"},
	{name: "大理白族自治州", zip: "532900"},
	{name: "德宏傣族景颇族自治州", zip: "533100"},
	{name: "怒江傈僳族自治州", zip: "533300"},
	{name: "迪庆藏族自治州", zip: "533400"},
	{name: "西藏自治区", zip: "540000"},
	{name: "拉萨市", zip: "540100"},
	{name: "昌都地区", zip: "542100"},
	{name: "山南地区", zip: "542200"},
	{name: "日喀则地区", zip: "542300"},
	{name: "那曲地区", zip: "542400"},
	{name: "阿里地区", zip: "542500"},
	{name: "林芝地区", zip: "542600"},
	{name: "陕西省", zip: "610000"},
	{name: "西安市", zip: "610100"},
	{name: "铜川市", zip: "610200"},
	{name: "宝鸡市", zip: "610300"},
	{name: "咸阳市", zip: "610400"},
	{name: "渭南市", zip: "610500"},
	{name: "延安市", zip: "610600"},
	{name: "汉中市", zip: "610700"},
	{name: "榆林市", zip: "610800"},
	{name: "安康市", zip: "610900"},
	{name: "商洛市", zip: "611000"},
	{name: "甘肃省", zip: "620000"},
	{name: "兰州市", zip: "620100"},
	{name: "嘉峪关市", zip: "620200"},
	{name: "白银市", zip: "620400"},
	{name: "天水市", zip: "620500"},
	{name: "武威市", zip: "620600"},
	{name: "张掖市", zip: "620700"},
	{name: "平凉市", zip: "620800"},
	{name: "酒泉市", zip: "620900"},
	{name: "庆阳市", zip: "621000"},
	{name: "定西市", zip: "621100"},
	{name: "陇南市", zip: "621200"},
	{name: "临夏回族自治州", zip: "622900"},
	{name: "甘南藏族自治州", zip: "623000"},
	{name: "青海省", zip: "630000"},
	{name: "西宁市", zip: "630100"},
	{name: "海东地区", zip: "632100"},
	{name: "海北藏族自治州", zip: "632200"},
	{name: "黄南藏族自治州", zip: "632300"},
	{name: "海南藏族自治州", zip: "632500"},
	{name: "果洛藏族自治州", zip: "632600"},
	{name: "玉树藏族自治州", zip: "632700"},
	{name: "海西蒙古族藏族自治州", zip: "632800"},
	{name: "宁夏回族自治区", zip: "640000"},
	{name: "银川市", zip: "640100"},
	{name: "石嘴山市", zip: "640200"},
	{name: "吴忠市", zip: "640300"},
	{name: "固原市", zip: "640400"},
	{name: "中卫市", zip: "640500"},
	{name: "新疆维吾尔自治区", zip: "650000"},
	{name: "乌鲁木齐市", zip: "650100"},
	{name: "克拉玛依市", zip: "650200"},
	{name: "吐鲁番地区", zip: "652100"},
	{name: "哈密地区", zip: "652200"},
	{name: "昌吉回族自治州", zip: "652300"},
	{name: "博尔塔拉蒙古自治州", zip: "652700"},
	{name: "巴音郭楞蒙古自治州", zip: "652800"},
	{name: "阿克苏地区", zip: "652900"},
	{name: "克孜勒苏柯尔克孜自治州", zip: "653000"},
	{name: "喀什地区", zip: "653100"},
	{name: "和田地区", zip: "653200"},
	{name: "伊犁哈萨克自治州", zip: "654000"},
	{name: "塔城地区", zip: "654200"},
	{name: "阿勒泰地区", zip: "654300"},
	{name: "自治区直辖县级行政区划", zip: "659000"},
	{name: "台湾省", zip: "710000"},
	{name: "香港特别行政区", zip: "810000"},
	{name: "澳门特别行政区", zip: "820000"},
];
export const defaultCity = {
	province: '上海市',
	city: '上海市',
	cityName: '上海市',
	citycode: '021',
	adcode: '310000',
	oversea: 0,
	lnglat: [121.473658, 31.230378],

	// province: '广东省',
	// city: '广州市',
	// cityName: '广州市',
	// citycode: '020',
	// adcode: '310000',
	// oversea: 0,
	// lnglat: [113.264385,23.129112],
};

// export const appId = 'wx6ef0bb6a5b9e3955';
export const appId = 'wxf3a7fbf5ec6a6855';

export const platformRules = {
	uauth: {
		pageTitle: '用户授权协议',
		title: '《用户授权协议》',
		desc: '《用户授权协议》（以下简称“本协议”）是上海途约信息科技有限公司与用户（以下简称“您”）所订立的有效合约。',
		content: [
			'【审慎阅读】您即将通过本页面使用我司平台账户授权登录第三方站点/软件或进行一定数据共享。确认授权前请您仔细阅读本协议的全部内容（特别是以粗体下划线标注的内容）。如您不同意本协议内容，或无法准确理解本协议任何条款的含义，请不要进行授权及后续操作。',
			'【签约动作】您通过网络页面确认同意接受本协议，视为您同意接受本协议的全部约定内容。',
			'【共享信息】您授权我司平台将我司平台账户的相关信息和数据传递给第三方，用于第三方为您提供服务。页面提示上会展示具体授权对象、授权字段及内容等，授权字段及内容将通过加密通道传递给第三方。我司平台会要求该第三方依法使用您的上述信息。如您不希望向第三方提供上述信息，请勿点击确认授权。',
			'【责任承担】您理解，第三方服务由相应第三方提供并就以上服务及数据安全独立承担责任。因该第三方服务或其使用您的信息产生的纠纷，或第三方服务违反相关法律法规，或您在使用第三方服务过程中遭受的损失，请您与第三方协商解决。',
			'【安全提示】网络欺诈往往会模仿、仿冒本登录页面的样式制作视觉感相似的页面引诱用户输入账号和密码等信息，我司平台在此提醒您防范网络风险，不要向非我司平台的站点或任何人士透露您的平台账户的名称、密码等相关信息。',
			'【协议变更】您同意，我司平台可不时修订本协议，并将协议变更情况在我司平台的网站/客户端上进行公示，该等变更自公告载明的生效时间开始生效，并成为本协议的一部分。',
			'【争议解决】凡因本协议引起的及任何与之有关的争议，首先应由该等争议的相关当事方通过友好协商解决，如果无法友好协商解决的，任何一方均可向被告所在地有管辖权的人民法院提起诉讼。',
			'【其他】本协议未尽事宜，参照《酒店平台公告》执行；《酒店平台公告》与本协议有冲突的，以本协议为准。'
		]
	},
	prule: {
		pageTitle: '隐私政策',
		title: '《隐私政策》',
		desc: '上海途约信息技术有限公司及其关联方（简称“我们”）作为中信银行信用卡中心酒店预订平台的运营者，深知个人信息对您的重要性，我们将按照法律法规的规定，保护您的个人信息及隐私安全。我们制定本“隐私政策”并特别提示：希望您在使用中信银行信用卡中心酒店预订平台及相关服务前仔细阅读并理解本隐私政策，以便做出适当的选择。',
		content: [
			`<strong>本隐私政策将帮助您了解：</strong>`,
			'1、我们会遵循隐私政策收集、使用您的信息，但不会仅因您同意本隐私政策而采用强制捆绑的方式一揽子收集个人信息。',
			'2、为了用于帮助您做酒店预定和相关的服务，我们将会申请通讯录权限。',
			'3、我们将会申请位置权限，用于帮助您在发布的信息中展示酒店或丰富位置信息推荐酒店。城市位置无需使用权限，仅通过ip地址确定“同城”中所展示的酒店及相关信息，不会收集精确位置信息。',
			'4、当您使用或开启相关功能或使用服务时，为实现功能、服务所必需，我们会收集、使用相关信息。除非是为实现基本业务功能或根据法律法规要求所必需的必要信息，您均可以拒绝提供且不影响其他功能或服务。',
			'5、如果您未登录账号，我们会通过设备对应的标识符信息来保障信息推送的基本功能。如果您登录了账号，我们会根据账号信息实现信息推送。',
			'6、通讯录、精确地理位置、摄像头、麦克风、相册权限，均不会默认开启，只有经过您的明示授权才会在为实现特定功能或服务时使用，您也可以撤回授权。特别需要指出的是，即使经过您的授权，我们获得了这些敏感权限，也不会在相关功能或服务不需要时而收集您的信息。',
			'7、本隐私政策适用于您通过我司预订平台、供第三方网站和平台使用的平台软件开发工具包（SDK）和应用程序编程接口（API）方式来访问和使用我们的产品和服务。',
			'8、以上隐私信息的获取和使用方为上海途约信息技术有限公司，上海途约信息技术有限公司需承担用户隐私信息保密的责任和义务，中信银行信用卡中心将尽商业上合理的努力评估合作方收集信息的合法性、正当性、必要性，并会与合作方签订相关法律文件并要求合作方处理您的个人信息时遵守法律法规，尽力要求合作方对您的信息采取保护措施。',
		]
	},
	srule: {
		title: '酒店平台公告',
		list: [
			{
				title: '一、关于预订',
				desc: [
					'行友鱼酒店平台支持全国酒店房源预订。',
					'平台目前只支持中信信用卡支付。',
				]
			},
			{
				title: '二、关于酒店发票',
				desc: [
					'平台支持酒店开票和代理商开票（具体以平台提示为准），酒店开票需您在酒店前台自行领取酒店发票，代理商开票需您致电客服，与客服沟通开票信息及邮寄信息。',
					'发票一经打印，无法再次重复打印，请妥善保管好您的发票，若不慎遗失，本平台将无法再次提供。',
				]
			},
			{
				title: '三、关于退房',
				desc: [
					'本平台不支持申请退房，请您谨慎下单，由您个人原因导致无法入住所造成的损失，由您自行承担。如有其他特殊情况，请致电客服400-1767-365。'
				]
			},
			{
				title: '四、关于行友鱼酒店平台',
				desc: [
					'本平台机票服务由上海途约国际旅行社有限公司（以下简称“途约”）提供，具体酒店业务与中信银行股份公司信用卡中心无关，中信信用卡中心仅提供信用卡项下支付结算业务，如涉及酒店业务问题，请咨询平台热线400-1767-365获取协助。',
					'行友鱼酒店平台显示的酒店及其他旅行产品和服务的信息和描述均由途约提供，途约保证平台显示酒店及服务相关信息的准确性。如用户对产品或服务的标题、图片、价格、详情等任何信息有任何疑问，请在购买或使用产品和服务前与途约进行确认。用户在预订和使用过程中发生消费纠纷或合法权益受到损害时，由途约负责调解，如因途约原因导致客户损失，途约将积极为客户妥善解决并给予适当赔偿。',
					'明显的错误均不具有约束力，所有特别优惠及促销均予以标明，如果没有如此标记，出现明显错误时，您不能获得任何优惠。',
					'您完全理解并同意，通过行友鱼酒店平台购买酒店等现有旅游产品或服务或此后新的产品和服务时，您在预定产品或服务后应当及时付款；您在预定产品或服务后未全额支付前，您尚未完成购买上述产品或服务。因上述产品或服务的价格、库存等实时更新或变化而造成您的额外费用、损失或无库存等，由您自行承担，途约不承担任何责任。您完全理解并同意，您在支付过程中，因网络中断、银行反馈信息错误等非途约的原因造成的付款失败，由您自行承担。',
					'本平台官方订票链接：https://m.todate.com.cn/cprj/citic-cmall',
					'本平台官方订票订单路径：中信银行信用卡APP“动卡空间”—“机酒签证”—“酒店”。',
					'本平台订单查询路径：中信银行信用卡APP“动卡空间”—“机酒签证”—“订单”—“酒店订单”。',
					'本平台官方热线电话：400-1767-365',
					'本平台官方通知短信号：106-912-0000-7350',
					'若有任何服务条款与法律相抵触，这些条款将按尽可能接近的方法重新解析，而其它条款则保持对客户产生法律效力和影响。本条款的履行和解释以及争端的解决，在各方面均受中国大陆法律管辖。'
				]
			}
		]
	}
};

export const sourcePage = 'https://tmart.creditcard.ecitic.com/citiccard/newtrip-ocp/#/?fromDKKJ=1';
export const orderListPage = 'https://tmart.creditcard.ecitic.com/citiccard/newtrip-ocp/#/myorder';

export const loginParamsList = [
	'dataContent', 'signContent'
];
