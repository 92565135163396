/**
 * Created by jimmy on 2019/3/2.
 */
import moment from 'moment';
import * as CONST from './const';
import * as Request from './request';
import * as urlParse from 'url-parse';

window.moment = moment;

/**
 * 滚动到 xx
 * @param {*} target 
 */
export const scrollTo = (target) => {
  let _timer = null;
  const _scrollTo = () => {
    let pos = window.pageYOffset;
    if ( pos < target ) {
      window.scrollTo( 0, pos + 80 );
      setTimeout(_scrollTo, 2);
    }else{
      clearTimeout(_timer);
      _timer = null;
    }
  };
  
  _scrollTo();
};

/**
 * 自定义提示框
 * @param msg
 * @param settings
  * @param type 类型 1：toast 提示；2：confirm
  * @param message 提示信息
 */
export const dialog = {
  toast: message => {
    const div = document.createElement('div');
    div.className = 'dialog-toast';
    div.innerText = message;
    document.body.appendChild(div);
    
    setTimeout(() => {
      div.style.opacity = 0;
    }, 1000);
  
    setTimeout(() => {
      document.body.removeChild(div);
    }, 2000);
  },
  confirm: (message, okHandle, cancelHandle) => {
	  if(!message) return;
	
	  const divMask = document.createElement('div');
	  divMask.className = 'dialog-mask';
	  
    const div = document.createElement('div');
    div.className = 'dialog-confirm';
    div.innerHTML = `
      <div class="message">${message}</div>
      <div class="ctrls">
        <div class="ctrl-item cancel" id="confirmCancel">取消</div>
        <div class="ctrl-item ok" id="confirmOK">确认</div>
      </div>
    `;
	
	  document.body.appendChild(divMask);
	  document.body.appendChild(div);
    
    document.getElementById('confirmCancel').onclick =  function(){
      document.body.removeChild(div);
	    document.body.removeChild(divMask);
      if(cancelHandle && typeof cancelHandle == 'function') {
        cancelHandle && cancelHandle();
      }
    };
  
    document.getElementById('confirmOK').onclick =  function(){
      document.body.removeChild(div);
	    document.body.removeChild(divMask);
      if(okHandle && typeof okHandle == 'function') {
        okHandle && okHandle();
      }
    };
  },
	alert: (title = '提示', message, okHandle) => {
  	if(!message) return;
  	
  	const divMask = document.createElement('div');
		divMask.className = 'dialog-mask';
		
		const div = document.createElement('div');
		div.className = 'dialog-alert';
		div.innerHTML = `
      <div class="title">${title}</div>
      <div class="message">${message}</div>
      <div class="ctrls">
        <div class="ctrl-item ok" id="alertOK">知道了</div>
      </div>
    `;
		
		document.body.appendChild(divMask);
		document.body.appendChild(div);
		
		document.getElementById('alertOK').onclick =  function(){
			document.body.removeChild(div);
			document.body.removeChild(divMask);
			if(okHandle && typeof okHandle == 'function') {
				okHandle && okHandle();
			}
		};
	}
};

// 重定向
export const redirectPage = (ctx, path, code, root = 'prj', query) => {
  const pname = projectName.get();
  let _href = `/${root}/${pname}${path}`;
  if(query) _href += `?${query}`;
  if(code == 4020) {
    window.location.href = _href;
  }else{
    ctx.props.history.push(_href);
  }
};

// 获取跳转 URL
export const getRedirectUrl = (path, root = 'prj') => {
  const pname = projectName.get();
  return `/${root}/${pname}${path}`;
};

// 获取图片 URL
export const getImageUrl = (path) => {
  if(!path) return;
  return path.startsWith('http://') || path.startsWith('https://') ? path : CONST.imgPrefix + path;
};

// 项目 ID
export const projectId = {
	get: () => {
		return window.globalData.pid || window.location.pathname.split('/')[1]
	},
	set: (pid) => {
		window.globalData.pid = pid;
	}
};

// 项目名称
export const projectName = {
	get: () => {
		let pathPrjName = window.location.pathname.split('/')[2];
		if(pathPrjName == 'wechat') pathPrjName = undefined;
		return window.globalData.pname || pathPrjName || urlQuery.get('pname')
	},
	set: (pname) => {
		window.globalData.pname = pname;
	}
};

// 用户信息
export const userInfo = {
	conf: () => {
		const root = window.location.pathname.split('/')[1];
		if(!root) return {};
		
		const typeMap = { prj: 1, cprj: 2 };
		const prjName = projectName.get();
		if(prjName) {
			return {
				key: `${root}-${prjName}-userInfo`,
				type: typeMap[root]
			}
		}else{
			return {
				key: `${root}-userInfo`,
				type: typeMap[root]
			}
		}
	},
  get: () => {
    return JSON.parse(localCache(userInfo.conf()) || JSON.stringify({}));
  },
  set: (userinfo = {}) => {
    // window.sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
    localCache({ ...userInfo.conf(), value: JSON.stringify(userinfo) });
  },
  clear: () => {
    // window.sessionStorage.clear();
    localCache({ ...userInfo.conf(), value: JSON.stringify({}) });
  },
	heart: async () => {
		const result = await Request.post({
			url: '/user/heart'
		});
		if(result.ok != 0) return;

		const _userInfo = userInfo.get();
		userInfo.set({
			..._userInfo,
			...result.obj
		});
	},
	isLogin: () => {
		const _userInfo = userInfo.get();
		return _userInfo && _userInfo.id;
	}
};

// loading
export const loading = {
  show: () => {
    document.getElementById('loading').style.display = 'block';
  },
  hide: () => {
    document.getElementById('loading').style.display = 'none';
  }
};

// 有效 array 判断
export const isValidArray = arr => {
  return arr && Array.isArray(arr) && arr.length > 0;
};

export const logger = {
  error: (type, errMsg) => console.error(`[${type}] ${errMsg}`)
};

// 永久缓存 type = 1
// 当前会话缓存 type = 2 [default]
export const localCache = ({key = '', value = '', type = 2}) => {
  if(!key) {
    logger.error('cache', 'key is required');
    return;
  }
  
  const storageAction = type == 1 ? window.localStorage : window.sessionStorage;
  if(value) {
    return storageAction.setItem(key, value);
  }else{
    return storageAction.getItem(key);
  }
};

export const datesDiff = (start, end) => {
  if(start === '' || end === '') {
    return '0'
  }
  
  // moment
  return Math.abs(moment(end).diff(moment(start), 'days'));
};

export const blurAdjust = () => {
  setTimeout(()=>{
    if(document.activeElement.tagName == 'INPUT' || document.activeElement.tagName == 'TEXTAREA'){
      return
    }
    let result = 'pc';
    if(/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
      result = 'ios'
    }else if(/(Android)/i.test(navigator.userAgent)) {  //判断Android
      result = 'android'
    }
    
    if( result = 'ios' ){
      document.activeElement.scrollIntoViewIfNeeded(true);
    }
  },100)
};

/**
 * 统一描述日期展示
 * @param {*} date 
 */
export const dateDisplayer = date => {
  if(!date) {
    return '请选择日期';
    return '?月?日';
  }
  
  return moment(date).format('M月D日');
};

// 获取链接 query 信息
// export const urlquery = (key) => {
//   const { query = {} } = urlParse(window.location.href, true);
//   return query && query[key] || '';
// }

export const addZero = num => num < 10 ? '0' + num : num;

/**
 * 时间距离
 * @param {*} start 秒
 * @param {*} end 秒
 * @param {*} format 格式化 
 */
export const timeDiff = (start, end, format = 'hh:mm:ss') => {
  const restSec = end - parseInt(start, 10);
  if(restSec <= 0) {
    return format.replace('mm', '00').replace('ss', '00');
  }

  let min = addZero(Math.floor(restSec / 60 % 60));
  let sec = addZero(restSec - min * 60);
  return format.replace('mm', min).replace('ss', sec);
};

/**
 * 设置页面标题
 * @param title
 */
export const setTitle = (title) => {
	if(!!title) {
		document.title = title;
	}
};

export const getCityIdByName = async (cityName) => {
	if(!cityName) return 3;
	const result = await Request.post({
		url: '/common/getCityId',
		data: {
			cityName
		}
	});
	
	if(result.ok == 0) {
		return result.obj.cityId;
	}
	
	return 3;
};

/**
 * 获取未来 ${length} 天的日期，length 默认 100
 * @param length
 * @returns {Array}
 */
export const datesInitial = (length = 100) => {
	const currentDate = moment().format('YYYY-MM-DD');
	const lastDate = moment(currentDate).add(length, 'd').format('YYYY-MM-DD');
	
	const dates = [];
	for(let i = currentDate; i < lastDate; i = moment(i).add(1, 'd').format('YYYY-MM-DD')){
		dates.push({
			date: i
		});
	}
	return dates;
};

/**
 * 日历初始化
 * @param dates
 * @returns {Array}
 */
export const calendarInit = (dates) => {
	if(!dates || dates.length <= 0) return [];
	const map = {};
	
	const months = [];
	dates.map(item => {
		const month = moment(item.date).format('YYYY-MM');
		if(!months.includes(month)){
			map[month] = [];
			months.push(month);
		}
	});
	months.map(month => {
		const days = moment(month).daysInMonth();
		const firstDayWeek = moment(`${month}-01`).day();
		// 每个月第一天开始星期
		for(let w = 0; w < firstDayWeek; w++) {
			map[month].push('');
		}
		
		// 每月日期
		const curDate = moment().format('YYYY-MM-DD');
		for(let d = 1; d <= days; d++) {
			d = d < 10 ? '0' + d : d;
			const date = `${month}-${d}`;
			map[month].push({
				date,
				status: date < curDate ? 0 : 1
			});
		}
	});
	
	return Object.keys(map).map(item => ({
		month: item,
		dates: map[item]
	}));
};

/**
 * query 获取和设置
 * @type {{set: ((p1?:*)), get: ((p1?:*))}}
 */
export const urlQuery = {
	/**
	 *
	 * @param q：新的 query 参数 [object] 如果参数重复，会覆盖原来 url 上的值
	 * @returns {{query: {}, queryStr: string, href: string}}
	 */
	set: (q = {}, href) => {
		const { query, origin } = urlQuery.get('', href);
		for(let i in q) {
			if(q[i] != undefined && q[i] != null) {
				query[i] = q[i];
			}
		}
		
		let _queryArr = [];
		for(let i in query) {
			if(query[i] != undefined || query[i] != null || query[i] != '') {
				_queryArr.push(`${i}=${query[i]}`);
			}
		}
		
		const queryStr = _queryArr.join('&');
		return {
			query,
			queryStr,
			href: `${origin}?${queryStr}`
		};
	},
	
	/**
	 *
	 * @param key：query 的 key，如果存在，则返回对应的值，否则返回完整 query 对象
	 * @returns {*}
	 */
	get: (key, href) => {
		href = href || window.location.href;
		const hrefQuery = href.split('?');
		const query = {};
		if(hrefQuery && hrefQuery[1]) {
			const queryStr = hrefQuery[1];
			const queryArr = queryStr.split('&');
			queryArr.map(item => {
				if(item) {
					const _item = item.split('=');
					if(_item) {
						query[_item[0]] = decodeURIComponent(_item[1]);
					}
				}
			})
		}
		
		// 如果有 query 的关键字的话，返回对应的值
		if(key) {
			return query[key] || '';
		}
		
		// 返回所有 query 和 origin url
		return {
			query,
			origin: hrefQuery && hrefQuery[0] ? hrefQuery[0] : ''
		};
	}
};

window.urlQuery = urlQuery;

// 判断某城市属于国外城市
export const isOversea = (city = {}) => {
	if(city.oversea != undefined || city.oversea != null) {
		return city.oversea;
	}
	const cityList = CONST.cityList.map(item => item.name);
	return cityList.includes(city.cityName) ? 0 : 1;
};

export const ua = {
	get: () => navigator.userAgent.toLowerCase(),
	isWeChat: () => {
		const _ua = ua.get();
		return _ua.match(/MicroMessenger/i) == "micromessenger" ? true : false;
	}
};

export const weChat = {
	get: () => {
		return JSON.parse(localCache({ key: 'userwx' }) || JSON.stringify({}));
	},
	set: (info = {}) => {
		localCache({
			key: 'userwx',
			value: JSON.stringify(info)
		})
	},
	_wxRedirectUriReset: (uri = window.location.href)=>{
		let hash, pureUri, pureUrl;
		hash = uri.split('#')[1];
		pureUri = uri.split('#')[0];
		pureUrl = pureUri.split('?')[0];
		
		let querys = (function (querys) {
			if(!querys) return {};
			let query = {};
			let _querysArr = querys.split('&');
			for(let i=0, len=_querysArr.length; i<len; i++){
				let qItem = _querysArr[i].split('=');
				query[qItem[0]] = qItem[1];
			}
			return query;
		})(pureUri.split('?')[1]);
		
		let queryArr = [];
		for(let i in querys){
			if(querys[i] && i != 'code' && i != 'state'){
				queryArr.push(i + '=' + querys[i]);
			}
		}
		if(queryArr.length > 0){
			pureUrl += '?' + queryArr.join('&')
		}
		if(!!hash) pureUrl += '#' + hash;
		
		return pureUrl;
	},
	login: async (option = {}, callback) => {
		const code = urlQuery.get("code");
		if(!code) {
			const url = `https://open.weixin.qq.com/connect/oauth2/authorize?${[
				`appid=${CONST.appId}`,
				`redirect_uri=${encodeURIComponent(weChat._wxRedirectUriReset(window.location.href))}`,
				`response_type=code`,
				`scope=${option.scope || 'snsapi_base'}`,
				`state=${option.state || 'STATE_BASE'}`
			].join('&')}#wechat_redirect`;
			window.location.href = url;
			return;
		}
		
		const result = await Request.post({
			url: '/user/wx/openid',
			data: { code }
		});
		if(result.ok == 0){
			const openId = result.obj.openId;
			weChat.set({
				openId
			});
			
			setTimeout(() => {
				callback && callback();
			}, 666);
		}
	},
};

export const selfEvent = {
  _listeners: {},
  // 添加
  addEvent: function(type, fn) {
    if (typeof this._listeners[type] === "undefined") {
      this._listeners[type] = [];
    }
    if (typeof fn === "function") {
      this._listeners[type].push(fn);
    }
    return this;
  },
  // 触发
  fireEvent: function(type, data) {
    const arrayEvent = this._listeners[type];
    if (arrayEvent instanceof Array) {
      for (let i=0, length=arrayEvent.length; i<length; i+=1) {
        if (typeof arrayEvent[i] === "function") {
          arrayEvent[i]({ type: type, data });
        }
      }
    }
    return this;
  },
  // 删除
  removeEvent: function(type, fn) {
    const arrayEvent = this._listeners[type];
    if (typeof type === "string" && arrayEvent instanceof Array) {
      if (typeof fn === "function") {
        // 清除当前type类型事件下对应fn方法
        for (let i=0, length=arrayEvent.length; i<length; i+=1){
          if (arrayEvent[i] === fn){
            this._listeners[type].splice(i, 1);
            break;
          }
        }
      } else {
        // 如果仅仅参数type, 或参数fn邪魔外道，则所有type类型事件清除
        delete this._listeners[type];
      }
    }
    return this;
  }
};

export const getRedirectPathFromSession = (key, root = 'cprj') => {
	return localCache({ key: `${root}-${key}` }) || '';
};
export const setRedirectPathFromSession = (key, value, root = 'cprj') => {
	if(!key || !value) return;

	localCache({
		key: `${root}-${key}`,
		value
	});
};
export const pathNameMapInitial = (props, root = 'cprj') => {
	if(!props || !props.match) {
		return {};
	}

	const params = props.match.params;
	let _map = {};
	switch(root){
		case 'prj':
			_map = {
				[getRedirectUrl('', root)]: 'close',
				[getRedirectUrl('/intro', root)]: 'close',
				[getRedirectUrl('/login', root)]: getRedirectUrl('/intro', root),
				[getRedirectUrl('/home', root)]: getRedirectUrl('/intro', root),
			};
			break;
		case 'cprj':
			_map = {
				[getRedirectUrl('/search', root)]: getRedirectUrl('/home', root),
				[getRedirectUrl('/hotels', root)]: getRedirectUrl('/home', root),
				[getRedirectUrl('/user/orders', root)]: getRedirectUrl('/home', root),
				[getRedirectUrl(`/hotel/${params.hotelId}/detail`, root)]: 'local',
				[getRedirectUrl(`/hotel/${params.productId}/book`, root)]: 'local',
				[getRedirectUrl(`/user/order/${params.orderId}`, root)]: getRedirectUrl('/user/orders', root),
				[getRedirectUrl('/payment', root)]: getRedirectUrl('/user/orders', root),
				[getRedirectUrl('/payment/result', root)]: getRedirectUrl('/user/orders', root),
				[getRedirectUrl('/payment/rule', root)]: getRedirectUrl('/home', root),

				// 外链
				[getRedirectUrl('', root)]: CONST.sourcePage,
				[getRedirectUrl('/', root)]: CONST.sourcePage,
				[getRedirectUrl('/home', root)]: CONST.sourcePage,
				'back-from-cjd': CONST.orderListPage
			};
			break;
		case 'xprj':
			_map = {
				[getRedirectUrl('', root)]: CONST.sourcePage,
				[getRedirectUrl('/', root)]: CONST.sourcePage,
				[getRedirectUrl('/home', root)]: CONST.sourcePage,
				[getRedirectUrl(`/detail/${params.id}`, root)]: getRedirectUrl('/home', root),
				[getRedirectUrl('/user/orders', root)]: getRedirectUrl('/home', root),
				[getRedirectUrl(`/order/${params.oid}`, root)]: getRedirectUrl('/user/orders', root),
				[getRedirectUrl('/payment', root)]: 'local',
				[getRedirectUrl('/payment/result', root)]: getRedirectUrl('/user/orders', root),
				[getRedirectUrl('/member/buy', root)]: 'local'
			};
			break;
	}
	return _map;
};

export const isHome = (props, root = 'cprj') => props.location.pathname == getRedirectUrl('/home', root) || props.location.pathname == getRedirectUrl('/', root) || props.location.pathname == getRedirectUrl('', root);

export const judgeDkkjVersionwith7 = (suc, fail, complete) => {
	let result = navigator.userAgent.indexOf('DKKJ') > -1;
	if (result) {
		if(window.tower && window.tower.device && window.tower.device.getSystemInfo) {
			window.tower.device.getSystemInfo({
				success: function (res) {
					if (res.version.split('.')[0] > 6) {
						suc && suc(res);
					} else {
						fail && fail();
					}
				},
				fail: function (res) {
					fail && fail();
				},
				complete: function (res) {
					complete && complete(res);
				}
			});
		}
	} else {
		fail && fail();
	}
};

export const doHidePrice = (projectInfo = {}) => {
	return projectInfo && projectInfo.hidePrice && projectInfo.hidePrice == 1;
};