/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import './intro.less';
import * as Request from '../common/request';
import * as Utils from '../common/utils';
import * as CONST from '../common/const';
import Page from '../compoment/prj/page';

import IMG_INTRO_SELFMEM_1 from '../common/images/intros/self-mem/1.png';
import IMG_INTRO_SELFMEM_2 from '../common/images/intros/self-mem/2.png';
import IMG_INTRO_SELFMEM_3 from '../common/images/intros/self-mem/3.png';
import IMG_INTRO_SELFMEM_4 from '../common/images/intros/self-mem/4.png';
import IMG_INTRO_SELFMEM_5 from '../common/images/intros/self-mem/5.png';
import IMG_INTRO_SELFMEM_6 from '../common/images/intros/self-mem/6.png';
import IMG_INTRO_SELFMEM_7 from '../common/images/intros/self-mem/7.png';
import IMG_INTRO_SELFMEM_8 from '../common/images/intros/self-mem/8.png';
import IMG_INTRO_SELFMEM_9 from '../common/images/intros/self-mem/9.png';
import IMG_INTRO_SELFMEM_10 from '../common/images/intros/self-mem/10.png';

const __INTROS_ = {
	'self-mem': [ IMG_INTRO_SELFMEM_1, IMG_INTRO_SELFMEM_2, IMG_INTRO_SELFMEM_3, IMG_INTRO_SELFMEM_4, IMG_INTRO_SELFMEM_5, IMG_INTRO_SELFMEM_6, IMG_INTRO_SELFMEM_7, IMG_INTRO_SELFMEM_8, IMG_INTRO_SELFMEM_9, IMG_INTRO_SELFMEM_10 ],
	'self-membtn': { y: 0.22 },
	'self-mem3': [ IMG_INTRO_SELFMEM_1, IMG_INTRO_SELFMEM_2, IMG_INTRO_SELFMEM_3, IMG_INTRO_SELFMEM_4, IMG_INTRO_SELFMEM_5, IMG_INTRO_SELFMEM_6, IMG_INTRO_SELFMEM_7, IMG_INTRO_SELFMEM_8, IMG_INTRO_SELFMEM_9, IMG_INTRO_SELFMEM_10 ],
	'self-mem3btn': { y: 0.22 },
	'citic-upup': [ IMG_INTRO_SELFMEM_1, IMG_INTRO_SELFMEM_2, IMG_INTRO_SELFMEM_3, IMG_INTRO_SELFMEM_4, IMG_INTRO_SELFMEM_5, IMG_INTRO_SELFMEM_6, IMG_INTRO_SELFMEM_7, IMG_INTRO_SELFMEM_8, IMG_INTRO_SELFMEM_9, IMG_INTRO_SELFMEM_10 ],
	'citic-upupbtn': { y: 0.22 }
};

class PageIntro extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
	    loginType: 0,
	    imgs: [],
	    imgsFlag: false,
	    funcIndex: -1,
    }
  }
  
  componentDidMount() {
  	this.getLoginType();
  	this.getPageConf();
  }
	
	getLoginType = async () => {
		const result = await Request.post({
			url: '/project/login-type'
		});

		let loginType = 2;
		if(result.ok == 0) {
			loginType = result.obj.websiteLoginType || 2;
		}
		this.setState({ loginType });
	};

  getPageConf = async () => {
	  const result = await Request.post({
		  url: '/project/intro/cfg'
	  });

	  if(result && result.obj && result.obj.imgList) {
	  	this.setState({
	  		imgs: result.obj.imgList,
			  imgsFlag: true,
			  funcIndex: result.obj.funcIndex || -1
	  	});
	  }
	  this.setState({
		  imgsFlag: true,
	  });
  };
  
  render() {
  	const { loginType, imgsFlag, imgs, funcIndex } = this.state;
	  const prjName = Utils.projectName.get();

	  const { query = {} } = Utils.urlQuery.get();
	  const queryArr = [];
	  for(let i in query) {
		  if(i && CONST.loginParamsList.includes(i)) {
			  queryArr.push(`${i}=${encodeURIComponent(query[i])}`);
		  }
	  }

	  let path = '/login?t=1';
	  if(Utils.userInfo.isLogin()) {
	  	path = '/home?t=1';
	  }

	  if(queryArr.length > 0) {
		  path += `&${queryArr.join('&')}`;
	  }
	  let src = Utils.urlQuery.get("src");
	  // if(loginType == 1) {
		 //  src = 'rawlogin';
	  // }
	  if (!!src) {
		  path = `${path}&src=${src}`;
	  }
	  
	  /*if(loginType == 1 && __INTROS_[prjName]) {
	  	return (
			  <Page title="权益专区" {...this.props}>
				  <section className="intro-wrap special-intro-wrap">
					  {
						  (__INTROS_[prjName] || []).map((item, idx) => <img src={item} />)
					  }
					  <div className="entrance" style={{ bottom: (__INTROS_[`${prjName}btn`] || { y: 0 }).y * window.innerHeight }} onClick={() => {
						  Utils.redirectPage(this, path);
					  }} />
				  </section>
			  </Page>
		  )
	  }else if(loginType == 0) {
	  	return null;
	  }*/

	  if(!imgsFlag) {
	  	return null;
	  }
	
	  return <Page title="权益专区" {...this.props}>
		  {
			  imgs && imgs.length > 0 && <section className="intro-wrap">
				  <div className="bg-wrap-new">
					  {
						  imgs.map((img, idx) => <img src={Utils.getImageUrl(img)} key={idx} onClick={() => {
						  	if(funcIndex && funcIndex == (idx + 1)) {
								  Utils.redirectPage(this, path);
							  }
						  }} />)
					  }
				  </div>
			  </section> || <section className="intro-wrap">
				  <div className="first-page" style={{ height: window.innerHeight }}>
					  <div className="icon-wrap">
						  <div className="icon" />
						  <div className="icon-yongyou" />
					  </div>
					  <div className="intro" style={{ marginTop: 56 / window.innerHeight * 100 + '%' }} />
					  <div className="intro-text">{CONST.INTRO_TEXT[Utils.projectName.get()] || CONST.INTRO_TEXT['default']}</div>
					  <div className="ctrlbtn" onClick={() => {
						  Utils.redirectPage(this, path);
					  }}>激活权益</div>
					  <div className="intro_arrow" style={{ width: 0.06 * window.innerWidth, bottom: 0.035 * window.innerHeight }} />
				  </div>
				  <div className="rest-page">
					  <div className="imgs">
						  <div className="imgitem imgitem1" />
						  <div className="imgitem imgitem2" />
						  <div className="imgitem imgitem3" />
					  </div>
					  <div className="brands" />
					  <div className="slogan">畅游天下·约在享途</div>
				  </div>
			  </section>
	    }
	  </Page>
  }
}

export default PageIntro;