/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import './rule.less';
import * as Request from '../common/request';
import Page from '../compoment/prj/page';

class PageRule extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      rules: ""
    }
  }
  
  componentDidMount() {
    this.getRule();
  }
  
  getRule = async () => {
    const result = await Request.post({
      url: '/project/rules/get'
    });
    
    if(result.ok == 0) {
      this.setState({
        rules: result.obj.rules
      })
    }
  };
  
  render() {
    const { rules } = this.state;
    return (
      <Page title="产品细则" {...this.props}>
        <section className="rule-wrap">
          <div className="title">产品细则</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: rules }} />
        </section>
      </Page>
    );
  }
}

export default PageRule;
