/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import './payment.less';
import * as Request from '../common/request';
import * as Utils from '../common/utils';
import * as CONST from '../common/const';
import * as Payment from '../common/payment';
import * as Theme from '../common/theme';

import Page from '../compoment/prj/page';

let outerSelectedPayment = 'citic';

class PagePayment extends Component {

  constructor(props) {
    super(props);

    this.timer = null;

    this.state = {
      orderInfo: {},
      selectedPayment: 'citic',
	    selectedPayCode: null,
      restTime: '',
	    paymentMethods: []
    }
  }

  componentDidMount() {
    this.getData();
    this.getPayments();
  }

  componentWillUnmount() {
    if(this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  countDown(seconds) {
    let min = 0, sec = 0;
    const restSec = seconds - parseInt(new Date().getTime() / 1000, 10);
    if(restSec > 0) {
      min = Math.floor(restSec / 60 % 60);
      sec = restSec - min * 60;
    }
    min = Utils.addZero(min);
    sec = Utils.addZero(sec);
    this.setState({ restTime: `${min}:${sec}` });
    this.timer = setTimeout(() => this.countDown(seconds), 1000);
  }

  getData = async () => {
    const orderId = Utils.urlQuery.get('oid');
    const result = await Request.post({
      url: '/book-order/detail',
      data: {
        bookOrderId: orderId
      }
    });
  
    if(result.ok == 0) {
      const orderInfo = result.obj;
      this.setState({
        orderInfo
      }, () => this.countDown(orderInfo.expireTime));
    }
  };
	
	payActionHandle = async () => {
		const { orderInfo, selectedPayment, selectedPayCode } = this.state;
		const _selectedPayment = outerSelectedPayment;
		
		if (!!_selectedPayment && _selectedPayment.startsWith('citic')) {
			await Payment.doPayment(
			  `${window.location.origin}/prj/${Utils.projectName.get()}/payment/result`,
        orderInfo.payPrice,
        selectedPayCode,
        {
          bookOrderId: orderInfo.bookOrderId,
          orderId: orderInfo.bookOrderId
        }
      );
		}else if (!!_selectedPayment && _selectedPayment.startsWith('wechat')) {
			await Payment.doWeChatPayment(orderInfo.bookOrderId, `${window.location.origin}/prj/${Utils.projectName.get()}/user/order/${orderInfo.bookOrderId}`, orderInfo.payPrice);
		} else if (!!_selectedPayment && _selectedPayment == 'nbank') {
			const nbankResult = await Payment.doNBankPayment(orderInfo.bookOrderId, `${window.location.origin}/prj/${Utils.projectName.get()}/user/order/${orderInfo.bookOrderId}`, orderInfo.payPrice);
			if(nbankResult && nbankResult.startsWith('<html>')) {
				document.write(nbankResult);
			}
		} else {
			Utils.dialog.toast('支付完善中，很快就可以用啦～');
			return;
		}
	};

	getPayments = async () => {
		const result = await Request.post({
			url: '/pay/get-method'
		});
		if(result.ok != 0) return;
		const _paymentMethods = result.obj.list;
		// const _paymentMethods = [{"methodName":"wechat"}, {"methodName":"citic"}];
		
		const paymentMethods = [];
		_paymentMethods.map(item => {
			if(Utils.ua.isWeChat() || (!Utils.ua.isWeChat() && !item.methodName.startsWith('wechat'))) {
				paymentMethods.push(item);
			}
		});
		
		if(paymentMethods.length > 0) {
			const payment = CONST.payments[paymentMethods[0].methodName];
			const selectedPayment = payment.type;
			outerSelectedPayment = payment.type;
			this.setState({ paymentMethods, selectedPayment });
		}
	};
	
  render() {
    const { orderInfo, selectedPayment, restTime, paymentMethods } = this.state;
    // console.log('paymentMethods', paymentMethods)
    return (
      <Page title="订单支付" {...this.props}>
        <section className="payment-wrap">
          <div className="payment-content">
            <span className="countdown">支付剩余时间 {restTime}</span>
            <span className="value"><em className="yen">¥</em>{orderInfo.payPrice}</span>
          </div>
          <div className="payment-method">
            <span>请选择支付方式</span>
            <div className="list">
              {
	              Utils.isValidArray(paymentMethods) && paymentMethods.map((item, idx) => {
	              	const payment = CONST.payments[item.methodName];
	              	return (
			              <div className="item" key={idx} onClick={() => {
				              outerSelectedPayment = payment.type;
				              this.setState({ selectedPayment: payment.type, selectedPayCode: item.payCode });
			              }}>
				              <div className="content">
					              <em className={`icon ${payment.iconCls}`} />
					              <label>{payment.name}</label>
				              </div>
				              <em  className={payment.type == selectedPayment ? 'selector selected' : 'selector'} style={ payment.type == selectedPayment ? { backgroundImage: `url(${Theme.getImages().selectedRound})` } : {} } />
			              </div>
		              )
	              })
              }
            </div>
          </div>
          <div className={`payment-action${Utils.isValidArray(paymentMethods) && paymentMethods.length > 0 ? '' : ' disabled'}`}>
            <span className="theme-primary-btncolor" onClick={() => {
            	if(Utils.isValidArray(paymentMethods) && paymentMethods.length > 0) {
		            this.payActionHandle();
	            }
            }}>确认支付{orderInfo.payPrice}元</span>
          </div>
        </section>
      </Page>
    );
  }
}

export default PagePayment;
