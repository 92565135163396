/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import './login.less';
import * as Request from '../common/request';
import * as Utils from '../common/utils';
import * as AutoLogin from '../common/auto_login';
import * as CONST from '../common/const';
import Page from '../compoment/prj/page';

class PageLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: '',
      smsCode: '',
	    citicIdNbrOpenId: '',
	    // loginType: ['s', 'rawlogin'].includes(Utils.urlQuery.get("src")) ? 1 : 2
	    loginType: 0,
	    projectInfo: {},
	    _isInitial: false
    };
	  this._isMounted = false;
  }

  componentDidMount() {
	  this._isMounted = true;

	  if(this._isMounted) {
		  this.initial();
		  this.getConfig();
	  }
  }

	componentWillUnmount() {
		this._isMounted = false;
	}

	getLoginType = async () => {
		const result = await Request.post({
			url: '/project/login-type'
		});

		let loginType = 2;
		if(result.ok == 0) {
			loginType = result.obj.websiteLoginType || 2;
		}
		this.setState({ loginType });
		return loginType;
	};

	initial = async () => {
  	const _redirectHelper = (elseCallback) => {
		  const userInfo = Utils.userInfo.get();
		  if(userInfo.id && userInfo.actived != 0) {
			  Utils.redirectPage(this, '/home');
		  }else{
			  elseCallback && elseCallback();
		  }
	  };

		let { loginType } = this.state;
		if(loginType == 0) {
			loginType = await this.getLoginType();
		}

		if (loginType == 1) {
			// loginType 为 1 的时候需要微信登录
			if(Utils.ua.isWeChat()) {
				const userWeChatInfo = Utils.weChat.get();
				if(!userWeChatInfo || !userWeChatInfo.openid) {
					Utils.weChat.login({}, () => {
						_redirectHelper();
						this.setState({ _isInitial: true });
					});
					return;
				}
			}
			_redirectHelper(() => {
				this.setState({ _isInitial: true });
			});
		} else if(loginType == 3){
			_redirectHelper(async () => {
				const result = await AutoLogin.nBankLogin();
				if(result) {
					Utils.userInfo.set(result);

					if (result.actived == 0) {
						// 账号尚未激活
						Utils.redirectPage(this, '/rights');
					} else {
						Utils.redirectPage(this, '/home');
					}
				}
				this._isInitial = true;
				this.setState({ _isInitial: true });
			});
		} else {
			_redirectHelper(async () => {
				const result = await AutoLogin.action(window.location.href);
				if (result) {
					if (result.statusCode == 1) {
						this._isMounted && this.setState({
							citicIdNbrOpenId: result.citicIdNbrOpenId
						});
					} else if (result.statusCode == 2) {
						Utils.userInfo.set(result);

						if (result.actived == 0) {
							// 账号尚未激活
							Utils.redirectPage(this, '/rights');
						} else {
							Utils.redirectPage(this, '/home');
						}
					}
				}
				this.setState({ _isInitial: true });
			});
		}
	};

	getConfig = async () => {
		const result = await Request.post({
			url: '/project/info/get'
		});
		if(result && result.ok == 0) {
			this._isMounted && this.setState({
				projectInfo: result.obj
			})
		}
	};

	// 登录
	loginHandle = async () => {
		const { mobile, smsCode, citicIdNbrOpenId, loginType } = this.state;
		if(!mobile || !smsCode) {
			// Utils.dialog.toast('参数缺失');
			return;
		}

		// const src = Utils.urlQuery.get("src");
		let result = {};
		if (loginType == 1) {
			result = await Request.post({
				url: '/user/mobile-login',
				data: {
					mobile,
					smsCode
				}
			});
		} else {
			result = await Request.post({
				url: '/user/citic-mobile-login',
				data: {
					mobile,
					smsCode,
					citicIdNbrOpenId
				}
			});
		}

		if(result.ok == 0) {
			Utils.dialog.toast('登录成功');
			const userInfo = result.obj;
			Utils.userInfo.set(userInfo);

			if(userInfo.actived == 0) {
				// 账号尚未激活
				Utils.redirectPage(this, '/rights');
			}else{
				Utils.redirectPage(this, '/home');
			}
		}
	};

  // 获取验证码
  getSmsCode = async () => {
    const { mobile } = this.state;
    if(!mobile) {
      // Utils.dialog.toast('手机号码缺失');
      return;
    }

    const result = await Request.post({
      url: '/common/sendcode',
      data: {
        mobile
      }
    });

    if(result.ok == 0) {
      Utils.dialog.toast('验证码发送成功');
    }
  };

  render() {
  	if(!this.state._isInitial) return null;

    const { mobile, smsCode, projectInfo, loginType } = this.state;
    return (
      <Page title="登录" {...this.props}>
        <section className="login-wrap">
	        { projectInfo && projectInfo.logo && <img className="icon" src={projectInfo.logo} /> || <div className="icon-new" style={{ height: 30 }} /> }
          {/*<div className="icon" style={{ visibility: loginType == 1 ? 'hidden' : 'inherit' }} />*/}
          <div className="main-wrap">
            <div className="main">
              <div className="title" />
              <div className="input-area">
                <InputArea
                  label="手机号"
                  placeholder="请输入手机号"
                  maxLength="11"
                  value={mobile}
                  onChange={event => {
                    this.setState({ mobile: event.target.value });
                  }}
                />
                <InputArea
                  label="验证码"
                  placeholder="请输入验证码"
                  isCode={true}
                  preValue={mobile}
                  value={smsCode}
                  getSmsCodeHandle={this.getSmsCode}
                  onChange={event => {
                    this.setState({ smsCode: event.target.value });
                  }}
                />
              </div>
              <div className={`submit-btn${!smsCode || !mobile ? ' disabled' : ''}`} onClick={this.loginHandle}>登录</div>
            </div>
          </div>
	        <div className="slogan">畅游天下，约在享途</div>
        </section>
      </Page>
    );
  }
}

class InputArea extends Component {
	constructor(props) {
		super(props);

		this.timer = null;

		this.state = {
			countDown: -1
		}
	}

	doCountDown = (countDown = 60) => {
		clearTimeout(this.timer);
		this.timer = null;
		this.setState({ countDown }, () => {
			if(countDown >= 0) {
				this.timer = setTimeout(() => {
					countDown = countDown - 1;
					this.doCountDown(countDown);
				}, 1000);
			}
		});
	};

  render() {
    const { label, placeholder, isCode, onChange, value, getSmsCodeHandle, preValue, maxLength } = this.props;
    const { countDown } = this.state;

    return (
      <div className="input-wrap">
        <label>{label}</label>
        <input
	        placeholder={placeholder}
	        onChange={onChange}
	        value={value}
	        maxLength={maxLength}
	        onBlur={() => {
	          Utils.blurAdjust();
	        }} />
        { isCode && <div className={`code-btn${!!preValue ? '' : ' disabled'}`} onClick={() => {
        	if(countDown != -1) return;

        	getSmsCodeHandle();
        	this.doCountDown();
        }}>{ countDown == -1 ? '获取验证码' : `${countDown}秒` }</div> }
      </div>
    );
  }
}

export default PageLogin;
