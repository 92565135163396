/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './home.less';
import * as Request from '../common/request';
import * as Utils from '../common/utils';
import * as CONST from '../common/const';
import Page from '../compoment/prj/page';
import HotelItem from '../compoment/prj/hotel_item';
import HotelListFilters from '../compoment/prj/hotel_list_filters';
import ListLoading from '../compoment/list_loading';

import IMG_DEFAULT_HEADIMG from '../common/images/home_cover.jpg';

class PageHome extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      firstRightsFlag: !window.localStorage.getItem('rightsFirstShow'),
      projectInfo: {},
	    defaultCover: '',
      listQuery: {
        lat: 31.230378,
        lng: 121.473658,
        productType: 1,
        orderType: 0,
        cityId: 3,
        pageSize: Utils.urlQuery.get('pageSize') || 20,
	      pageNo: Utils.urlQuery.get('pageNo') || 1,
        interestType: []
      },
	    totalCnt: 0,
      hotelList: [],
      couponList: []
    }
  }
  
  componentDidMount() {
    this.getProjectInfo();
    this.getCoupons();
  }
  
  closeFirstCoupons = () => {
    this.setState({ firstRightsFlag: false });
    window.localStorage.setItem('rightsFirstShow', true);
  };
  
  getProjectInfo = async () => {
    const result = await Request.post({
      url: '/project/info/get'
    });
    
    if(result.ok == 0) {
      this.setState({
        projectInfo: result.obj
      })
    }
    this.setState({ defaultCover: IMG_DEFAULT_HEADIMG });
  };
  
  getHotelList = async () => {
    const { listQuery } = this.state;
    const result = await Request.post({
      url: '/hotel/list',
      data: {
        ...listQuery
      }
    });
    
    if(result.ok == 0) {
      this.setState({
        hotelList: result.obj.list,
	      totalCnt: result.obj.totalCnt
      });
    }
  };

  getCoupons = async () => {
    const { firstRightsFlag } = this.state;
    if(!firstRightsFlag) return;
    const result = await Request.post({
      url: '/coupon/getByUser'
    });
    
    if(result.ok == 0) {
      this.setState({
        couponList: result.obj.couponList
      });
    }
  };
  
  render() {
    const { firstRightsFlag, projectInfo, listQuery, hotelList, couponList, totalCnt, defaultCover } = this.state;

    return (
      <Page title={projectInfo.projectName} {...this.props}>
        <section className="home-wrap">
          <div className="top-base-info" id="homeBaseInfo" style={{ backgroundImage: `url(${projectInfo.headImg ? Utils.getImageUrl(projectInfo.headImg) : defaultCover})` }}>
            <div className="title">{projectInfo.projectName}</div>
            <div className="desc" dangerouslySetInnerHTML={{ __html: projectInfo.projectDes }} />
            <div className="entrances">
              <Link className="entrance-item" to={Utils.getRedirectUrl('/user/rights')}>我的权益</Link>
              <Link className="entrance-item" to={Utils.getRedirectUrl('/user/orders')}>我的订单</Link>
            </div>
            <Link className="rule theme-secondary-tag" to={Utils.getRedirectUrl('/rule')}>产品细则</Link>
          </div>
          <div className="bottom-list">
            <HotelListFilters {...this.props} {...this}
              onChange={data=> {
                if(data.orderTypeInfo && (data.orderTypeInfo.orderType || data.orderTypeInfo.orderType == 0)) {
                  listQuery.orderType = data.orderTypeInfo.orderType;
                }
                if(data.cityInfo && data.cityInfo.cityId) {
                  listQuery.cityId = data.cityInfo.cityId;
                  listQuery.cityName = data.cityInfo.cityName;
                }
                if(data.couponInfo) {
                  listQuery.interestType = data.couponInfo;
                }
                
                this.setState({
                  listQuery
                }, () => {
                	// 更新 URL 中 query 信息
	                let _queryArr = [];
	                for(let i in listQuery) {
	                	if(listQuery[i]){
			                _queryArr.push(`${i}=${listQuery[i]}`);
		                }
	                }
	                this.props.history.push({
	                	pathname: Utils.getRedirectUrl('/home'),
		                search: `?${_queryArr.join('&')}`
	                });
	                
                  this.getHotelList();
                })
              }}
              onInitial={data => {
	              if(data.orderTypeInfo && (data.orderTypeInfo.orderType || data.orderTypeInfo.orderType == 0)) {
		              listQuery.orderType = data.orderTypeInfo.orderType;
	              }
	              
                listQuery.cityId = data.cityInfo.cityId;
                if(data.cityInfo.lng) listQuery.lng = data.cityInfo.lng;
                if(data.cityInfo.lat) listQuery.lat = data.cityInfo.lat;
	
	              if(data.couponInfo) {
		              listQuery.interestType = data.couponInfo;
	              }
	              
                this.setState({
                  listQuery
                }, () => {
                  this.getHotelList();
                })
              }}
            />
            <div className="hotels-list">
              {
                hotelList && hotelList.length > 0 && hotelList.map((hotel, idx) => <HotelItem key={idx} data={hotel} projectInfo={projectInfo} />)
              }
	
	            <ListLoading
		            length={hotelList.length}
	              total={totalCnt}
	              pageSize={listQuery.pageSize}
	              pageNo={listQuery.pageNo}
	            />
            </div>
          </div>
        </section>
      </Page>
    );
  }
}

export default PageHome;
