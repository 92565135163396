/**
 * Created by jimmy on 2019/3/9.
 */
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import moment from 'moment';
import './calendar.less';

let oriMap = {};

class CompCalendar extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      dates: [],
      today: '',
      startDate: '',
      endDate: '',
      has6Coupon: false
    };
  }
  
  componentDidMount() {
    this.getData();
    this.initial(this.props);
  }
  
  componentWillReceiveProps(nextProps, nextState) {
    this.initial(nextProps);
  }
  
  initial = (props) => {
    this.setState({
      has6Coupon: props.has6Coupon,
      startDate: !!props.shouldClear ? '' : this.state.startDate,
      endDate: !!props.shouldClear ? '' : this.state.endDate,
    })
  };
  
  getData = async () => {
    const { productId } = this.props.match.params;
    const result = await Request.post({
      url: '/product/accom/calendar',
      data: {
        productId
      }
    });
    
    if(result.ok == 0) {
      this.setState({
        dates: result.obj.dates,
        today: result.obj.today
      });
    }
  };
  
  calendarInit = (dates) => {
    const { has6Coupon } = this.state;
    if(!dates || dates.length <= 0) return [];
    const map = {};
    
    const months = [];
    dates.map(item => {
      const month = moment(item.date).format('YYYY-MM');
      oriMap[item.date] = item;
      if(!months.includes(month)){
        map[month] = [];
        months.push(month);
      }
    });
    
    months.map(month => {
      const days = moment(month).daysInMonth();
      const firstDayWeek = moment(`${month}-01`).day();
      // 每个月第一天开始星期
      for(let w = 0; w < firstDayWeek; w++) {
        map[month].push('');
      }
      
      // 每月日期
      for(let d = 1; d <= days; d++) {
        d = d < 10 ? '0' + d : d;
        const date = `${month}-${d}`;
        const dateObj = oriMap[date];
        map[month].push({
          date,
          rawDate: !dateObj,
          ...dateObj
        });
      }
    });
  
    return Object.keys(map).map(item => ({
      month: item,
      dates: map[item]
    }));
  };
  
  selectHandle = (dateObj) => {
    const startDate = dateObj.date;
	  const endDate = moment(startDate).add(this.state.has6Coupon? 2 : 1, "days").format('YYYY-MM-DD');
    this.setState({
	    startDate,
	    endDate
    });
  };
  
  getDateObjByDate = (date) => {
  	const { dates } = this.state;
  	let res = null;
  	dates.map(d => {
  		if(d.date == date){
  			res = d;
		  }
	  });
  	return res;
  };
  
  render() {
    let { dates, today, startDate, endDate, has6Coupon } = this.state;
    dates = this.calendarInit(dates);
    
    let allDates = {};
	  dates.map(d => {
	  	d.dates.map(_d => {
	  		if(_d && _d.date) {
				  allDates[_d.date] = _d;
			  }
		  })
	  });
	  
    return (
      <div className="calendar-wrap">
        <div className="title">
          <em onClick={this.props.cancelHandle}>取消</em>选择日期
        </div>
        <div className="date">
          <div className="date-item">
            <label>入住</label><div className={`value${!startDate && ' value-empty' || ''}`}>{Utils.dateDisplayer(startDate)}</div>
          </div>
          <div className="night-long">{Utils.datesDiff(startDate, endDate)}晚</div>
          <div className="date-item">
            <label>离店</label><div className={`value${!endDate && ' value-empty' || ''}`}>{Utils.dateDisplayer(endDate)}</div>
          </div>
        </div>
        <div className="week">
          <span className="weekend theme-primary-color">日</span>
          <span>一</span><span>二</span><span>三</span><span>四</span><span>五</span>
          <span className="weekend theme-primary-color">六</span>
        </div>
        <div className="days">
          {
            dates && dates.length > 0 && dates.map((item, idx) => {
              return (
                <div className="days-item" key={idx}>
                  <div className="big-month theme-primary-color">{moment(`${item.month}-01`).format('M')}</div>
                  <div className="year-month">{moment(`${item.month}-01`).format('YYYY年MM月')}</div>
                  <div className="day-list">
                    {
                      item.dates && item.dates.length > 0 && item.dates.map((obj, didx) => {
                        let disabled = false;
                        let dateCls = ['day-list-item'];
	                      
	                      /**
	                       * 2晚，不可订往前 2 天
	                       * 1晚，不可订往前 1 天
	                       */
	                      let nextDay = moment(obj.date).add(1, 'days').format('YYYY-MM-DD');
	                      let nextDateObj = this.getDateObjByDate(nextDay);
	                      if(has6Coupon) {
                          if(!nextDateObj || nextDateObj.status == 0 || nextDateObj.rawDate || obj.status == 0 || obj.rawDate){
	                          disabled = true;
	                          dateCls.push('disabled');
                          }
	                      }else{
	                        if(obj.status == 0 || obj.rawDate){
		                        disabled = true;
		                        dateCls.push('disabled');
	                        }
	                      }
	                      
                        if(obj.date == today) dateCls.push('today');
                        if(obj.date == startDate) {
	                        dateCls.push('select-start');
	                        dateCls.push('theme-primary-btncolor-start');
                        }
                        if(obj.date == endDate){
	                        dateCls.push('select-end');
	                        dateCls.push('theme-primary-btncolor-end');
                        }
                        if(obj.date > startDate && obj.date < endDate) dateCls.push('select-start-end');
                        
                        return (
                          <div className={dateCls.join(' ')} key={didx} onClick={() => {
                            if(disabled) {
                              Utils.logger.error('disabled');
                              return;
                            }
                            
                            this.selectHandle(obj);
                          }}>
                            <div className="inner">{obj.date && moment(obj.date).format('D') || ''}</div>
	                          <em className="theme-primary-btncolor" />
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
    
        <div className="submit-btn-wrap">
          <div className="submit-btn theme-primary-btncolor" onClick={() => {
            if(!startDate) {
              Utils.dialog.toast('请选择入住日期');
              return;
            }else if(!endDate) {
              Utils.dialog.toast('请选择离店日期');
              return;
            }
            
            let datesRes = [];
            for(let i = 0; i <= Utils.datesDiff(startDate, endDate); i++) {
            	const curDay = moment(startDate).add(i, 'days').format('YYYY-MM-DD');
	            datesRes.push(allDates[curDay]);
            }
            this.props.selectHandle(datesRes)
          }}>
            <span>{Utils.dateDisplayer(startDate)}-{Utils.dateDisplayer(endDate)}({Utils.datesDiff(startDate, endDate)}晚)</span>
            <label>确定</label>
          </div>
        </div>
      </div>
    );
  }
}

export default CompCalendar;

