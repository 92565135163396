/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import './prd-list-july.less';
import Page from '../compoment/prj/page';
import * as Utils from '../common/utils';

import IMG_CXYNEW_1 from '../common/images/prdlist/july/1.jpg';
import IMG_CXYNEW_2 from '../common/images/prdlist/july/2.jpg';
import IMG_CXYNEW_3 from '../common/images/prdlist/july/3.jpg';
import IMG_CXYNEW_4 from '../common/images/prdlist/july/4.jpg';
import IMG_CXYNEW_5 from '../common/images/prdlist/july/5.jpg';
import IMG_CXYNEW_6 from '../common/images/prdlist/july/6.jpg';
import IMG_CXYNEW_7 from '../common/images/prdlist/july/7.jpg';
import IMG_CXYNEW_8 from '../common/images/prdlist/july/8.jpg';
import IMG_CXYNEW_9 from '../common/images/prdlist/july/9.png';

const IMG_MAP = {
  IMG_CXYNEW_1, IMG_CXYNEW_2, IMG_CXYNEW_3, IMG_CXYNEW_4, IMG_CXYNEW_5, IMG_CXYNEW_6, IMG_CXYNEW_7, IMG_CXYNEW_8, IMG_CXYNEW_9
};

class PageJulyList extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {
	  Utils.setTitle('产品列表');
  }


  render() {
    return (
	    <section className="prdlist-wrap">
		    {
			    Object.keys(IMG_MAP).map((img, idx) => (
				    <img src={IMG_MAP[img]} key={idx} />
			    ))
		    }
	    </section>
    );
  }
}

export default PageJulyList;

window.onload=function () {
  if(window.location.pathname == '/prj/citic-call/prd-list-july') {
    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width,initial-scale=1')
  }
};
