/**
 * Created by jimmy on 2019/3/2.
 */
import React, { Component } from 'react';
import './payment_result.less';
import { Link } from 'react-router-dom';
import * as Utils from '../common/utils';
import * as Theme from '../common/theme';
import * as Payment from '../common/payment';
import * as CONST from '../common/const';

import Page from '../compoment/prj/page';

const payResult = Utils.urlQuery.get('retCode') == '0000000' ? 1 : 0;  // 1 || 0
const token = Utils.urlQuery.get('token');

class PagePaymentResult extends Component {

  constructor(props) {
    super(props);

    this.state = {
      payPrice: 0,
	    bookOrderId: '',
	    status: null
    }
  }

  componentDidMount() {
    this.syncPayToServer();
  }
	
	syncPayToServer = async () => {
  	if(!token){
		  this.setState({
			  bookOrderId: Utils.urlQuery.get('oid')
		  });
		  return;
	  }
  	
		const result = await Payment.syncPayToServer(token, payResult);
		if(result && result.ok == 0) {
			this.setState({
				payPrice: result.obj.payPrice,
				bookOrderId: result.obj.bookOrderId,
				status: result.obj.status
			});
		}
	};

  render() {
  	const { payPrice, bookOrderId, status } = this.state;
  	
    return (
      <Page title="支付结果" {...this.props}>
        <section className="payment-result-wrap">
	        <div className="status-sign" style={{ backgroundImage: `url(${payResult == 1 ? Theme.getImages().successRound : Theme.getImages().failRound})` }}></div>
	        <div className="status-text theme-primary-color">{payResult == 1 ? ' 支付成功' : ' 支付失败'}</div>
	        { payPrice && payPrice > 0 && <div className="price yen">{payResult == 1 ? '实付' : '应付'} ¥{payPrice / 100}</div> }
	        <div className="next-entrance">
		        <Link className="item theme-primary-color theme-primary-bordercolor" to={Utils.getRedirectUrl('/home')}>返回首页</Link>
		        {
			        (payResult == 1 || status != CONST.orderStatusType.TOPAY) &&
			          <Link className="item theme-primary-color theme-primary-bordercolor" to={Utils.getRedirectUrl('/user/orders')}>查看订单</Link> ||
			          <div className="item theme-primary-color theme-primary-bordercolor" onClick={async () => {
				          window.location.href = window.location.origin + Utils.getRedirectUrl(`/payment?oid=${bookOrderId}`);
			          }}>继续支付</div>
		        }
	        </div>
        </section>
      </Page>
    );
  }
}

export default PagePaymentResult;
